// Copyright (C) 2021-2022 LTTS
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Text from 'antd/lib/typography/Text';
import Card from 'antd/lib/card';
import Dropdown from 'antd/lib/dropdown';
import Button from 'antd/lib/button';
import { MoreOutlined } from '@ant-design/icons';
import ProjectActionsMenuComponent from './actions-menu';
import { List, Tooltip } from 'antd';
import { Row, Col } from 'antd';
import { Project } from 'reducers/interfaces';
import { useHistory } from 'react-router';
import moment from 'moment';
import { ProjectImageIcon, ProjectVideoIcon, ProjectAudioIcon, ProjectLidarIcon } from 'icons';
import Icon from '@ant-design/icons';
import './styles.scss';

interface Props {
   // viewChanges: boolean;
    projectInstance: Project;
}
export default function ProjectItemComponent(props: Props): JSX.Element {
    //const { viewChanges } = props;
    const {
        projectInstance: { instance },
    } = props;
    const history = useHistory();
    const ownerName = instance?.owner ? instance.owner.username : null;
    const description = instance?.description ? instance.description : 'Not Available';
    const createdDate = instance?.createdDate ? instance.createdDate : null;
    const projectType = instance?.project_type;
    const created = moment(createdDate);
    const updated = moment(instance.updatedDate).fromNow();
    const onOpenProject = (): void => {
        history.push(`/projects/${instance.id}`);
    };
    const showProjectType = () => {
        let iconObj = {
            icon: '',
            text: '',
            className: '',
        };
        if(projectType === 1) {
            iconObj = {
                icon: ProjectImageIcon,
                text: 'Image Annotation',
                className:'Project-icon-image',
            }
        }
        else if(projectType === 2) {
            iconObj = {
                icon: ProjectVideoIcon,
                text: 'Video Annotation',
                className:'Project-icon-video'
            }
        }
        else if(projectType === 3) {
            iconObj = {
                icon: ProjectLidarIcon,
                text: 'LiDAR Annotation',
                className:'Project-icon-lidar'
            }
        }
        else if(projectType === 4) {
            iconObj = {
                icon: ProjectAudioIcon,
                text: 'Audio Annotation',
                className:'Project-icon-audio'
            }
        }
        return (
            <div className={iconObj.className}>
            <Row align='middle'>
                <Col span={6}>
                    <Icon component={iconObj.icon} />
                </Col>
                <Col span={6} style={{ fontWeight: 'bold' }}>
                  {iconObj.text}
                </Col>
            </Row>
        </div>
        )
    };

    return (
        <>
                        <Card
                            className='ltts-item-card'
                            title={
                                <div className='Project-taskcount-text-align'>
                                   {showProjectType()}
                                    <div className='ltts-project-card-layout'>
                                        <div className='ltts-project-card-content'>
                                            <p>
                                                <Tooltip
                                                    placement='rightTop'
                                                    title={instance?.name}
                                                    overlayStyle={{ maxWidth: '500px' }}
                                                >
                                                <span aria-hidden>
                                                    <Text strong className='ltts-project-item'>Project Name : </Text>
                                                   <span className='description-ellipsis-cardview'>{instance?.name}</span> 
                                                </span>
                                                </Tooltip>
                                            </p>
                                            <p>
                                                <span aria-hidden>
                                                    <Tooltip
                                                        placement='rightTop'
                                                        title={description}
                                                        overlayStyle={{ maxWidth: '500px' }}
                                                    >
                                                        <Text strong className='ltts-project-item'>
                                                            Project Description :{' '}
                                                        </Text>
                                                        <span className='description-ellipsis-cardview'>{description}</span>
                                                    </Tooltip>
                                                </span>
                                            </p>
                                            <p>
                                                <span aria-hidden>
                                                    <Text strong className='ltts-project-item'>
                                                        {'Project Status'} : {instance?.project_creation_status }
                                                    </Text>
                                                </span>
                                            </p>
                                            <p>
                                                <Text strong className='ltts-project-item'>Creation Date : </Text>
                                                <span>{created.format('MMMM Do YYYY HH:MM')}</span>
                                            </p>

                                            <p>
                                                <Text strong className='ltts-project-item'>Created by : </Text>
                                                {ownerName}
                                            </p>
                                        </div>
                                        <p>
                                            <Text className='ltts-projects-secondary-text'>{`Last updated ${updated}`}</Text>
                                        </p>

                                        <div className='ltts-item-description'>
                                            <div>
                                                <Dropdown
                                                    overlay={<ProjectActionsMenuComponent projectInstance={instance} />}
                                                    placement='bottomCenter'
                                                >
                                                    <Button type='link' size='large' icon={<MoreOutlined />} />
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            bordered={true}
                        ></Card>
        </>
    );
}
