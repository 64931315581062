// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState } from 'react';
import { Table, Button, Popover, Select, notification, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
    reassignProjectStages,
    fetchCurrentAssignedUser,
    fetchDifferentAssignedUser,
    fetchWorkflowHistory,
    fetchStageReport,
    resetAnnotationInfo,
    fetchProjectStages,
} from 'actions/reports-action';
import { CombinedState } from 'reducers/interfaces';

interface Props {
    reportId: any;
}
export default function ReportAnalyticsModal(props: Props): JSX.Element {
    const { reportId } = props;
    const dispatch = useDispatch();

    const [releaseStageId, setReleaseStageId] = useState([]);
    const [userStages, setUserStages] = useState([]);
    const [stageDetails, setStageDetails] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    const {
        releaseStageMessage,
        currentStageUsers,
        differentStageUsers,
        parentWorkflowHistory,
        stageAnnotationInfo,
        projectAppStages,
        releaseButtonDisabled,
    } = useSelector((state: CombinedState) => state.reports);

    const data = stageAnnotationInfo.annotation_info?.length > 0 ? stageAnnotationInfo.annotation_info : [];
    const workflowHistory = parentWorkflowHistory.wf_history?.length > 0 ? parentWorkflowHistory.wf_history : [];

    useEffect(() => {
        if (releaseStageMessage) {
            notification.success({
                message: releaseStageMessage,
            });
            setTimeout(() => {
                dispatch(resetAnnotationInfo()),
                    dispatch(fetchStageReport(stageAnnotationInfo?.project_app_id)),
                    dispatch(fetchProjectStages(reportId));
            }, 2000);
        }
    }, [releaseStageMessage]);

    const releaseCurrentStageHandler = () => {
        dispatch(reassignProjectStages(releaseStageId.workflow_id, releaseStageId.current_stage_id, null, null, null));
    };

    const currentStageUserHandler = (userId: any) => {
        const selectedUser = currentStageUsers.filter((id: any) => id.user_id === userId);
        dispatch(
            reassignProjectStages(
                releaseStageId.workflow_id,
                releaseStageId.current_stage_id,
                selectedUser[0].user_id,
                selectedUser[0].username,
                null,
            ),
        );
    };

    const releaseDifferentStageHandler = (id: any) => {
        dispatch(reassignProjectStages(releaseStageId.workflow_id, releaseStageId.current_stage_id, null, null, id));
    };

    const releaseDifferentStageUserHandler = (userId: any) => {
        const selectedUser = userStages.filter((id: any) => id.user_id === userId);
        dispatch(
            reassignProjectStages(
                releaseStageId.workflow_id,
                releaseStageId.current_stage_id,
                selectedUser[0].user_id,
                selectedUser[0].username,
                selectedUser[0].project_app_id,
            ),
        );
    };

    useEffect(() => {
        if (differentStageUsers.length > 0) {
            const differentStages = differentStageUsers.filter(
                (id: any) => id.project_app_id !== releaseStageId.current_stage_id,
            );
            setUserStages(differentStages);
        }
    }, [differentStageUsers]);

    const releaseWorkflowHandler = (stages: any) => {
        const stageInfo = {
            workflow_id: stages.workflow_id,
            current_stage_id: stageAnnotationInfo.project_app_id,
        };

        const differentStages = projectAppStages.filter(
            (id: any) => id.project_app_id !== stageAnnotationInfo.project_app_id,
        );
        setReleaseStageId(stageInfo);
        setStageDetails(differentStages);
        dispatch(fetchCurrentAssignedUser(stageAnnotationInfo.project_app_id));
        dispatch(fetchDifferentAssignedUser(reportId));
    };

    const content = (
        <div className='release-report'>
            <Button onClick={() => releaseCurrentStageHandler()}>Release to Current Stage Pool</Button>
            <Button>
                Release to Current Stage with User
                <br />
                <Select placeholder='Select a User' style={{ width: '90%' }} onChange={currentStageUserHandler}>
                    {currentStageUsers.map(
                        (userValues: any): JSX.Element => (
                            <Select.Option value={userValues.user_id} key={userValues.user_id}>
                                {userValues.username}
                            </Select.Option>
                        ),
                    )}
                </Select>
            </Button>
            <Button>
                Release to Different Stage
                <br />
                <Select placeholder='Select a Stage' style={{ width: '90%' }} onChange={releaseDifferentStageHandler}>
                    {stageDetails.map(
                        (stages: any): JSX.Element => (
                            <Select.Option value={stages.project_app_id} key={stages.project_app_id}>
                                stage:{stages.stage} - {stages.app_alias_name}
                            </Select.Option>
                        ),
                    )}
                </Select>
            </Button>
            <Button>
                Release to Different Stage & User
                <br />
                <Select
                    placeholder='Select a User'
                    style={{ width: '90%' }}
                    onChange={releaseDifferentStageUserHandler}
                >
                    {userStages.map(
                        (userValues: any): JSX.Element => (
                            <Select.Option value={userValues.user_id} key={userValues.user_id}>
                                {userValues.user_id} - {userValues.username}
                            </Select.Option>
                        ),
                    )}
                </Select>
            </Button>
        </div>
    );

    const showHistory = (workflowId: any) => {
        dispatch(fetchWorkflowHistory(workflowId));
        setTimeout(() => {
            setOpenModal(true);
        }, 500);
    };

    const submitHandler = () => {
        setOpenModal(false);
    };

    const workflowCancelHandler = () => {
        setOpenModal(false);
    };

    const workflowColumns = [
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            render: (username: any) => {
                return <div>{username}</div>;
            },
            sorter: (val1: any, val2: any) => val1.username.localeCompare(val2.username),
        },
        {
            title: 'Parent WorkflowId',
            dataIndex: 'parent_wf_id',
            key: 'parent_wf_id',
            render: (parent_wf_id: any) => {
                return <div>{parent_wf_id}</div>;
            },
        },
        {
            title: 'App Alias Name',
            dataIndex: 'app_alias_name',
            key: 'app_alias_name',
            render: (app_alias_name: any) => {
                return <div>{app_alias_name}</div>;
            },
            sorter: (val1: any, val2: any) => val1.app_alias_name.localeCompare(val2.app_alias_name),
        },
    ];

    const columns = [
        {
            title: 'Username',
            dataIndex: 'user',
            key: 'user',
            render: (user: any) => {
                return <div>{user}</div>;
            },
            sorter: (val1: any, val2: any) => val1.user.localeCompare(val2.user),
        },
        {
            title: 'Annotation Count',
            dataIndex: 'annotation',
            key: 'annotation',
            render: (count: any) => {
                return <div>{count}</div>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: any) => {
                return <div>{status}</div>;
            },
            sorter: (val1: any, val2: any) => val1.status.localeCompare(val2.status),
        },
        {
            title: 'Completed at',
            dataIndex: 'completed_at',
            key: 'completed_at',
            render: (completed: any) => {
                return <div>{completed}</div>;
            },
        },
        {
            title: 'Workflow Id',
            dataIndex: 'workflow_id',
            key: 'workflow_id',
            render: (workflow: any) => {
                return <div>{workflow}</div>;
            },
        },
        {
            title: 'Job Id',
            dataIndex: 'job_id',
            key: 'job_id',
            render: (job: any) => {
                return <div>{job}</div>;
            },
            sorter: (a: any, b: any) => a.job_id - b.job_id,
        },
        {
            title: 'Sequence Id',
            dataIndex: 'sequence_id',
            key: 'sequence_id',
            render: (sequence_id: any) => {
                return <div>{sequence_id}</div>;
            },
            sorter: (a: any, b: any) => a.sequence_id - b.sequence_id,
        },
        {
            title: 'Parent WF',
            dataIndex: 'parent_wf',
            key: 'parent_wf',
            render: (parent_wf: any, stages: any) => {
                return (
                    <>
                        <div className='parent-stage'>
                            {parent_wf}
                            {parent_wf ? (
                                <Button
                                    className='report-stage-wf'
                                    onClick={() => {
                                        showHistory(stages.workflow_id);
                                    }}
                                >
                                    View History
                                </Button>
                            ) : null}
                        </div>
                    </>
                );
            },
        },
        {
            title: 'Release User',
            dataIndex: 'workflow_id',
            key: 'workflow_id',
            render: (workflow_id: any, stages: any) => {
                return (
                    <>
                        <Popover className='report-popover' placement='bottomRight' content={content} trigger='click'>
                            <Button
                                className='report-stage-wf'
                                disabled={stages.status !== 'in_progress' || workflow_id === releaseButtonDisabled}
                                onClick={() => {
                                    releaseWorkflowHandler(stages);
                                }}
                            >
                                Release WF
                            </Button>
                        </Popover>
                    </>
                );
            },
        },
    ];

    return (
        <>
            {data.length > 0 ? (
                <Table className='stage-reports' columns={columns} dataSource={data} />
            ) : (
                <div>No Annotation info to show</div>
            )}
            <Modal
                title='Stages'
                visible={openModal}
                onOk={submitHandler}
                onCancel={workflowCancelHandler}
                cancelButtonProps={{ style: { display: 'none' } }}
                width={'75%'}
            >
                {workflowHistory.length > 0 ? (
                    <Table className='stage-reports' columns={workflowColumns} dataSource={workflowHistory} />
                ) : (
                    'No parent workflow to show'
                )}
            </Modal>
        </>
    );
}
