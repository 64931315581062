// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { ReactText } from 'react';
import Tabs from 'antd/lib/tabs';
import { UploadFileIcon } from 'icons';
import { Button } from 'antd';
import Icon, { LeftOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import Paragraph from 'antd/lib/typography/Paragraph';
import Upload, { RcFile } from 'antd/lib/upload';
import Empty from 'antd/lib/empty';
import Tree, { TreeNodeNormal } from 'antd/lib/tree/Tree';
// eslint-disable-next-line import/no-extraneous-dependencies
import { EventDataNode } from 'rc-tree/lib/interface';
import notification from 'antd/lib/notification';
import consts from 'consts';
import './files-upload.scss';

export interface Upload {
    local: File[];
    showLimitedFiles: File[];
    showLess: boolean;
    showMore: boolean;
    fileCount: number;
}

export interface Share {
    share: string[];
}

interface State {
    uploadFiles: Upload;
    shareFiles: Share;
    expandedKeys: string[];
    active: 'local' | 'share';
    typeMissmatch: boolean;
}
// #LTTS added readonly & projectType props
interface Props {
    treeData: TreeNodeNormal[];
    onUpload(value: any): void;
    onLoadData: (key: string, success: () => void, failure: () => void) => void;
    readonly: boolean;
    name: string;
    projectType: number | null;
    advanced_config: string;
    p2p_mapping: number | null;
    projectId: number | null;
    history: any;
}

export class FileManagerComponent extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            uploadFiles: {
                local: [],
                showLimitedFiles: [],
                showLess: false,
                showMore: false,
                fileCount: 5,
            },
            shareFiles: {
                share: [],
            },
            expandedKeys: [],
            active: 'local',
            typeMissmatch: false,
        };

        this.loadData('/');
    }

    public getFiles() {
        const { active, uploadFiles, shareFiles } = this.state;
        return {
            local: active === 'local' ? uploadFiles.local : [],
            share: active === 'share' ? shareFiles.share : [],
        };
    }

    private loadData = (key: string): Promise<void> =>
        new Promise<void>((resolve, reject): void => {
            const { onLoadData } = this.props;

            const success = (): void => resolve();
            const failure = (): void => reject();
            onLoadData(key, success, failure);
        });

    public reset(): void {
        const { active } = this.state;
        this.setState({
            expandedKeys: [],
            active: 'local',
            uploadFiles: {
                ...this.state.uploadFiles,
                local: [],
            },
            shareFiles: {
                share: [],
            },
        });
    }

    private handleClick = (): void => {
        const { uploadFiles, shareFiles } = this.state;
        const { onUpload, advanced_config, name, p2p_mapping, projectId } = this.props;
        const fileList = uploadFiles.local;
        const sharedList = shareFiles.share;
        const projectValues = {
            name,
            advanced_config,
            p2p_mapping,
            fileList,
            sharedList,
            projectId,
        };
        if (this.state.active === 'local') {
            {
                uploadFiles.local.length === 0
                    ? notification.error({
                          message: 'Please Select a file to upload',
                          duration: 5,
                          className: 'cvat-notification-create-task-fail',
                      })
                    : onUpload(projectValues);
            }
            return;
        }

        if (this.state.active === 'share') {
            {
                shareFiles.share.length === 0
                    ? notification.error({
                          message: 'Please Select a file to upload',
                          duration: 5,
                          className: 'cvat-notification-create-task-fail',
                      })
                    : onUpload(projectValues);
            }
            return;
        }
    };

    private fileIncrementHandler = () => {
        const { uploadFiles } = this.state;
        this.setState({
            uploadFiles: {
                ...uploadFiles,
                fileCount: uploadFiles.fileCount + 5,
            },
        });
        setTimeout(() => {
            this.showMoreFiles();
        }, 100);
    };

    private showMoreFiles() {
        const { uploadFiles } = this.state;
        const duplicateFileArray = uploadFiles.local;
        const filteredValue = duplicateFileArray.slice(0, uploadFiles.fileCount);
        this.setState({
            uploadFiles: {
                ...this.state.uploadFiles,
                showLimitedFiles: filteredValue,
                showLess: true,
            },
        });
    }

    private showLessFiles = (): void => {
        const { uploadFiles } = this.state;
        const initialFiles = uploadFiles.showLimitedFiles.slice(0, 5);
        this.setState({
            uploadFiles: {
                ...this.state.uploadFiles,
                showLimitedFiles: initialFiles,
                showLess: false,
                showMore: true,
                fileCount: 5,
            },
        });
    };

    private renderLocalSelector(): JSX.Element {
        const { uploadFiles } = this.state;
        const { readonly, projectType } = this.props;

        return (
            <Tabs.TabPane className='cvat-file-manager-local-tab' key='local' tab='My computer'>
                <Upload.Dragger
                    disabled={readonly}
                    multiple
                    className='upload-dragger-align'
                    listType='picture'
                    fileList={uploadFiles.showLimitedFiles as any[]}
                    showUploadList={uploadFiles.showLimitedFiles && { showRemoveIcon: false }}
                    beforeUpload={(_: RcFile, totalFiles: RcFile[]): boolean => {
                        const initialFiles = totalFiles.slice(0, 5).map((val: any) => val);
                        this.setState({
                            uploadFiles: {
                                ...this.state.uploadFiles,
                                local: totalFiles,
                                showLimitedFiles: initialFiles,
                            },
                        });
                        return false;
                    }}
                    onChange={(): any => {
                        if (uploadFiles) {
                            if (uploadFiles.local[0]?.type !== undefined) {
                                const { type } = uploadFiles.local[0];
                                const type1 = type.split('/');
                                const type2 = type1[0];
                                const type3 = type2 && type2.charAt(0).toUpperCase() + type2.substring(1);
//                                 if (projectType === 1 && type3 === 'Image') {
//                                 } else if (projectType === 2 && type3 === 'Video') {
//                                 } else if (projectType === 3 && type3 === 'Application') {
//                                 } else if (projectType === 4 && type3 === 'Audio') {
//                                 } else {
//                                     this.setState({
//                                         typeMissmatch: true,
//                                     });
//                                     notification.error({
//                                         message: 'Please give the correct type of file',
//                                         description: 'File type  miss match',
//                                         duration: 5,
//                                         className: 'cvat-notification-create-task-fail',
//                                     });
//                                 }
                            }
                        }
                    }}
                >
                    <p className='ant-upload-drag-icon'>
                        <Icon component={UploadFileIcon} />
                    </p>
                    <p className='upload-text'>Click or drag files to this area</p>

                    <p className='upload-hint'>Support for bulk images or a single video</p>
                </Upload.Dragger>
                {this.state.uploadFiles.local.length > this.state.uploadFiles.showLimitedFiles.length ? (
                    <>
                        <br />
                        <div className='upload-file-add-btn'>
                            <Button onClick={this.fileIncrementHandler}>Show More</Button>
                        </div>
                    </>
                ) : this.state.uploadFiles.showLess ? (
                    <>
                        <br />
                        <div className='upload-file-add-btn'>
                            <Button onClick={this.showLessFiles}>Show Less</Button>
                        </div>
                    </>
                ) : null}
            </Tabs.TabPane>
        );
    }

    private renderShareSelector(): JSX.Element {
        function renderTreeNodes(data: TreeNodeNormal[]): JSX.Element[] {
            // sort alphabetically
            data.sort((a: TreeNodeNormal, b: TreeNodeNormal): number =>
                a.key.toLocaleString().localeCompare(b.key.toLocaleString()),
            );
            return data.map((item: TreeNodeNormal) => {
                if (item.children) {
                    return (
                        <Tree.TreeNode title={item.title} key={item.key} data={item} isLeaf={item.isLeaf}>
                            {renderTreeNodes(item.children)}
                        </Tree.TreeNode>
                    );
                }

                return <Tree.TreeNode {...item} key={item.key} data={item} />;
            });
        }

        const { SHARE_MOUNT_GUIDE_URL } = consts;
        const { treeData } = this.props;
        const { expandedKeys, shareFiles } = this.state;
        const { readonly } = this.props;

        return (
            <Tabs.TabPane key='share' tab='Connected file share' disabled={readonly}>
                {treeData[0].children && treeData[0].children.length ? (
                    <Tree
                        className='cvat-share-tree'
                        checkable
                        showLine
                        height={256}
                        checkStrictly={false}
                        expandedKeys={expandedKeys}
                        checkedKeys={shareFiles.share}
                        loadData={(event: EventDataNode): Promise<void> => this.loadData(event.key.toLocaleString())}
                        onExpand={(newExpandedKeys: ReactText[]): void => {
                            this.setState({
                                expandedKeys: newExpandedKeys.map((text: ReactText): string => text.toLocaleString()),
                            });
                        }}
                        onCheck={(
                            checkedKeys:
                                | ReactText[]
                                | {
                                      checked: ReactText[];
                                      halfChecked: ReactText[];
                                  },
                        ): void => {
                            const keys = (checkedKeys as ReactText[]).map((text: ReactText): string =>
                                text.toLocaleString(),
                            );
                            this.setState({
                                uploadFiles: {
                                    ...this.state.uploadFiles,
                                    local: [],
                                },
                                shareFiles: {
                                    share: keys,
                                },
                            });
                        }}
                    >
                        {renderTreeNodes(treeData)}
                    </Tree>
                ) : (
                    <div className='cvat-empty-share-tree'>
                        <Empty />
                        <Paragraph className='cvat-text-color'>
                            Please, be sure you had
                            <Text strong>
                                <a href={SHARE_MOUNT_GUIDE_URL}> mounted </a>
                            </Text>
                            share before you built LTTS and the shared storage contains files
                        </Paragraph>
                    </div>
                )}
            </Tabs.TabPane>
        );
    }

    public render(): JSX.Element {
        const { active } = this.state;

        return (
            <div className='upload-files-container'>
                <Text className='project-menu-header'>Upload Data</Text>
                <div className='project-header-border'></div>
                <div className='project-dragger-files'>
                    <Button className='create-project-back-btn' onClick={() => this.props.history.push('/projects')}>
                        <LeftOutlined />
                        Back to Projects
                    </Button>
                    <Tabs
                        type='card'
                        activeKey={active}
                        tabBarGutter={5}
                        onChange={(activeKey: string): void => {
                            this.setState({
                                active: activeKey as any,
                            });
                        }}
                    >
                        {this.renderLocalSelector()}
                        {this.renderShareSelector()}
                    </Tabs>

                    <div className='project-menu-submit-btn'>
                        <Button className='org-form-cancel-button' onClick={() => this.props.history.push('/projects')}>
                            Cancel
                        </Button>
                        <Button
                            // disabled={typeMissmatch}
                            type='primary'
                            // loading={uploadFetching}
                            className='project-upload-button'
                            onClick={this.handleClick}
                        >
                            Upload
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default FileManagerComponent;
