// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import React, { useState } from 'react';
import { UserAddOutlined, MailOutlined, LockOutlined } from '@ant-design/icons';
import Form, { RuleRender, RuleObject } from 'antd/lib/form';
import patterns from 'utils/validation-patterns';
import { UserAgreement } from 'reducers/interfaces';
import {
    Input, Checkbox, Modal, Tooltip, Button, Row,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import './styles.scss';

export interface UserConfirmation {
    name: string;
    value: boolean;
}

export interface RegisterData {
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    password1: string;
    password2: string;
    confirmations: UserConfirmation[];
}

interface Props {
    fetching: boolean;
    userAgreements: UserAgreement[];
    onSubmit(registerData: RegisterData): void;
}

export const validatePassword: RuleRender = (): RuleObject => ({
    validator(_: RuleObject, value: string): Promise<void> {
        if (value) {
            if (!patterns.validatePasswordLength.pattern.test(value)) {
                return Promise.reject(new Error(patterns.validatePasswordLength.message));
            }

            if (!patterns.passwordContainsNumericCharacters.pattern.test(value)) {
                return Promise.reject(new Error(patterns.passwordContainsNumericCharacters.message));
            }

            if (!patterns.passwordContainsUpperCaseCharacter.pattern.test(value)) {
                return Promise.reject(new Error(patterns.passwordContainsUpperCaseCharacter.message));
            }

            if (!patterns.passwordContainsLowerCaseCharacter.pattern.test(value)) {
                return Promise.reject(new Error(patterns.passwordContainsLowerCaseCharacter.message));
            }
        }

        return Promise.resolve();
    },
});

export const validateConfirmation: ((firstFieldName: string) => RuleRender) = (
    firstFieldName: string,
): RuleRender => ({ getFieldValue }): RuleObject => ({
    validator(_: RuleObject, value: string): Promise<void> {
        if (value && value !== getFieldValue(firstFieldName)) {
            return Promise.reject(new Error('Two passwords that you enter is inconsistent!'));
        }

        return Promise.resolve();
    },
});

const validateAgreement: ((userAgreements: UserAgreement[]) => RuleRender) = (
    userAgreements: UserAgreement[],
): RuleRender => () => ({
    validator(rule: any, value: boolean): Promise<void> {
        const [, name] = rule.field.split(':');
        const [agreement] = userAgreements
            .filter((userAgreement: UserAgreement): boolean => userAgreement.name === name);
        if (agreement.required && !value) {
            return Promise.reject(new Error(`You must accept ${agreement.displayText} to continue!`));
        }

        return Promise.resolve();
    },
});

function RegisterFormComponent(props: Props): JSX.Element {
    const { fetching, userAgreements, onSubmit } = props;
    const [modal, setIsModalVisible] = useState(true);
    const history = useHistory();
    const handleCancel = () :void => {
        setIsModalVisible(false);
        history.push('/auth/login');
    };
    return (
        <Modal
            onCancel={handleCancel}
            className='register-form-popup'
            title='Create an account'
            visible={modal}
            centered
            width={600}
            maskStyle={{backgroundColor:'white'}}
            bodyStyle={{ height: '60%' }}
            footer={[
                <Row justify='center'>
                    <Text className='already-register'>
                        Already you have account?
                        <Link to='/auth/login' className='login-link-bottom'>
                            <Button
                                className='login-link-text-bottom'
                                shape='round'
                                type='primary'
                            >
                                Login
                            </Button>
                        </Link>
                    </Text>
                </Row>,
            ]}
        >
            <Form
                onFinish={(values: Record<string, string | boolean>) => {
                    const agreements = Object.keys(values)
                        .filter((key: string):boolean => key.startsWith('agreement:'));
                        const confirmations = agreements?.map((key: string): UserConfirmation => ({ name: key.split(':')[1], value: (values[key] as boolean) }));
const rest = Object.entries(values);
// .filter((entry: (string | boolean)[]) => !agreements?.includes(entry[0] as string));
                    // const confirmations = agreements
                    //     .map((key: string): UserConfirmation => ({ name: key.split(':')[1], value: (values[key] as boolean) }));
                    // const rest = Object.entries(values)
                    //     .filter((entry: (string | boolean)[]) => !agreements.includes(entry[0] as string));

                    onSubmit({
                        ...(Object.fromEntries(rest) as any as RegisterData),
                        confirmations,
                    });
                }}
                className='register-form'
            >

                <Form.Item
                    className='firstname-field'
                    name='firstName'
                    rules={[
                        {
                            required: true,
                            message: 'Please specify a first name',
                            pattern: patterns.validateName.pattern,
                        },
                    ]}
                >
                    <Input
                        className='register-form-field'
                        suffix={<UserAddOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                        placeholder='First Name'
                    />
                </Form.Item>

                <Form.Item
                    name='lastName'
                    rules={[
                        {
                            required: true,
                            message: 'Please specify a last name',
                            pattern: patterns.validateName.pattern,
                        },
                    ]}
                >
                    <Input
                        className='register-form-field'
                        suffix={<UserAddOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                        placeholder='Last Name'
                    />
                </Form.Item>

                <Form.Item
                    name='email'
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                        },
                        {
                            required: true,
                            message: 'Please specify an email address',
                        },
                    ]}
                >
                    <Input
                        className='register-form-field'
                        autoComplete='email'
                        suffix={<MailOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                        placeholder='Email'
                    />
                </Form.Item>

                <Tooltip
                    title='Password must contain 8 characters.It should contain atleast one
                    alphabet,numerical,uppercase and lowercase letter'
                    placement='right'
                >
                    <Form.Item
                        name='password1'
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your password !',
                            }, validatePassword,
                        ]}
                    >
                        <Input.Password
                            className='register-form-field'
                            autoComplete='new-password'
                            suffix={<MailOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                            placeholder='Password'
                        />
                    </Form.Item>
                </Tooltip>

                <Form.Item
                    className='confirm-passwrd-field'
                    name='password2'
                    dependencies={['password1']}
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        }, validateConfirmation('password1'),
                    ]}
                >
                    <Input.Password
                        className='register-form-field'
                        autoComplete='new-password'
                        suffix={<LockOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                        placeholder='Confirm Password'
                    />
                </Form.Item>

                <Form.Item
                    initialValue={false}
                    valuePropName='checked'
                    rules={[
                        {
                            required: true,
                            message: 'You must accept to continue!',
                        }, validateAgreement(userAgreements),
                    ]}
                >
                    <Checkbox className='checkbox-privacy-policy'>
                        <span className='first-declare-text'>By joining I declare that I have read and accepted the</span>
                        <br />
                        <span className='second-declare-text'>
                        <a rel='noopener noreferrer' target='_blank' href='https://rainbow.ltts.com/Pages/Home.aspx' className='terms-service-text'>
                            <b>Terms of Service </b>
                        </a>
                        and
                        <a rel='noopener noreferrer' target='_blank' className='Privacy-policy-text' href='https://rainbow.ltts.com/Pages/Home.aspx'>
                            <b>Privacy Policy</b>
                        </a>
                        </span>
                    </Checkbox>
                </Form.Item>

                <Form.Item className='register-form-button-field'>
                    <Button
                        shape='round'
                        type='primary'
                        htmlType='submit'
                        className='register-form-button'
                        loading={fetching}
                        disabled={fetching}
                    >
                        <b>Submit</b>
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default React.memo(RegisterFormComponent);
