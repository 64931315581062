// Copyright (C) 2021-2022 LTTS
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Text from 'antd/lib/typography/Text';
import Card from 'antd/lib/card';
import Dropdown from 'antd/lib/dropdown';
import Button from 'antd/lib/button';
import { MoreOutlined } from '@ant-design/icons';
import ProjectActionsMenuComponent from './actions-menu';
import { List, Tooltip } from 'antd';
import { Row, Col } from 'antd';
import { Project } from 'reducers/interfaces';
import { useHistory } from 'react-router';
import moment from 'moment';
import { ProjectImageIcon, ProjectVideoIcon, ProjectAudioIcon, ProjectLidarIcon } from 'icons';
import Icon from '@ant-design/icons';
import './styles.scss';

interface Props {
    projectInstance: Project;
}
export default function ProjectItemListViewComponent(props: Props): JSX.Element {
    const {
        projectInstance: { instance },
    } = props;
    const history = useHistory();
    const ownerName = instance?.owner ? instance.owner.username : null;
    const description = instance?.description ? instance.description : 'Not Available';
    const createdDate = instance?.createdDate ? instance.createdDate : null;
    const projectType = instance?.project_type;
    const created = moment(createdDate);
    const updated = moment(instance.updatedDate).fromNow();
    const onOpenProject = (): void => {
        history.push(`/projects/${instance.id}`);
    };
    const showProjectType = () => {
        let iconObj = {
            icon: '',
            text: '',
            className: '',
        };
        if(projectType === 1) {
            iconObj = {
                icon: ProjectImageIcon,
                className:'Project-icon-image-listview',
            }
        }
        else if(projectType === 2) {
            iconObj = {
                icon: ProjectVideoIcon,
                className:'Project-icon-video-listview'
            }
        }
        else if(projectType === 3) {
            iconObj = {
                icon: ProjectLidarIcon,
                className:'Project-icon-lidar-listview'
            }
        }
        else if(projectType === 4) {
            iconObj = {
                icon: ProjectAudioIcon,
                className:'Project-icon-audio-listview'
            }
        }
        return (
            <div className={iconObj.className}>
            <Row align='middle'>
                <Col span={24}>
                    <Icon component={iconObj.icon} />
                </Col>
            </Row>
        </div>
        )
    };

    return (
                <div className='list-view project-list-view-rows'>
                    <List
                        grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 3,
                            lg: 4,
                            xl: 5,
                            xxl: 6,
                        }}
                    >
                        <List.Item>
                            <Row className='ltts-project-item-list-item-listview' justify='center' align='middle'>
                                <Col span={2} className='Project-icon-image'style={{textAlign:'center' }}>
                                {showProjectType()}
                                </Col>
                                <Col span={19}>
                                    <div className='ltts-project-card-content-listview'>
                                        <div>
                                            <Text>
                                                {projectType == 1 ? (
                                                    <div className='Project-icon-image'>
                                                        <Row align='middle'>
                                                            <Col span={6} style={{ fontWeight: 'bold',color:'#3691D6' }}>
                                                                Image Annotation
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ) : projectType == 2 ? (
                                                    <div className='Project-icon-video'>
                                                        <Row align='middle'>
                                                            <Col span={6} style={{ fontWeight: 'bold',color:'#3691D6'  }}>
                                                                Video Annotation
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ) : projectType == 3 ? (
                                                    <div className='Project-icon-lidar'>
                                                        <Row align='middle'>
                                                            <Col span={6} style={{ fontWeight: 'bold' ,color:'#3691D6' }}>
                                                                LIDAR Annotation
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ) : projectType == 4 ? (
                                                    <div className='Project-icon-audio'>
                                                        <Row align='middle'>
                                                            <Col span={6} style={{ fontWeight: 'bold' ,color:'#3691D6' }}>
                                                                Audio Annotation
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ) : null}
                                            </Text>
                                        </div>

                                        <p>
                                            <span aria-hidden>
                                                <Tooltip
                                                    placement='rightTop'
                                                    title={instance?.name}
                                                    overlayStyle={{ maxWidth: '500px' }}
                                                >
                                                    <Text strong className='project-listview-description'>
                                                        Project Name :  </Text>
                                                    <span className='project-listview-description description-ellipsis'>
                                                    {instance?.name}
                                                    </span>
                                                </Tooltip>
                                            </span>
                                        </p>
                                        <p>
                                            <Tooltip
                                                placement='rightTop'
                                                title={description}
                                                overlayStyle={{ maxWidth: '500px' }}
                                            >
                                                <Text strong className='project-listview-description'>Project Description : </Text>
                                                <span className='project-listview-description description-ellipsis'>{description}
                                                </span>
                                            </Tooltip>
                                        </p>

                                        <p>
                                            <Text strong className='project-listview-description'>Creation Date : </Text>
                                            <span className='project-listview-description'>{created.format('MMMM Do YYYY HH:MM')}</span>
                                        </p>

                                        <Text strong className='project-listview-description'>Project Status : </Text>
                                        <span className='project-listview-description'>In Progress</span>
                                    </div>
                                </Col>
                                <Col span={3} className='ltts-project-item-description'>
                                    <>
                                        <Text type='secondary'><span><b>Created By : </b></span>
                                        {ownerName}</Text>
                                        <br/>
                                    </>
                                    <Text type='secondary'>{`Last updated ${updated}`}</Text>
                                    <div className='listview-action-icon'>
                                        <Dropdown
                                            overlay={<ProjectActionsMenuComponent projectInstance={instance} />}
                                            placement='bottomCenter'
                                        >
                                            <Button type='link' size='large' icon={<MoreOutlined />} />
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                        </List.Item>
                    </List>
                </div>
            )}
    

