import Icon from '@ant-design/icons';
import { Button, Table } from 'antd';
import React, { useEffect } from 'react';
import ReactExport from 'react-export-excel';
import { ReportDownloadIcon } from 'icons';
import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getDayWiseReport } from 'actions/reports-action';
import { CombinedState } from 'reducers/interfaces';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const columns = [
    {
      title: 'SerialNo',
      dataIndex: 'SerialNo',
      key: 'SerialNo',
    },
    {
      title: 'UserName',
      dataIndex: 'UserName',
      sorter: (a, b) => a.UserName.localeCompare(b.UserName),
      key: 'UserName',
    },
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
    },
    {
      title: 'AnnotationCount',
      dataIndex: 'AnnotationCount',
      key: 'AnnotationCount',
    },
  ];
  

const App = () => {
  
  const dispatch = useDispatch();
 
  const dayFilter = ()=>{
    if(startDate || endDate){
      dispatch(getDayWiseReport(startDate, endDate));
    }
  }

  let dayReport = useSelector((state: CombinedState) => state.reports.dayReports);

  let serialNo = 0;
  let dataSource =
    dayReport.map(user => {
      serialNo += 1;
      return {
      SerialNo: serialNo, 
      UserName: user.username, 
      Date:user.date,
      AnnotationCount:user.total_count
    }}) ;

  let startDate, endDate;
  const onFromChange: DatePickerProps['onChange'] = (date, dateString) => {
    startDate = dateString;
    
  };

  const onToChange: DatePickerProps['onChange'] = (date, dateString) => {
    endDate = dateString;
  };

  return (
          <> 
            <span className='day-report-date-span'>
              <DatePicker placeholder ={'StartDate'} className='day-report-date-button' onChange={onFromChange} />
              <DatePicker placeholder ={'EndDate'} onChange={onToChange} />
              <Button className='day-report-filter' onClick={dayFilter}>Filter</Button>
            </span>
            <Table columns={columns} dataSource={dataSource} />
            <span className='download-span'>
            {dataSource?.length > 0 ? (
                                  <ExcelFile
                                      element={
                                          <Button className='report-download'>
                                              <Icon component={ReportDownloadIcon} />
                                              Download
                                          </Button>
                                      }
                                  >
                                      <ExcelSheet data={dataSource} name='ProjectsReports'>
                                          <ExcelColumn label='SerialNo' value='SerialNo' />
                                          <ExcelColumn label='UserName' value='UserName' />
                                          <ExcelColumn label='Date' value='Date' />
                                          <ExcelColumn label='AnnotationCount' value='AnnotationCount' />
                                      </ExcelSheet>
                                  </ExcelFile>
                                ) : null}
            </span>
          </>
  );
}

export default App;