// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { DatePicker, Input, Row, Col, Typography, Button, Radio } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { CombinedState } from 'reducers/interfaces';
import { getProjectType, createProjects, clickNext, getInitialState, getProjectData, getProjectDataById } from 'actions/projects-mgnt-actions';
import notification from 'antd/lib/notification';
import { ImageIcon, VideoIcon, AudioIcon, LidarIcon } from 'icons';
import Icon, { LeftOutlined } from '@ant-design/icons';
import Modal from 'antd/lib/modal';
import Spin from 'antd/lib/spin';
import { useHistory, useParams } from 'react-router';

const { TextArea } = Input;
const { Title } = Typography;

interface Props {
    disabled: boolean;
    formRef: any;
    formvalues:any;
    initialValues:any
}

export function NameConfigurationForm(props: Props): JSX.Element {
   
    const params = useParams();
    const id= params.id;
    const { formvalues, formRef, disabled, initialValues } = props;
    
    const dateFormat = 'YYYY-MM-DD';
    const history = useHistory();
    const newProjectId = useSelector((state: CombinedState) => state.projects.activities.creates.id);
    let projectList: any = [];
    projectList = useSelector((state: CombinedState) => state.projects.project_list);
    let formData = {...formvalues, start_date: formvalues.start_date ? moment(formvalues.start_date) : ''}

    const onBackToProjectsConfirm = (): void => {
        if(newProjectId) {
            history.push('/projects');
            // dispatch(getInitialState());
            return;
        }
        Modal.confirm({
            title: 'Do you want to go back to project dashboard?',
            content: 'All project related data will be lost. Continue?',
            className: 'ltts-modal-confirm-delete-task',
            onOk: () => {
                // dispatch(getInitialState());
                history.push('/projects');
            },
            okButtonProps: {
                type: 'primary',
                danger: true,
            },
            okText: 'Yes',
            cancelText: 'No',
        });
    };

    return (
        <div className='create-project-main'>
            <Button className='create-project-back-btn'
            onClick={onBackToProjectsConfirm}
            // onClick={() => history.push('/projects')}
            >
                <LeftOutlined />
                Back to Projects
            </Button>
            <Row justify='start'>
                <Col  span={formvalues ? 24 : 24} className='create-project-step1'>
                    <Title level={5} className='project-title1'>
                        Create Project
                    </Title>
                    <Form
                        name='basic'
                        labelCol={{
                            span: 6,
                        }}
                        wrapperCol={{
                            span: 20,
                        }}
                        initialValues={{
                            name: initialValues.name? initialValues.name:formData.name,
                            description: initialValues.description? initialValues.description:formData.description,
                            startDate: initialValues.start_date?moment(initialValues.start_date) :formData.start_date,
                            project_type: initialValues.project_type? initialValues.project_type:formData.project_type,
                        }}
                        autoComplete='off'
                        className='project-item'
                        ref={formRef}
                    >
                        <Form.Item
                            key='name'
                            name='name'
                            label='Project Name'
                            labelAlign='left'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please, specify a Project Name',
                                },
                            ]}
                        >
                            <Input disabled={disabled} placeholder='Project Name' allowClear className='project-list' />
                        </Form.Item>

                        <Form.Item
                            key='description'
                            name='description'
                            label='Description'
                            labelAlign='left'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please, specify a Description',
                                },
                            ]}
                        >
                            <TextArea placeholder='Project Description' allowClear className='project-list' />
                        </Form.Item>
                        <Form.Item
                            key='startDate'
                            name='startDate'
                            label='Start Date'
                            labelAlign='left'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please, specify a Start Date',
                                },
                            ]}
                        >
                            <DatePicker
                                size='large'
                                placeholder='Start Date'
                                format={dateFormat}
                                disabled={disabled}
                                disabledDate={(current) => current < moment().add(-1,'days')}
                                className='project-list'
                            />
                        </Form.Item>
                        <Form.Item
                            key='project_type'
                            name='project_type'
                            label='Project Type'
                            labelAlign='left'
                            rules={[{ required: true, message: 'Please select Project type!' }]}
                        >
                            <Radio.Group disabled={disabled}>
                                {projectList?.map((val: any) => {
                                    if (val.id == 1)
                                        return (
                                            <>
                                                <Radio key={val.id} value={val.id}>
                                                    <Icon component={ImageIcon} className='imageIcon-align' />
                                                    {val.project_type}
                                                </Radio>
                                            </>
                                        );
                                    else if (val.id == 2)
                                        return (
                                            <Radio key={val.id} value={val.id}>
                                                <Icon component={VideoIcon} className='imageIcon-align' />{' '}
                                                {val.project_type}
                                            </Radio>
                                        );
                                    else if (val.id == 3)
                                        return (
                                            <Radio key={val.id} value={val.id}>
                                                <Icon component={AudioIcon} className='imageIcon-align2' />{' '}
                                                {val.project_type}
                                            </Radio>
                                        );
                                    else if (val.id == 4)
                                        return (
                                            <Radio key={val.id} value={val.id}>
                                                <Icon component={LidarIcon} className='imageIcon-align2' />{' '}
                                                {val.project_type}
                                            </Radio>
                                        );
                                })}
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}

function CreateProject(): JSX.Element {
    const params = useParams();
    const id= params.id;
    useEffect(() => {
        dispatch(getProjectType());
        if(id) {

            dispatch(getProjectDataById({id}));
          }

    }, [id]);
    const newProjectDetails = useSelector((state: CombinedState) => state.projects.projectDataeditable);
    
    let formvalues: any = {};
    const nameFormRef = useRef<FormInstance>(null);
    const dispatch = useDispatch();
    const dateFormat = 'YYYY-MM-DD HH:MM';
    const [projectLabels, setProjectLabels] = React.useState<any[]>([]);
    const shouldShowNotification = useRef(false);
    const advancedFormRef = useRef<FormInstance>(null);
    const projectData = useSelector((state: CombinedState) => state.projects.activities.creates);
    const newProjectId = useSelector((state: CombinedState) => state.projects.activities.creates.id);
    const projectFetching = useSelector((state: CombinedState) => state.projects.fetching);
    formvalues = {...projectData};

     // useEffect(() => {
    //     if(newProjectId) {
    //         formvalues = {...projectData};
    //     }
    //     else {
    //         formvalues = {...projectData};
    //     }

    //     // if (Number.isInteger(newProjectId) && shouldShowNotification.current) {
    //     //     if (nameFormRef.current) nameFormRef.current.resetFields();
    //     //     if (advancedFormRef.current) advancedFormRef.current.resetFields();
    //     //     setProjectLabels([]);
    //     //     notification.info({
    //     //         message: 'The project has been created',
    //     //         className: 'cvat-notification-create-project-success',
    //     //         duration: 10,
    //     //     });
    //     // }
    //     // shouldShowNotification.current = true;
    // }, [newProjectId]);

    const onSumbit = async (): Promise<void> => {
        let projectData: Record<string, any> = {};
        if (nameFormRef.current) {
            const basicValues = await nameFormRef.current.validateFields();
            formvalues = {...basicValues};
            projectData = {
                name: basicValues.name,
                description: basicValues.description,
                project_type: basicValues.project_type,
                start_date: (basicValues.startDate).format(dateFormat),
            };
            if(id) {
                projectData.id = id;
                projectData.type = 'edit';
                projectData.project_config = newProjectDetails.project_config
            }
        }
        dispatch(createProjects(projectData));
    };

    return (
        <>
        <Spin spinning={projectFetching} className='ltts-spinner'>
        <Row justify='space-between' className="create-project-form">
                <Col span={24} className="form-fields-container">
                    <NameConfigurationForm key ={newProjectDetails.name} formRef={nameFormRef} formvalues={formvalues} disabled={id?true:false} initialValues={newProjectDetails}/>
                </Col>
                <Col span={24} className='project-bottom-align'>
                    {/* <Row justify='end' align='middle' className='project-bottom-align'>
                       <Col span={3} offset={21}> */}
                           <Button type='primary' onClick={onSumbit} className='next-button'>
                            Next
                            </Button>
                        {/* </Col>
                    </Row> */}
                </Col>
            </Row>
            </Spin>
        </>
    );
}

export default CreateProject;
