


export enum OrganizationSagaActionsTypes {
    CREATE_ORGANIZATION = 'CREATE_ORGANIZATION',
    CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS',
    CREATE_ORGANIZATION_FAILED = 'CREATE_ORGANIZATION_FAILED',
}

export function createOrganization(organizationData:any) {
    return {
        type: OrganizationSagaActionsTypes.CREATE_ORGANIZATION,
        payload: { organizationData }
    };
}
