// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT
import { AnyAction } from 'redux';
import { AppsNStagesActionsTypes } from 'actions/apps-stages-actions';
import { AuthActionTypes } from 'actions/auth-actions';

export const defaultState: any = {
    initialized: false,
    fetching: false,
    stagesCreated: false,
    error: null,
    apps: [],
    users: [],
    isIframeOpen: false,
    ticketReports: [],
    projectApps: [],
    assignedProjectApps: [],
    projectMetaData: null,
    closeMode: false,
    fetchJobsTasksId: false,
    isWorkFlowSubmitted: false,
    appProjectId: {},
    admins: [],
    assignedProjectAppsToProjects: [],
    assignedProjectAppsToUsers: {},
    previousStages: {},
    showRejectModal: false,
    rejectMessage: '',
    workflowMessage: '',

};

export default (state: any = defaultState, action: AnyAction): any => {
    switch (action.type) {
        case AppsNStagesActionsTypes.CREATE_STAGES:
            return {
                ...state,
                stagesCreated: false,
                fetching: true,
            };
        case AppsNStagesActionsTypes.CREATE_STAGES_SUCCESS:
            return {
                ...state,
                projectApps: action.projectApps,
                stagesCreated: true,
                fetching: false,
            };
        case AppsNStagesActionsTypes.CREATE_STAGES_FAILED:
            return {
                ...state,
                stagesCreated: false,
                error: action.message,
                fetching: false,
            };
        case AppsNStagesActionsTypes.GET_APPS_REQUESTED:
            return {
                ...state,
                stagesCreated: false,
                fetching: true,
            };
        case AppsNStagesActionsTypes.GET_APPS_SUCCESS:
            return {
                ...state,
                apps: action.apps,
                fetching: false,
            };
        case AppsNStagesActionsTypes.GET_APPS_FAILED:
            return {
                ...state,
                error: action.message,
                fetching: false,
            };
        case AppsNStagesActionsTypes.GET_USERS_REQUESTED:
            return {
                ...state,
                fetching: true,
            };
        case AppsNStagesActionsTypes.GET_USERS_SUCCESS:
            return {
                ...state,
                users: action.users,
                fetching: false,
            };
        case AppsNStagesActionsTypes.GET_USERS_FAILED:
            return {
                ...state,
                error: action.message,
                fetching: false,
            };
        case AppsNStagesActionsTypes.GET_ADMINS_REQUESTED:
            return {
                ...state,
                fetching: true,
            };
        case AppsNStagesActionsTypes.GET_ADMINS_SUCCESS:
            return {
                ...state,
                admins: action.admins,
                fetching: false,
            };
        case AppsNStagesActionsTypes.GET_ADMINS_FAILED:
            return {
                ...state,
                error: action.message,
                fetching: false,
            };
        case AppsNStagesActionsTypes.SAVE_ADMINS:
            return {
                ...state,
                fetching: true,
            };
        case AppsNStagesActionsTypes.SAVE_ADMINS_SUCCESS:
            return {
                ...state,
                admins: action.response,
                fetching: false,
            };
        case AppsNStagesActionsTypes.SAVE_ADMINS_FAILED:
            return {
                ...state,
                error: action.message,
                fetching: false,
            };
        case AppsNStagesActionsTypes.FETCH_ASSIGNED_PROJECT_APPS:
            return {
                ...state,
                fetching: true,
            };
        case AppsNStagesActionsTypes.FETCH_ASSIGNED_PROJECT_APPS_SUCCESS:
            return {
                ...state,
                assignedProjectAppsToProjects: action.response,
                fetching: false,
            };
        case AppsNStagesActionsTypes.FETCH_ASSIGNED_PROJECT_APPS_FAILED:
            return {
                ...state,
                error: action.message,
                fetching: false,
            };
        case AppsNStagesActionsTypes.PROJECT_APPS_USER_ASSIGNED:
            return {
                ...state,
                fetching: true,
            };
        case AppsNStagesActionsTypes.PROJECT_APPS_USER_ASSIGNED_SUCCESS:
            return {
                ...state,
                assignedProjectAppsToUsers: action.usersAssingedApps,
                fetching: false,
            };
        case AppsNStagesActionsTypes.PROJECT_APPS_USER_ASSIGNED_FAILED:
            return {
                ...state,
                error: action.message,
                fetching: false,
            };
        case AppsNStagesActionsTypes.MAP_USERS_TO_APP:
            return {
                ...state,
                fetching: true,
            };
        case AppsNStagesActionsTypes.MAPPING_USERSTOAPP_SUCESS:
            return {
                ...state,
                fetching: false,
            };
        case AppsNStagesActionsTypes.MAPPING_USERSTOAPP_FAILED:
            return {
                ...state,
                error: action.message,
                fetching: false,
            };
        case AppsNStagesActionsTypes.FETCH_PROJECT_APPS:
            return {
                ...state,
                fetching: true,
            };
        case AppsNStagesActionsTypes.FETCH_PROJECT_APPS_SUCCESS:
            return {
                ...state,
                assignedProjectApps: action.response,
                fetching: false,
            };
        case AppsNStagesActionsTypes.FETCH_PROJECT_APPS_FAILED:
            return {
                ...state,
                error: action.message,
                fetching: false,
            };
        case AppsNStagesActionsTypes.FETCH_WORKFLOW_MAPPING:
            return {
                ...state,
                projectMetaData: null,
                isWorkFlowSubmitted: false,
                fetching: true,
                workflowMessage: '',
            };
        case AppsNStagesActionsTypes.SUBMIT_CLOSE:
            return {
                ...state,
                closeMode: true
            };
        case AppsNStagesActionsTypes.SUBMIT_NEXT:
            return {
                ...state,
                closeMode: false
            };
        case AppsNStagesActionsTypes.FETCH_WORKFLOW_MAPPING_SUCCESS:
            const { project_meta_data, token, workflow_id, message, app_config, project_id,rotation,translation } = action.response;
            return {
                ...state,
                projectMetaData: { project_meta_data, token, workflow_id, app_config, project_id,rotation,translation },
                appProjectId: action.payload,
                isIframeOpen: project_meta_data ? true : false,
                fetching: false,
                workflowMessage: message,
            };
        case AppsNStagesActionsTypes.FETCH_WORKFLOW_MAPPING_FAILED:
            return {
                ...state,
                error: action.message,
                projectMetaData: null,
                isIframeOpen: false,
                fetching: false,
            };
        case AppsNStagesActionsTypes.FETCH_ANNOTATOR_TIME_SUCCESS:
            return {
                ...state,
            };
        case AppsNStagesActionsTypes.FETCH_TICKET_REPORTS_SUCCESS:
            return {
                ...state,
                ticketReports: action.response,
            };
        case AppsNStagesActionsTypes.RESET_TICKETS_REPORTS_STATE:
            return {
                ...state,
                ticketReports: []
            }
        case AppsNStagesActionsTypes.FETCH_PREVIOUS_STAGES_SUCCESS:
            return {
                ...state,
                previousStages: action.response
            };

        case AppsNStagesActionsTypes.CREATE_REJECT_STAGES_SUCCESS:
            return {
                ...state,
                previousStages: {},
                rejectMessage: action.response
            };

        case AppsNStagesActionsTypes.SWITCH_REJECT_DIALOG: {
            return {
                ...state,
                showRejectModal: typeof action.payload.show === 'undefined' ? !state.showRejectModal : action.payload.show,
            };
        }

        case AppsNStagesActionsTypes.CHANGE_IFRAME_VALUE:
            return {
                ...state,
                projectMetaData: null,
                isIframeOpen: action.payload,

            };
        case AppsNStagesActionsTypes.SAVE_WORKFLOW:
            return {
                ...state,
                fetching: true,
            };
        case AppsNStagesActionsTypes.SAVE_WORKFLOW_SUCCESS:
            return {
                ...state,
                isWorkFlowSubmitted: true,
                isIframeOpen: false,
                fetching: false,
            };
        case AppsNStagesActionsTypes.SAVE_WORKFLOW_FAILED:
            return {
                ...state,
                error: action.message.response.data.message,
                isWorkFlowSubmitted: false,
                fetching: false,
            };
        case AppsNStagesActionsTypes.RESET_APPS_STATE:
            return { ...defaultState }
        case AuthActionTypes.LOGOUT_SUCCESS: {
            return { ...defaultState }
        };
        default:
            return state;
    }
};
