// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { BoundariesActions, BoundariesActionTypes } from 'actions/boundaries-actions';
import { AuthState } from './interfaces';
import { AuthSagaActionTypes } from 'actions/auth-saga-actions';
import { AuthSagaActions } from 'saga/auth-saga';
const defaultState: AuthState = {
    initialized: false,
    fetching: false,
    user: null,
    authActionsFetching: false,
    authActionsInitialized: false,
    allowChangePassword: false,
    showChangePasswordDialog: false,
    allowResetPassword: false,
};

export default function (state = defaultState, action: AuthSagaActions | BoundariesActions): AuthState {
    switch (action.type) {
        case AuthSagaActionTypes.REGISTER_SUCCESS:
            return {
                ...state,
                fetching: false,
                user: action.payload.user,
            };
        case AuthSagaActionTypes.REGISTER_FAILED:
            return {
                ...state,
                fetching: false,
            };

        case BoundariesActionTypes.RESET_AFTER_ERROR: {
            return { ...defaultState };
        }
        default:
            return state;
    }
}
