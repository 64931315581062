import Icon from '@ant-design/icons';
import { Button, Table } from 'antd';
import React, { useEffect } from 'react';
import type { TablePaginationConfig } from 'antd/es/table';
import ReactExport from 'react-export-excel';
import { ReportDownloadIcon } from 'icons';
import { useDispatch, useSelector } from 'react-redux';
import { getUserWiseReport } from 'actions/reports-action';
import { CombinedState } from 'reducers/interfaces';

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
  }

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const columns = [
  {
    title: 'SerialNo',
    dataIndex: 'SerialNo',
    key: 'SerialNo',
  },
  {
    title: 'UserName',
    dataIndex: 'UserName',
    sorter: (a, b) => a.UserName.localeCompare(b.UserName),
    key: 'UserName',
  },
  {
    title: 'No of Rejection',
    dataIndex: 'NoofRejection',
    key: 'NoofRejection',
  },
  {
    title: 'No of Project involve',
    dataIndex: 'NoofProjectinvolve',
    key: 'NoofProjectinvolve',
  },
  {
    title: 'Total no of Annotation',
    dataIndex: 'TotalnoofAnnotation',
    key: 'TotalnoofAnnotation',
  },
];
const data = [
  {
    key: '1',
    SerialNo: 1,
    UserName: 'ayz',
    NoofRejection: 1,
    NoofProjectinvolve: 1,
    TotalnoofAnnotation: 100,
  },
  {
    key: '1',
    SerialNo: '2',
    UserName: 'byz',
    NoofRejection: 1,
    NoofProjectinvolve: 1,
    TotalnoofAnnotation: 100,
  },
  {
    key: '1',
    SerialNo: '3',
    UserName: 'cyz',
    NoofRejection: 1,
    NoofProjectinvolve: 1,
    TotalnoofAnnotation: 100,
  },
  {
    key: '1',
    SerialNo: '4',
    UserName: 'dyz',
    NoofRejection: 1,
    NoofProjectinvolve: 1,
    TotalnoofAnnotation: 100,
  },
  {
    key: '1',
    SerialNo: '5',
    UserName: 'eyz',
    NoofRejection: 1,
    NoofProjectinvolve: 1,
    TotalnoofAnnotation: 100,
  },
];

let data1 = [];
for (let i = 0; i < data.length; i++) {
    data1.push({
        'SerialNo': data[i].SerialNo,
        'UserName':data[i].UserName,
        'NoofRejection':data[i].NoofRejection,
        'NoofProjectinvolve':data[i].NoofProjectinvolve,
        'TotalnoofAnnotation':data[i].TotalnoofAnnotation,
      })
}
 
  const App = () => {

  const dispatch = useDispatch();
    useEffect(() => {
      dispatch(getUserWiseReport());
    }, []);
    
    let userReport = useSelector((state: CombinedState) => state.reports.userReports);
    
    let serialNo = 0;
    let dataSource =
      userReport.map(user => {
        serialNo += 1;
        return {
        SerialNo: serialNo, 
        UserName: user.username, 
        NoofRejection:user.no_of_rejections,
        NoofProjectinvolve:user.no_of_projects_involved,
        TotalnoofAnnotation:user.total_no_of_annotations  
      }}) ;
    
  return (
    <>
      <Table columns={columns} dataSource={dataSource} />
      <span className='download-span'>
      {data?.length > 0 ? (
                            <ExcelFile
                                element={
                                    <Button className='report-download'>
                                        <Icon component={ReportDownloadIcon} />
                                        Download
                                    </Button>
                                }
                            >
                                <ExcelSheet data={dataSource} name='ProjectsReports'>
                                    <ExcelColumn label='SerialNo' value='SerialNo' />
                                    <ExcelColumn label='UserName' value='UserName' />
                                    <ExcelColumn label='No of Rejection' value='NoofRejection' />
                                    <ExcelColumn label='No of Project involve' value='NoofProjectinvolve' />
                                    <ExcelColumn label='Total no of Annotation' value='TotalnoofAnnotation' />
                                </ExcelSheet>
                            </ExcelFile>
                          ) : null
        }

      </span>
    </>
  );
}

export default App;