// Copyright (C) 2021-2022 LTTS
//
// SPDX-License-Identifier: MIT

import './header.scss';
import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Icon, {
    SettingOutlined,
    LogoutOutlined,
    QuestionCircleOutlined,
    DownOutlined,
    ControlOutlined,
    UserOutlined,
    BellOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import Layout from 'antd/lib/layout';
import Menu from 'antd/lib/menu';
import Dropdown from 'antd/lib/dropdown';
import Modal from 'antd/lib/modal';
import IdleTimer from 'react-idle-timer';
import getCore from 'ltts-core-wrapper';
import {
    ProfileIcon,
    RejectIcon,
    SaveAndCloseIcon,
    SubmitAndCloseIcon,
    SubmitLoadNextIcon,
    ProjectInfoIcon,
} from 'icons';
import { notification } from 'antd';
// #LTTS changes New code added by Raju N
import ChangePasswordDialog from 'components/change-password-modal/change-password-modal';
// import SettingsModal from './settings-modal/settings-modal';
import SettingsModal from 'components/header/settings-modal/settings-modal';
import { switchSettingsDialog as switchSettingsDialogAction } from '../../actions/settings-actions';
import { logoutAsync, authActions } from '../../actions/auth-actions';
// import { authSagaActions } from 'sagas/auth-saga';
import { CombinedState } from '../../reducers/interfaces';
import { Button } from 'antd';
// import { logoutAsync } from 'actions/auth-saga-actions';
import { AnnotIcon } from 'icons';
import {
    activateIframe,
    saveWorkflowId,
    dispatchPreviousStage,
    switchRejectDialog,
    submitClose,
    submitNext,
    fetchAnnotatorTime,
} from 'actions/apps-stages-actions';
import ProjectStagesRejectionComponent from 'components/dashboard/apps-stages/apps-stages-reject';
import { activeIframeCustomer } from 'actions/reports-action';
import moment from 'moment';
const core = getCore();

interface Tool {
    name: string;
    description: string;
    server: {
        host: string;
        version: string;
    };
    core: {
        version: string;
    };
    canvas: {
        version: string;
    };
    ui: {
        version: string;
    };
}

interface StateToProps {
    user: any;
    tool: Tool;
    switchSettingsShortcut: string;
    settingsDialogShown: boolean;
    changePasswordDialogShown: boolean;
    changePasswordFetching: boolean;
    logoutFetching: boolean;
    renderChangePasswordItem: boolean;
    isAnalyticsPluginActive: boolean;
    isModelsPluginActive: boolean;
    isGitPluginActive: boolean;
}

interface DispatchToProps {
    onLogout: () => void;
    switchSettingsDialog: (show: boolean) => void;
    switchChangePasswordDialog: (show: boolean) => void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        auth: {
            user,
            fetching: logoutFetching,
            fetching: changePasswordFetching,
            showChangePasswordDialog: changePasswordDialogShown,
            allowChangePassword: renderChangePasswordItem,
        },
        plugins: { list },
        about: { server, packageVersion },
        shortcuts: { normalizedKeyMap },
        settings: { showDialog: settingsDialogShown },
    } = state;

    return {
        user,
        tool: {
            name: server.name as string,
            description: server.description as string,
            server: {
                host: core.config.backendAPI.slice(0, -7),
                version: server.version as string,
            },
            canvas: {
                version: packageVersion.canvas,
            },
            core: {
                version: packageVersion.core,
            },
            ui: {
                version: packageVersion.ui,
            },
        },
        switchSettingsShortcut: normalizedKeyMap.SWITCH_SETTINGS,
        settingsDialogShown,
        changePasswordDialogShown,
        changePasswordFetching,
        logoutFetching,
        renderChangePasswordItem,
        isAnalyticsPluginActive: list.ANALYTICS,
        isModelsPluginActive: list.MODELS,
        isGitPluginActive: list.GIT_INTEGRATION,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onLogout: (): void => dispatch(logoutAsync()),
        switchSettingsDialog: (show: boolean): void => dispatch(switchSettingsDialogAction(show)),
        switchChangePasswordDialog: (show: boolean): void => dispatch(authActions.switchChangePasswordDialog(show)),
    };
}

const formatTime = (timer: any) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes} : ${getSeconds}`;
};

interface HeaderProps {
    collapsed: any;
}

type Props = StateToProps & DispatchToProps & HeaderProps;

function HeaderContainer(props: Props): JSX.Element {
    const {
        user,
        tool,
        logoutFetching,
        changePasswordFetching,
        settingsDialogShown,
        switchSettingsShortcut,
        onLogout,
        switchSettingsDialog,
        switchChangePasswordDialog,
        renderChangePasswordItem,
        isAnalyticsPluginActive,
        isModelsPluginActive,
        collapsed,
    } = props;

    const [IsVisible, setIsVisible] = useState(false);
    const [idleTimer, setidleTimer] = useState(null);
    const [logoutTimer, setlogoutTimer] = useState(null);
    const active = true;
    const sessionTimeoutPeriod = 30;
    let timeout: any;
    const idleTimerRef = useRef(idleTimer);
    // #LTTS changes code added by Raju

    const [timer, setTimer] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const intervalRef = useRef(null);

    const [enableReject, setEnableReject] = useState(true);

    const [enableCustomerReject, setEnableCustomerReject] = useState(true);

    const { projectMetaData, isIframeOpen, previousStages, showRejectModal, appProjectId, assignedProjectApps } =
        useSelector((state: CombinedState) => state.appsStages);

    const isIframeOpencustomer = useSelector((state: CombinedState) => state.reports?.isIframeOpen);
    const { viewTaskDetails } = useSelector((state: CombinedState) => state.reports);

    useEffect(() => {
        assignedProjectApps.map((id: any, index: any) => {
            if (id.project_app_id === appProjectId?.payload?.project_app_id) {
                assignedProjectApps[index]?.app_config?.levels?.mode.map((mode: any) => {
                    mode === 'reject' ? setEnableReject(false) : setEnableReject(true);
                });
            }
        });
    }, [appProjectId, enableReject]);

    useEffect(() => {
        viewTaskDetails?.app_config?.levels?.mode.map((mode: any) => {
            mode === 'reject' ? setEnableCustomerReject(false) : setEnableCustomerReject(true);
        });
    }, [viewTaskDetails, enableCustomerReject]);
    const rejectModal = () => {
        const workflowId = projectMetaData?.workflow_id;
        if (workflowId) {
            dispatch(dispatchPreviousStage(workflowId));
        }
    };

    const rejectCustomerModal = () => {
        const workflowId = viewTaskDetails?.workflow_id;
        if (workflowId) {
            dispatch(dispatchPreviousStage(workflowId));
        }
    };

    const projectInfoModal = () => {
        const workflowId = projectMetaData?.workflow_id;
        const jobId = projectMetaData?.project_meta_data.job_id;
        const taskId = projectMetaData?.project_meta_data.task_id;
        const projectName = projectMetaData?.project_meta_data.project_name;
        const checkFn = () => {
            return (
                <div>
                    <div>
                        <div className='project-info-header-div'>
                            <span className='Project-info-text'>
                                <b>Project Info</b>
                            </span>
                        </div>
                    </div>
                    <div className='project-info-body'>
                        <div className='project-info-name'>
                            <span>
                                <b>ProjectName :</b>
                            </span>
                            <span className='project-name-class'>{projectName}</span>
                        </div>
                        <br></br>
                        <div className='project-info-ids'>
                            <p>
                                <span>
                                    <b>Workflow Details :</b>
                                </span>
                            </p>
                            {/* <p>WorkflowID : {workflowId}</p> */}
                            <p>Task ID : {taskId}</p>
                            <p>Job ID : {jobId}</p>
                        </div>
                    </div>
                </div>
            );
        };

        if (workflowId) {
            Modal.info({
                content: checkFn(),
                className: 'project-info-modal',
                width: 600,
            });
        }
    };

    useEffect(() => {
        if (Object.keys(previousStages).length > 0) {
            if (previousStages?.previous_stages?.length > 0) {
                dispatch(switchRejectDialog(true));
            } else if (previousStages?.previous_stages?.length === 0) {
                notification.info({
                    message: 'No Previous Stages to Show',
                });
            }
        }
    }, [previousStages]);

    const dispatch = useDispatch();
    const onLogoutPopConfirm = (): void => {
        Modal.confirm({
            title: 'Do you want to logout?',
            content: 'All related data (images, annotations) will be lost. Continue?',
            className: 'ltts-modal-confirm-delete-task',
            onOk: () => {
                isIframeOpen ? (handleTime(), onLogout()) : onLogout();
            },
            okButtonProps: {
                type: 'primary',
                danger: true,
            },
            okText: 'Yes',
            cancelText: 'No',
        });
    };

    const closeIFrame = (): void => {
        const ANNOTATION_UI_URL = process.env.REACT_APP_ANNOTATION_UI_URL;
        const iframe = document.getElementById('iframeId');
        const iWindow = iframe.contentWindow;
        iWindow.postMessage('saveAnnoButton', ANNOTATION_UI_URL);
        iWindow.postMessage('closeFrame', ANNOTATION_UI_URL);
        const checkFn = () => {
            return (
                <div>
                    <div>
                        <span className='asterik-red'>
                            <b>Please make sure you have done the following before closing .</b>
                        </span>
                    </div>
                    <br></br>
                    <ul className='warning-content-body'>
                        <li className='warning-content-body'>
                            Verify all the annotations are <b>saved</b>.{' '}
                        </li>
                        <li className='warning-content-body'>
                            Make a note of Total Annotations done by you so far. (total annotations can be accessed from
                            "information icon <b>i</b>")
                        </li>
                    </ul>
                    <br></br>
                    <br />
                    <p>
                        <span className='asterik-red'>*</span>
                        <span className='asterik-red'>
                            if you missed to do any of above, please close this pop up message and complete above
                            mentioned steps.{' '}
                        </span>
                    </p>
                </div>
            );
        };
        Modal.confirm({
            content: checkFn(),
            width: 700,
            className: '',
            onOk: () => {
                dispatch(activateIframe(false));
                handleTime();
            },
            okButtonProps: {
                type: 'primary',
                danger: true,
            },
            okText: 'Yes',
            cancelText: 'No',
        });
    };

    const closeIFrameCustomer = (): void => {
        const ANNOTATION_UI_URL = process.env.REACT_APP_ANNOTATION_UI_URL;
        const iframe = document.getElementById('iframeId');
        const iWindow = iframe.contentWindow;
        iWindow.postMessage('saveAnnoButton', ANNOTATION_UI_URL);
        iWindow.postMessage('closeFrame', ANNOTATION_UI_URL);
        const checkFn = () => {
            return (
                <div>
                    <div>
                        <span className='asterik-red'>
                            <b>Please make sure you have done the following before closing .</b>
                        </span>
                    </div>
                    <br></br>
                    <ul className='warning-content-body'>
                        <li className='warning-content-body'>
                            Verify all the annotations are <b>saved</b>.{' '}
                        </li>
                        <li className='warning-content-body'>
                            Make a note of Total Annotations done by you so far. (total annotations can be accessed from
                            "information icon <b>i</b>")
                        </li>
                    </ul>
                    <br></br>
                    <br />
                    <p>
                        <span className='asterik-red'>*</span>
                        <span className='asterik-red'>
                            if you missed to do any of above, please close this pop up message and complete above
                            mentioned steps.{' '}
                        </span>
                    </p>
                </div>
            );
        };
        Modal.confirm({
            content: checkFn(),
            width: 700,
            className: '',
            onOk: () => {
                dispatch(activateIframe(false));
                dispatch(activeIframeCustomer());
            },
            okButtonProps: {
                type: 'primary',
                danger: true,
            },
            okText: 'Yes',
            cancelText: 'No',
        });
    };

    const submitAnnotation = (): void => {
        const checkFn = () => {
            return (
                <div>
                    <div>
                        {' '}
                        <span className='asterik-red'>
                            <b>Do you really want to submit this task ?</b>
                        </span>
                    </div>
                    <br></br>
                    <ul className='warning-content-body'>
                        <li className='warning-content-body'>
                            once submitted, this task will be{' '}
                            <span>
                                <b>sent to next stage</b>
                            </span>{' '}
                            and can't be re-assigned again.
                        </li>
                        <li className='warning-content-body'>
                            please Verify all the annotations performed by you are saved and if any necessary changes
                            required, click "No" and modify.
                        </li>
                    </ul>
                    <br></br>
                    <br />
                    <p>
                        <span className='asterik-red'>*</span>
                        <span className='asterik-red'>
                            Please note that all the annotations done by you are counted against you and will be shared
                            to respective leads.
                        </span>
                    </p>
                </div>
            );
        };
        Modal.confirm({
            content: checkFn(),
            width: 700,
            onOk: () => {
                dispatch(submitNext());
                const ANNOTATION_UI_URL = process.env.REACT_APP_ANNOTATION_UI_URL;
                const iframe = document.getElementById('iframeId');
                const iWindow = iframe.contentWindow;
                iWindow.postMessage('saveAnnoButton', ANNOTATION_UI_URL);
                iWindow.postMessage('closeFrame', ANNOTATION_UI_URL);
                dispatch(saveWorkflowId({ workflow_id: projectMetaData.workflow_id, status: 'completed' }));
                handleTime();
            },
            okButtonProps: {
                type: 'primary',
                danger: true,
            },
            okText: 'Yes',
            cancelText: 'No',
        });
    };

    const submitCloseAnnotation = (): void => {
        const checkFn = () => {
            return (
                <div>
                    <div>
                        {' '}
                        <span className='asterik-red'>
                            <b>Do you really want to submit & close this task ?</b>
                        </span>
                    </div>
                    <br></br>
                    <ul className='warning-content-body'>
                        <li className='warning-content-body'>
                            once submitted, this task will be{' '}
                            <span>
                                <b>sent to next stage</b>
                            </span>{' '}
                            and can't be re-assigned again.
                        </li>
                        <li className='warning-content-body'>
                            please Verify all the annotations performed by you are saved and if any necessary changes
                            required, click "No" and modify.
                        </li>
                    </ul>
                    <br></br>
                    <br />
                    <p>
                        <span className='asterik-red'>*</span>
                        <span className='asterik-red'>
                            Please note that all the annotations done by you are counted against you and will be shared
                            to respective leads.
                        </span>
                    </p>
                </div>
            );
        };
        Modal.confirm({
            content: checkFn(),
            width: 700,
            onOk: () => {
                dispatch(submitClose());
                const ANNOTATION_UI_URL = process.env.REACT_APP_ANNOTATION_UI_URL;
                const iframe = document.getElementById('iframeId');
                const iWindow = iframe.contentWindow;
                iWindow.postMessage('saveAnnoButton', ANNOTATION_UI_URL);
                iWindow.postMessage('closeFrame', ANNOTATION_UI_URL);
                dispatch(saveWorkflowId({ workflow_id: projectMetaData.workflow_id, status: 'completed' }));
                handleTime();
            },
            okButtonProps: {
                type: 'primary',
                danger: true,
            },
            okText: 'Yes',
            cancelText: 'No',
        });
    };

    // # code added by Raju
    const showSessionModal = (): void => {
        setIsVisible(true);
    };
    const handleStayLoggedIn = (): void => {
        if (logoutTimer) {
            clearTimeout(logoutTimer);
            setlogoutTimer(0);
        }
        setidleTimer(0);
        setIsVisible(false);
        intervalRef.current = setInterval(() => {
            setTimer((timer) => timer + 1);
        }, 1000);
        const ANNOTATION_UI_URL = process.env.REACT_APP_ANNOTATION_UI_URL;
        const iframe = document.getElementById('iframeId');
        const iWindow = iframe?.contentWindow;
        iWindow.postMessage('saveAnnoButton', ANNOTATION_UI_URL);
    };
    // const onIdle = (): void => {
        // showSessionModal();
        // timeout = setTimeout(() => {
            // handleTime();
            // dispatch(activateIframe(false));
            // setIsVisible(false);
        // }, 1000 * 30 * 1); // 30 seconds
        // setlogoutTimer(timeout);
    // };
    // code added by giti
    // const onAction = ():void=>  {
    //     setidleTimer(0);
    // }
    // const onActive = ():void=>  {
    //     setidleTimer(0);
    // }
    // #LTTS code ended above by Raju
    const menu = (
        <Menu className='ltts-header-menu' mode='vertical'>
            {/* {user.isSuperuser && (
                <Menu.Item
                    icon={<ControlOutlined className='ltts-dropdown-icon' />}
                    key='admin_page'
                    onClick={(): void => {
                        // false positive
                        // eslint-disable-next-line
                        window.open(`${tool.server.host}/admin`, '_blank');
                    }}
                >
                    Admin page
                </Menu.Item>
            )} */}
            {user.isSuperuser && (
                <Menu.Item
                    icon={<SettingOutlined />}
                    key='settings'
                    title={`Press ${switchSettingsShortcut} to switch`}
                    onClick={() => switchSettingsDialog(true)}
                >
                    Settings
                </Menu.Item>
            )}
            <Menu.Item key='logout' onClick={onLogoutPopConfirm} disabled={logoutFetching}>
                {logoutFetching ? <LoadingOutlined /> : <LogoutOutlined />}
                Logout
            </Menu.Item>
            <Menu.Item key='help'>
                <QuestionCircleOutlined /> Help
            </Menu.Item>
        </Menu>
    );

    useEffect(() => {
        const intervalId = intervalRef.current;
        return () => {
          clearInterval(intervalId);
        };
    }, [isIframeOpen]);

    useEffect(() => {
        if (isIframeOpen === true) {
            setIsActive(true);
            setIsPaused(true);
            intervalRef.current = setInterval(() => {
                setTimer((timer) => timer + 1);
            }, 1000);
        }
    }, [isIframeOpen]);

    const handleTime = () => {
        clearInterval(intervalRef?.current);
        setIsActive(false);
        setIsPaused(false);
        let date = new Date(0);
        date.setSeconds(timer);
        let hhmmssFormat = date.toISOString().substr(11, 8);
        const currentTime = moment().format('YYYY-MM-DD h:mm:ss');
        const timerValues = {
            project_id: projectMetaData?.project_id,
            workflow_id: projectMetaData?.workflow_id,
            user_id: user.id,
            time: hhmmssFormat,
            updated_at: currentTime,
        };
        dispatch(fetchAnnotatorTime(timerValues));
        setTimer(0);
    };

    useEffect(() => {
        function receiveMessage(e){
            if(e.source === document.getElementById('iframeId')?.contentWindow){
                const data = e.data;
             if(data === 'message'){
                clearInterval(intervalRef?.current);
                intervalRef.current = setInterval(() => {
                    setTimer((timer) => timer + 1);
                }, 1000);
        setlogoutTimer(timeout);
                 }
            }
                }
//new code below
function receiveStoptimer(e){
    if(e.source === document.getElementById('iframeId')?.contentWindow){
        const data = e.data;
     if(data === 'stoptimer'){
        clearInterval(intervalRef?.current);
    }
        }
    }
    function receivelogOuttimer(e){
        if(e.source === document.getElementById('iframeId')?.contentWindow){
            const data = e.data;
         if(data === 'logout'){
            clearInterval(intervalRef?.current);
                let date = new Date(0);
                date.setSeconds(timer);
                let hhmmssFormat = date.toISOString().substr(11, 8);
                        dispatch(activateIframe(false));
                        const timerValues = {
                            project_id: projectMetaData?.project_id,
                            workflow_id: projectMetaData?.workflow_id,
                        user_id: user.id,
                        time: hhmmssFormat,
                        updated_at:moment().format('YYYY-MM-DD h:mm:ss'),
                    };
                    dispatch(fetchAnnotatorTime(timerValues));
                    setTimer(0)
        }
            }
        }
        window.addEventListener("message", receiveMessage);
        window.addEventListener("message", receiveStoptimer);
        window.addEventListener("message", receivelogOuttimer);

        return () => {
          window.removeEventListener("message", receiveMessage);
          window.removeEventListener("message", receiveStoptimer);
          window.removeEventListener("message", receivelogOuttimer);

        };
      }, [projectMetaData,timer]);
    return (
        <div className='headers-main'>
            <Layout.Header className={'ltts-header ' + (isIframeOpen ? 'ltts-frame-open' : ' ')}>
                <Modal
                    title='Session Timeout!'
                    visible={IsVisible}
                    onOk={() => {
                        dispatch(activateIframe(false)), handleTime(), setIsVisible(false);
                    }}
                    onCancel={handleStayLoggedIn}
                    okText='Logout from Annotation'
                    cancelText='Stay'
                >
                    <p>
                        Your session is about to expire in 30 seconds due to inactivity. You will be redirected to the
                        App page.
                    </p>
                </Modal>

                {/* {isIframeOpen && (
                    <IdleTimer
                        startOnMount={ true }
                        crossTab={active}
                        element={document}
                        stopOnIdle={true}
                        ref={idleTimerRef}
                        timeout={60000 * sessionTimeoutPeriod}
                        onIdle={onIdle}
                        onActive={onActive}
                        onAction={onAction}
                    />
                )} */}

                {/* if required for future */}
                {/* <Button className='ltts-header-button' type='link' value='CloudFilled'>
                    <span>
                        <Icon className='ltts-header-account-icon1' component={CloudFilled} />
                    </span>
                </Button> */}
                {/* <Row > */}
                {/* <Col  span={16}> */}
                <Icon component={AnnotIcon} className='ltts-logo-text' />
                <div className='ltts-header-icon-dropdown' style={{ marginRight: '-500px' }}>
                    {isIframeOpencustomer ? (
                        <>
                            <Button
                                id='close-iframe-button'
                                className='header-annot-btns annot-save-button'
                                onClick={(): void => closeIFrameCustomer()}
                            >
                                <Icon component={SaveAndCloseIcon} />
                                Save & Close
                            </Button>
                            <Button
                                className='header-annot-btns reject-btn'
                                disabled={enableCustomerReject}
                                onClick={() => rejectCustomerModal()}
                            >
                                <Icon component={RejectIcon} /> Reject
                            </Button>
                        </>
                    ) : (
                        ''
                    )}
                </div>

                <div className='ltts-header-icon-dropdown'>
                    {isIframeOpen ? (
                        <>
                            <Button id='close-iframe-button' className='header-annot-btns annot-save-button'>
                                <Icon component={SaveAndCloseIcon} />
                                <p>{formatTime(timer)}</p>
                            </Button>

                            <Button
                                id='close-iframe-button'
                                className='header-annot-btns annot-save-button'
                                onClick={(): void => {
                                    closeIFrame();
                                }}
                            >
                                <Icon component={SaveAndCloseIcon} />
                                Save & Close
                            </Button>
                            <Button
                                id='submit-annotation'
                                className='ltts-submit-close-button header-annot-btns'
                                type='primary'
                                onClick={(): void => submitCloseAnnotation()}
                            >
                                <Icon component={SubmitAndCloseIcon} />
                                Submit & Close
                            </Button>
                            <Button
                                id='submit-annotation'
                                className='ltts-submit-next-button header-annot-btns'
                                type='primary'
                                onClick={(): void => submitAnnotation()}
                            >
                                <Icon component={SubmitLoadNextIcon} />
                                Submit & Load Next
                            </Button>
                            <Button
                                className='header-annot-btns reject-btn'
                                disabled={enableReject}
                                onClick={() => rejectModal()}
                            >
                                <Icon component={RejectIcon} /> Reject
                            </Button>
                            <Button
                                className='header-annot-btns project-info-button'
                                onClick={() => projectInfoModal()}
                            >
                                <Icon component={ProjectInfoIcon} />
                                <text style={{ color: 'black' }}>Project Info</text>
                            </Button>
                        </>
                    ) : (
                        ''
                    )}
                    <Button type='link' value='BellOutlined'>
                        <span>
                            <Icon className='ltts-header-account-icon' component={BellOutlined} />
                        </span>
                    </Button>
                    <span>
                        <Icon component={ProfileIcon} />
                    </span>
                    <Dropdown overlay={menu} placement='bottomRight' className='ltts-header-menu-dropdown'>
                        <span>
                            {/* <Text strong>
                            {user.username.length > 14 ? `${user.username.slice(0, 10)} ...` : user.username}
                            </Text>  */}
                            <DownOutlined className='ltts-header-menu-icon' />
                        </span>
                    </Dropdown>
                </div>
                <ProjectStagesRejectionComponent
                    visible={showRejectModal}
                    onSubmit={() => {
                        dispatch(switchRejectDialog(false)), dispatch(activateIframe(false));
                    }}
                />
                {user.isSuperuser && (
                    <SettingsModal visible={settingsDialogShown} onClose={() => switchSettingsDialog(false)} />
                )}
                {renderChangePasswordItem && <ChangePasswordDialog onClose={() => switchChangePasswordDialog(false)} />}
            </Layout.Header>
        </div>
    );
}

function propsAreTheSame(prevProps: Props, nextProps: Props): boolean {
    let equal = true;
    for (const prop in nextProps) {
        if (prop in prevProps && (prevProps as any)[prop] !== (nextProps as any)[prop]) {
            if (prop !== 'tool') {
                equal = false;
            }
        }
    }

    return equal;
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(HeaderContainer, propsAreTheSame));
