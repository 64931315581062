// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT
import { UserConfirmation } from '../components/register-page/register-form';

// ... addToDo ...
export enum AuthSagaActionTypes {
    REGISTER = 'REGISTER',
    REGISTER_SUCCESS = 'REGISTER_SUCCESS',
    REGISTER_FAILED = 'REGISTER_FAILED',

}



export function registerAsyncData(
    username: string,
    firstName: string,
    lastName: string,
    email: string,
    password1: string,
    password2: string,
    confirmations: UserConfirmation[],
) {
    return {
        type: AuthSagaActionTypes.REGISTER,
        payload: {
            username,
            firstName,
            lastName,
            email,
            password1,
            password2,
            confirmations,
        },
    };
}