// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd/lib/grid';
import { Button, Collapse, FormInstance, notification } from 'antd';
import Text from 'antd/lib/typography/Text';
import LabelsEditor from 'components/labels-editor/labels-editor';
import { Label } from 'components/labels-editor/common';
import '../create-project/project-details-styles.scss';
import '../edit-project/edit-project-styles.scss';
import AdvancedConfigurationForm from 'components/create-task-page/advanced-configuration-form';
import { editProjectDetails } from 'actions/projects-mgnt-actions';
import { NameConfigurationForm } from '../create-project/create-project-mgnt';
import { CombinedState } from 'reducers/interfaces';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';

const { Panel } = Collapse;

export interface AdvancedConfiguration {
    segmentSize?: number;
    imageQuality?: number;
    overlapSize?: number;
    dataChunkSize?: number;
    sortingMethod?: string;
    startFrame?: number;
    stopFrame?: number;
    useCache?: boolean;
    useZipChunks?: boolean;
    userFlagEnabled?: boolean;
    rejectionFlagEnabled?: boolean;
    frameFilter?: string;
}
export interface CreateTaskData {
    advanced: AdvancedConfiguration;
    labels: any[];
    name: '';
    description: '';
    start_date: any;
    project_type: any;
}
export enum AttributeType {
    SELECT = 'SELECT',
    RADIO = 'RADIO',
    CHECKBOX = 'CHECKBOX',
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
}

const defaultState: State = {
    advanced: {
        segmentSize: 1,
    },
    labels: [],
    name: '',
    description: '',
    start_date: '',
    project_type: '',
};
type State = CreateTaskData;

interface Props {
    labelNames?: string[];
    onSubmit: (label: Label | null) => void;
    installedGit: boolean;
    dumpers: [];
    projectId?: number | null;
    formRef: any;
    formvalues: any;
}

export default function editProject(props: Props): JSX.Element {
    const nameFormRef = useRef<FormInstance>(null);
    const newProjectState = useSelector((state: CombinedState) => state.projects);
    const newProjectId = useSelector((state: CombinedState) => state.projects.activities.creates.id);
    const advancedConfigurationComponent: RefObject<AdvancedConfigurationForm> =
        React.createRef<AdvancedConfigurationForm>();
    const dispatch = useDispatch();

    const { installedGit, dumpers } = props;
    const location = useLocation();
    const projectValues = JSON.parse(location.state);
    const { project_config } = projectValues;
    const { basic_configuration } = project_config;
    const labels = basic_configuration?.labels;
    defaultState.labels = labels;
    const [localState, setLocalState] = useState(defaultState);
    const { id, name, description, start_date, project_type } = projectValues;
    const startDateFinal = moment(start_date);

    const advanced_configuration = project_config?.advanced_configuration;
    const history = useHistory();
    let status = useSelector((state: CombinedState) => state.projects.editFetching);
    const firstUpdate = useRef(true);

    useEffect(() => {
        // const btn = <Button onClick={() => history.push(`/projects`)}>Open project</Button>;
        // if (firstUpdate.current) {
        //     firstUpdate.current = false;
        //     return;
        // }
        // if(status===false){
        //     notification.info({
        //         message: 'The project has been updated',
        //         btn,
        //         className: 'ltts-notification-create-project-success',
        //     });
        // }
    }, [status]);

    let formvalues = {
        name,
        description,
        start_date,
        project_type,
        labels: basic_configuration?.labels,
        project_config: project_config,
        advanced_configuration: advanced_configuration,
        datachunk_size: advanced_configuration?.datachunk_size,
        frame_filter: advanced_configuration?.frame_filter,
        image_quality: advanced_configuration?.image_quality,
        overlap_size: advanced_configuration?.overlap_size,
        segment_size: advanced_configuration?.segment_size,
        sorting_method: advanced_configuration?.sorting_method,
        start_frame: advanced_configuration?.start_frame,
        stop_frame: advanced_configuration?.stop_frame,
        use_cache: advanced_configuration?.use_cache,
        use_zipchunks: advanced_configuration?.use_zipchunks,
        user_flag_enabled: advanced_configuration?.user_flag_enabled,
        rejection_flag_enabled: advanced_configuration?.rejection_flag_enabled,
    };
    let advancedFormValues = {
        dataChunkSize: advanced_configuration?.datachunk_size,
        frameFilter: advanced_configuration?.frame_filter,
        imageQuality: advanced_configuration?.image_quality,
        overlapSize: advanced_configuration?.overlap_size,
        segmentSize: advanced_configuration?.segment_size,
        sortingMethod: advanced_configuration?.sorting_method,
        startFrame: advanced_configuration?.start_frame,
        stopFrame: advanced_configuration?.stop_frame,
        useCache: advanced_configuration?.use_cache,
        useZipChunks: advanced_configuration?.use_zipchunks,
        userFlagEnabled: advanced_configuration?.user_flag_enabled,
        rejectionFlagEnabled: advanced_configuration?.rejection_flag_enabled,
    };

    const handleSubmitAdvancedConfiguration = async (values: AdvancedConfiguration): Promise<void> => {
        const { labels } = localState;

        let basicValues;
        if (nameFormRef.current) {
            basicValues = await nameFormRef.current.validateFields();
        }
        let projectData: Record<string, any> = {};
        let { name, Description, StartDate, Project_type } = basicValues;

        projectData = {
            id: id,
            name: name,
            description: Description,
            start_date: StartDate,
            project_type: Project_type,
            project_config: {
                basic_configuration: {
                    labels,
                },
                advanced_configuration: {
                    segment_size: values.segmentSize,
                    image_quality: values.imageQuality,
                    overlap_size: values.overlapSize,
                    sorting_method: values.sortingMethod,
                    datachunk_size: values.dataChunkSize,
                    start_frame: values.startFrame,
                    stop_frame: values.stopFrame,
                    use_cache: values.useCache,
                    use_zipchunks: values.useZipChunks,
                    user_flag_enabled: values.userFlagEnabled,
                    rejection_flag_enabled: values.rejectionFlagEnabled,
                    frame_filter: values.frameFilter,
                },
            },
        };
        dispatch(editProjectDetails(projectData));
    };

    const validateLabels = (): boolean => {
        const { labels } = localState;
        return !!labels?.length;
    };

    const handleSubmitClick = async (): Promise<void> => {
        if (!validateLabels()) {
            notification.error({
                message: 'Could not add Project details',
                description: 'A project must contain at least one label',
                className: 'ltts-notification-create-task-fail',
            });
            return;
        }

        if (advancedConfigurationComponent.current) {
            advancedConfigurationComponent.current?.submit().then(() => {});
        } else {
            const { labels } = localState;
            let basicValues;
            if (nameFormRef.current) {
                basicValues = await nameFormRef.current.validateFields();
            }
            let projectData: Record<string, any> = {};
            let { name, Description, StartDate, Project_type } = basicValues;
            projectData = {
                id: id,
                name: name,
                description: Description,
                start_date: StartDate,
                project_type: Project_type,
                project_config: {
                    basic_configuration: {
                        labels,
                    },
                    advanced_configuration: project_config.advanced_configuration || [],
                },
            };
            dispatch(editProjectDetails(projectData));
        }
    };

    const renderLabelsBlock = (): JSX.Element => {
        const { labels } = localState;
        return (
            <Col span={24}>
                {labels && (
                    <LabelsEditor
                        labels={labels}
                        onSubmit={(newLabels): void => {
                            //newLabels[0].id = 1;
                            setLocalState((prevState) => ({
                                ...prevState,
                                labels: [...newLabels],
                            }));
                        }}
                    />
                )}
            </Col>
        );
    };

    const renderAdvancedBlock = (): JSX.Element => (
        <Col span={24}>
            <Collapse>
                <Panel key='1' header={<Text className='ltts-title'>Advanced configuration</Text>}>
                    <AdvancedConfigurationForm
                        dumpers={dumpers}
                        installedGit={installedGit}
                        activeFileManagerTab='local'
                        ref={advancedConfigurationComponent}
                        onSubmit={handleSubmitAdvancedConfiguration}
                        editInitialValues={advancedFormValues}
                    />
                </Panel>
            </Collapse>
        </Col>
    );

    return (
        <div className='edit-project-page'>
            <div className='edit-project-title'>Edit Project</div>
            <div className=' Project-config-fixed ant-typography project-title'></div>
            <Row className='edit-project-details'>
                <Col className='edit-project-detail-inner'>
                    <Row>
                        <Col span={24}>
                            <NameConfigurationForm formRef={nameFormRef} formvalues={formvalues} disabled={true} />
                        </Col>
                    </Row>
                    <div className='label-section-label'>
                        <p>Labels :</p>
                    </div>
                    <Row className='inner-section edit-labels-block'>
                        <span className='LabelText'>
                            <b></b>
                        </span>
                        <Col span={23}>
                        {renderLabelsBlock()}
                        </Col>
                    </Row>
                    {advancedFormValues && <Row className='inner-section'>{renderAdvancedBlock()}</Row>}
                </Col>
            </Row>
            <div className='next-button-row'>
                <Button type='primary' className='project-config-next' onClick={handleSubmitClick}>
                    <b>Update</b>
                </Button>
            </div>
        </div>
    );
}
