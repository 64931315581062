// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT
import React from 'react';
import { Input, Button, Row, Col } from 'antd';
import Form from 'antd/lib/form';
import Text from 'antd/lib/typography/Text';
import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import { UserOutlined, MailOutlined } from '@ant-design/icons';
// import { validatePassword } from 'components/register-page/register-form';

export interface LoginData {
    username: string;
    password: string;
}
interface Props {
    fetching: boolean;
    renderResetPassword: boolean;
    onSubmit(loginData: LoginData): void;
}

function LoginFormComponent(props: Props & RouteComponentProps): JSX.Element {
    const { fetching, renderResetPassword, onSubmit } = props;
    return (
        <Form onFinish={onSubmit} autoComplete='off' className='login-form'>
            <p className='form-title'>Login</p>
            <Form.Item
                name='username'
                rules={[
                    {
                        required: true,
                        message: 'Please enter username',
                    },
                ]}
            >
                <Input
                    className='login-form-field'
                    suffix={<UserOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                    placeholder='Username/Email'
                    autoComplete='off'
                />
            </Form.Item>

            <Form.Item
                name='password'
                rules={[
                    {
                        required: true,
                        message: 'Please enter password',
                    },
                    // , validatePassword,
                ]}
            >
                <Input.Password
                    className='login-form-field'
                    autoComplete='password'
                    suffix={<MailOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                    placeholder='Password'
                />
            </Form.Item>
            {/* {renderResetPassword && (
                <Row justify='center' className='row-forgot-password'>
                    <Col>
                        <Text strong>
                            <Link to='/auth/password/reset'>Forgot password?</Link>
                        </Text>
                    </Col>
                </Row>
            )} */}
            <Form.Item className='login-button-row'>
                <Button
                    type='primary'
                    loading={fetching}
                    disabled={fetching}
                    htmlType='submit'
                    className='login-form-button'
                >
                    Login
                </Button>
            </Form.Item>
            <Row>
                <Col>
                    <Text strong>
                        Don't you have an account yet?
                        <Link to='/auth/register'> Sign up</Link>
                    </Text>
                </Col>
            </Row>
        </Form>
    );
}

export default withRouter(LoginFormComponent);
