// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MI
import { all, put, takeLatest } from 'redux-saga/effects';
import { OrganizationSagaActionsTypes } from '../actions/organization-mgnt-actions';
import getCore from '../ltts-core-wrapper';
import { ActionUnion, createAction } from '../utils/redux';

const ltts = getCore();

export const organizationSagaActions = {
    createOrganization: () => createAction(OrganizationSagaActionsTypes.CREATE_ORGANIZATION),
    createOrganizationSuccess: (organization: any) => createAction(
        OrganizationSagaActionsTypes.CREATE_ORGANIZATION_SUCCESS, { organization },
    ),
    createOrganizationFailed: (slug: string, error: any) => createAction(
        OrganizationSagaActionsTypes.CREATE_ORGANIZATION_FAILED, { slug, error },
    ),

};

export type OrganizationSagaActions = ActionUnion<typeof organizationSagaActions>;

function* createOrganizationAsync(data: any): any {
    const { slug } = data.payload.organizationData;
    const organization = new ltts.classes.Organization(data.payload.organizationData);
    yield (organizationSagaActions.createOrganization());

    try {
        const createdOrganization = yield organization.save();
        yield put(organizationSagaActions.createOrganizationSuccess(createdOrganization));
        if (data.payload.onCreateSuccess) data.payload.onCreateSuccess(createdOrganization.slug);
    } catch (error) {
        yield put(organizationSagaActions.createOrganizationFailed(slug, error));
    }
};


export function* organizationWatcher() {
    yield all([
        takeLatest(OrganizationSagaActionsTypes.CREATE_ORGANIZATION, createOrganizationAsync),
    ])
}
