// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import { all, fork } from 'redux-saga/effects';
import { authWatcher } from './auth-saga';
import { projectWatcher } from './projects-mgnt';
import { organizationWatcher } from './organization-mgnt';
import { appsNStagesWatcher } from './apps-stages-saga';
import { reportsWatcher } from './reports-saga';

export default function* rootSaga() {
    yield all([
        fork(authWatcher),
        fork(appsNStagesWatcher),
        fork(projectWatcher),
        fork(organizationWatcher),
        fork(reportsWatcher),
    ]);
}
