// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT

import { BoundariesActionTypes } from '../actions/boundaries-actions';
import { ReportSagaActionTypes } from '../actions/reports-action';
import { Reports } from './interfaces';

const defaultState: Reports = {
    projectDataStatus: [],
    projectsName: [],
    projectAppStages: [],
    fetching: false,
    releaseStageMessage: '',
    currentStageUsers: [],
    differentStageUsers: [],
    userReports: [],
    dayReports:[],
    appStageDetails: [],
    viewTaskDetails: {},
    parentWorkflowHistory: [],
    stageAnnotationInfo: [],
    projectReportsDownload: [],
    releaseButtonDisabled: 0,
    stageDataStatus: [],
    isIframeOpen: false,
    customerIframe: false,
    customerStages: [],
};

export default function (state = defaultState, action: any): Reports {
    switch (action.type) {
        case ReportSagaActionTypes.GET_PROJECTS_REPORTS:
            return {
                ...state,
            };
        case ReportSagaActionTypes.GET_PROJECTS_REPORTS_SUCCESS:
            return {
                ...state,
                projectDataStatus: action.response,
            };
        case ReportSagaActionTypes.GET_PROJECTS_REPORTS_FAILED: {
            return {
                ...state,
                projectDataStatus: [],
            };
        }
        case ReportSagaActionTypes.GET_PROJECTS_NAME_SUCCESS:
            return {
                ...state,
                projectsName: action.response,
            };
        case ReportSagaActionTypes.GET_USER_WISE_REPORT_SUCCESS:
            return {
                ...state,
                userReports: action.response,
            };
        case ReportSagaActionTypes.GET_DAY_WISE_REPORT_SUCCESS:
            return {
                ...state,
                dayReports: action.response,
            };
        case ReportSagaActionTypes.GET_APP_STAGE_REPORT_SUCCESS:
            return {
                ...state,
                appStageDetails: action.response,
            };
        case ReportSagaActionTypes.GET_VIEW_TASK_SUCCESS:
            return {
                ...state,
                viewTaskDetails: action.response,
                isIframeOpen: true,
                customerIframe:true
            };
        case ReportSagaActionTypes.CHANGE_IFRAME_VALUE_CUSTOMER:
            return {
                ...state,
                viewTaskDetails: null,
                isIframeOpen: action.payload,
                customerIframe:false
            };
        case ReportSagaActionTypes.GET_PROJECT_APP_STAGES:
            return {
                ...state,
                fetching: true,
            };
        case ReportSagaActionTypes.GET_PROJECT_APP_STAGES_SUCCESS:
            return {
                ...state,
                fetching: false,
                projectAppStages: action.response,
            };
        case ReportSagaActionTypes.GET_PROJECT_STAGE_DATA_STATUS_SUCCESS:
            return {
                ...state,
                fetching: false,
                stageDataStatus: action.response,
            }
        case ReportSagaActionTypes.GET_PROJECT_APP_STAGES_CUSTOMER_SUCCESS:
            return {
                ...state,
                fetching: false,
                customerStages: action.response,
            };
        case ReportSagaActionTypes.GET_PROJECT_REPORTS_DOWNLOAD_SUCCESS:
            return {
                ...state,
                fetching: false,
                projectReportsDownload: action.response,
            };
        case ReportSagaActionTypes.GET_STAGE_REPORT:
            return {
                ...state,
                fetching: true,
            };
        case ReportSagaActionTypes.GET_STAGE_REPORT_SUCCESS:
            return {
                ...state,
                fetching: false,
                stageAnnotationInfo: action.response,
            };
        case ReportSagaActionTypes.RELEASE_PROJECT_APP_STAGES_SUCCESS:
            return {
                ...state,
                releaseStageMessage: action.response.data.message,
                releaseButtonDisabled: action.workflowId,
            };

        case ReportSagaActionTypes.RESET_ANNOTATION_INFO:
            return {
                ...state,
                releaseStageMessage: '',
                customerStages:[],
            };
        case ReportSagaActionTypes.RELEASE_CURRENT_USER_STAGES_SUCCESS:
            return {
                ...state,
                currentStageUsers: action.response,
            };
        case ReportSagaActionTypes.RELEASE_DIFFERENT_USER_STAGES_SUCCESS:
            return {
                ...state,
                differentStageUsers: action.response,
            };
        case ReportSagaActionTypes.GET_WORKFLOW_HISTORY_SUCCESS:
            return {
                ...state,
                parentWorkflowHistory: action.response,
            };
        case ReportSagaActionTypes.RESET_REPORTS_STATE:
            return {
                ...state,
                projectAppStages: []
            }
        case BoundariesActionTypes.RESET_AFTER_ERROR: {
            return { ...defaultState };
        }
        default:
            return state;
    }
}