import { Modal, Transfer } from 'antd';
import image from 'antd/lib/image';
import './project-admin.scss';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdmins, fetchUsers, saveAdminUsers } from 'actions/apps-stages-actions';
import { CombinedState } from 'reducers/interfaces';

export default function projectAdmin(): JSX.Element {
  const history = useHistory();
  const [mockData, setMockData] = useState([]);
  const [targetKeys, setTargetKey] = useState([]);
  const dispatch = useDispatch();

  const params = useParams();
  const id= params.id;
  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchAdmins(id));
  },[]);

  const userList = useSelector((state: CombinedState) => state.appsStages.users).map(user => ({title: user.username, key: user.id}));
  const adminListcheck = useSelector((state: CombinedState) => state.appsStages.admins);
  const adminList = useSelector((state: CombinedState) => state.appsStages.admins).map(admin => ( admin.user_id));

  useEffect(() => {
    getMock(userList,adminList);
  },[userList.length]);

  const getMock = (data,adminList) => {

    const targetKeys = [];
    const mockData = [];
      if(data && adminList) {
        setMockData(data);
        setTargetKey(adminList)
      }
  };
  const filterOption = (inputValue: any, option: any[]) => option.title.indexOf(inputValue) > -1;

  const handleChange = (targetKeys: any) => {
    setTargetKey(targetKeys)
  };
  const handleSearch = (dir: any, value: any) => {
  };
  const handleCancel = () :void => {
    history.push({
      pathname: '/projects',
    });
  };
  const handleOk = () => {
    let adminPayload=[]
    let redirect;
    for (let i = 0; i < targetKeys.length; i++) {
        const adminData=
          {
            "project_id": id,
            "user_id": targetKeys[i],
            "is_admin": true
          }
        adminPayload.push(adminData)
        redirect ={history:history }
    }
    dispatch(saveAdminUsers(adminPayload,redirect));
  };

  return (
    <Modal
      onOk={handleOk}
      onCancel={handleCancel}
      cancelButtonProps={{
        style: {
          display: "none",
        },
      }}
      className=' author-project-page'
      title='Project Admin'
      visible={true}
      centered
      width={800}
      maskStyle={{backgroundImage: `url("${image}")`}}
    >
      <Transfer
        dataSource={mockData}
        showSearch
        titles={['Available Users', 'Assigned Users']}
        filterOption={filterOption}
        targetKeys={targetKeys}
        onChange={handleChange}
        onSearch={handleSearch}
        render={item => item.title}
      />
    </Modal>
  );
}


