// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { RefObject } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Row, Input, Col } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import Spin from 'antd/lib/spin';
import validationPatterns from 'utils/validation-patterns';
import { CombinedState } from 'reducers/interfaces';

const { Title } = Typography;

function CreateOrganizationFormPage({ formRef }: { formRef: RefObject<FormInstance> }): JSX.Element {
    const MAX_SLUG_LEN = 16;
    const MAX_NAME_LEN = 64;

    const organizationCreating = useSelector((state: CombinedState) => state.organizations.creating);

    if (organizationCreating) {
        return <Spin size='large' className='ltts-spinner' />;
    }

    return (
        <>
            <Row>
                <Col span={24} className='org-form-col'>
                    <Title level={5} className='organization-title'>
                        {' '}
                        Create Organization
                    </Title>
                    <Form
                        ref={formRef}
                        autoComplete='off'
                        className='create-organization-form'
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            span: 10,
                        }}
                    >
                        <Form.Item
                            hasFeedback
                            name='slug'
                            rules={[
                                { required: true, message: 'please input your short name' },
                                { max: MAX_SLUG_LEN, message: `Short name must not exceed ${MAX_SLUG_LEN} characters` },
                                { ...validationPatterns.validateOrganizationSlug },
                            ]}
                            label='Short Name'
                            labelAlign='left'
                            className='create-organization-form-item'
                        >
                            <Input placeholder='Short Name' className='org-input-field' />
                        </Form.Item>
                        <Form.Item
                            hasFeedback
                            name='name'
                            className='create-organization-form-item'
                            rules={[
                                { required: true, message: 'please input your organization name' },
                                { max: MAX_NAME_LEN, message: `Full name must not exceed ${MAX_NAME_LEN} characters` },
                            ]}
                            label='Full Name'
                            labelAlign='left'
                        >
                            <Input placeholder='Full Name' className='org-input-field' />
                        </Form.Item>
                        <Form.Item
                            hasFeedback
                            name='description'
                            className='create-organization-form-item'
                            rules={[{ required: true, message: 'please input your description' }]}
                            label='Description'
                            labelAlign='left'
                        >
                            <Input.TextArea rows={2} placeholder='Description' />
                        </Form.Item>
                        <Form.Item
                            hasFeedback
                            name='email'
                            className='create-organization-form-item'
                            rules={[{ type: 'email', required: true, message: 'please input your email' }]}
                            label='Email'
                            labelAlign='left'
                        >
                            <Input autoComplete='email' placeholder='Email' className='org-input-field' />
                        </Form.Item>
                        <Form.Item
                            hasFeedback
                            name='phoneNumber'
                            className='create-organization-form-item'
                            rules={[
                                { required: true, message: 'please input your phone number' },
                                { ...validationPatterns.validatePhoneNumber },
                            ]}
                            label='Phone Number'
                            labelAlign='left'
                        >
                            <Input autoComplete='phoneNumber' placeholder='Phone Number' className='org-input-field' />
                        </Form.Item>
                        <Form.Item
                            hasFeedback
                            name='location'
                            className='create-organization-form-item'
                            rules={[{ required: true, message: 'please input your location' }]}
                            label='Location'
                            labelAlign='left'
                        >
                            <Input autoComplete='location' placeholder='Location' className='org-input-field' />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </>
    );
}

export default React.memo(CreateOrganizationFormPage);
