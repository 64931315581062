// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState } from 'react';
import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import { MailOutlined } from '@ant-design/icons';
import Input from 'antd/lib/input';
import { Modal } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import './styles.scss';

export interface ResetPasswordData {
    email: string;
}

interface Props {
    fetching: boolean;
    onSubmit(resetPasswordData: ResetPasswordData): void;
}

function ResetPasswordFormComponent({ fetching, onSubmit }: Props): JSX.Element {
    const [modal, setIsModalVisible] = useState(true);
    const history = useHistory();
    const handleCancel = ():void => {
        setIsModalVisible(false);
        history.push('/auth/login');
    };

    return (
        <Modal
            className='ltts-reset'
            onCancel={handleCancel}
            title='Reset Password'
            footer={null}
            visible={modal}
            centered
           // width={600}
            maskStyle={{backgroundColor:'white' }}
        >

            <Form onFinish={onSubmit} className='ltts-reset-password-form'>
                <Form.Item
                    name='email'
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                        },
                        {
                            required: true,
                            message: 'Please specify an email address',
                        },
                    ]}
                >
                    <Input
                        className='email-field'
                        autoComplete='email'
                        suffix={<MailOutlined className='suffix-icon-mail' />}
                        placeholder='xxxxxx@gmail.com'
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        shape='round'
                        type='primary'
                        loading={fetching}
                        disabled={fetching}
                        htmlType='submit'
                        className='ltts-reset-password-form-button'
                    >
                        <span className='reset-text'>Reset password</span>
                    </Button>
                </Form.Item>
            </Form>
            <div className='login-redirect-text'>
                Go to
                <Link to='/auth/login' className='login-text'> Login page </Link>
            </div>
        </Modal>
    );
}

export default React.memo(ResetPasswordFormComponent);
