// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT

const config = require('./config');

(() => {
    const PluginRegistry = require('./plugins');
    const serverProxy = require('./server-proxy');
    const lambdaManager = require('./lambda-manager');
    const {
        isBoolean,
        isInteger,
        isString,
        checkFilter,
        checkExclusiveFields,
        camelToSnake,
        checkObjectType,
    } = require('./common');

    const User = require('./user');
    const { AnnotationFormats } = require('./annotation-formats');
    const { ArgumentError } = require('./exceptions');
    const { Task, Job } = require('./session');
    const { Project } = require('./project');
    const { CloudStorage } = require('./cloud-storage');
    const Organization = require('./organization');

    function implementAPI(ltts) {
        ltts.plugins.list.implementation = PluginRegistry.list;
        ltts.plugins.register.implementation = PluginRegistry.register.bind(ltts);

        ltts.lambda.list.implementation = lambdaManager.list.bind(lambdaManager);
        ltts.lambda.run.implementation = lambdaManager.run.bind(lambdaManager);
        ltts.lambda.call.implementation = lambdaManager.call.bind(lambdaManager);
        ltts.lambda.cancel.implementation = lambdaManager.cancel.bind(lambdaManager);
        ltts.lambda.listen.implementation = lambdaManager.listen.bind(lambdaManager);
        ltts.lambda.requests.implementation = lambdaManager.requests.bind(lambdaManager);

        ltts.server.about.implementation = async () => {
            const result = await serverProxy.server.about();
            return result;
        };

        ltts.server.share.implementation = async (directory) => {
            const result = await serverProxy.server.share(directory);
            return result;
        };

        ltts.server.formats.implementation = async () => {
            const result = await serverProxy.server.formats();
            return new AnnotationFormats(result);
        };

        ltts.server.userAgreements.implementation = async () => {
            const result = await serverProxy.server.userAgreements();
            return result;
        };

        ltts.server.register.implementation = async (
            username,
            firstName,
            lastName,
            email,
            password1,
            password2,
            userConfirmations,
        ) => {
            const user = await serverProxy.server.register(
                username,
                firstName,
                lastName,
                email,
                password1,
                password2,
                userConfirmations,
            );

            return new User(user);
        };

        ltts.server.login.implementation = async (username, password) => {
            await serverProxy.server.login(username, password);
        };

        ltts.server.logout.implementation = async () => {
            await serverProxy.server.logout();
        };

        ltts.server.changePassword.implementation = async (oldPassword, newPassword1, newPassword2) => {
            await serverProxy.server.changePassword(oldPassword, newPassword1, newPassword2);
        };

        ltts.server.requestPasswordReset.implementation = async (email) => {
            await serverProxy.server.requestPasswordReset(email);
        };

        ltts.server.resetPassword.implementation = async (newPassword1, newPassword2, uid, token) => {
            await serverProxy.server.resetPassword(newPassword1, newPassword2, uid, token);
        };

        ltts.server.authorized.implementation = async () => {
            const result = await serverProxy.server.authorized();
            return result;
        };

        ltts.server.request.implementation = async (url, data) => {
            const result = await serverProxy.server.request(url, data);
            return result;
        };

        ltts.server.installedApps.implementation = async () => {
            const result = await serverProxy.server.installedApps();
            return result;
        };

        ltts.users.get.implementation = async (filter) => {
            checkFilter(filter, {
                id: isInteger,
                is_active: isBoolean,
                self: isBoolean,
                search: isString,
                limit: isInteger,
            });

            let users = null;
            if ('self' in filter && filter.self) {
                users = await serverProxy.users.self();
                users = [users];
            } else {
                const searchParams = {};
                for (const key in filter) {
                    if (filter[key] && key !== 'self') {
                        searchParams[key] = filter[key];
                    }
                }
                users = await serverProxy.users.get(searchParams);
            }

            users = users.map((user) => new User(user));
            return users;
        };

        ltts.jobs.get.implementation = async (filter) => {
            checkFilter(filter, {
                page: isInteger,
                filter: isString,
                sort: isString,
                search: isString,
                taskID: isInteger,
                jobID: isInteger,
            });

            if ('taskID' in filter && 'jobID' in filter) {
                throw new ArgumentError(
                    'Filter fields "taskID" and "jobID" are not permitted to be used at the same time',
                );
            }

            if ('taskID' in filter) {
                const [task] = await serverProxy.tasks.get({ id: filter.taskID });
                if (task) {
                    return new Task(task).jobs;
                }

                return [];
            }

            if ('jobID' in filter) {
                const job = await serverProxy.jobs.get({ id: filter.jobID });
                if (job) {
                    return [new Job(job)];
                }
            }

            const jobsData = await serverProxy.jobs.get(filter);
            const jobs = jobsData.results.map((jobData) => new Job(jobData));
            jobs.count = jobsData.count;
            return jobs;
        };

        ltts.tasks.get.implementation = async (filter) => {
            checkFilter(filter, {
                page: isInteger,
                projectId: isInteger,
                id: isInteger,
                search: isString,
                filter: isString,
                ordering: isString,
            });

            checkExclusiveFields(filter, ['id', 'projectId'], ['page']);

            const searchParams = {};
            for (const field of ['filter', 'search', 'ordering', 'id', 'page', 'projectId']) {
                if (Object.prototype.hasOwnProperty.call(filter, field)) {
                    searchParams[camelToSnake(field)] = filter[field];
                }
            }

            const tasksData = await serverProxy.tasks.get(searchParams);
            const tasks = tasksData.map((task) => new Task(task));

            tasks.count = tasksData.count;

            return tasks;
        };

        ltts.projects.getType.implementation = async (filter) => {
            let projects = null;
            projects = await serverProxy.projects.getType();
            return projects;
        };

        ltts.projects.getExportData.implementation = async (project_id) => {
            let projects = null;
            projects = await serverProxy.projects.getExportData(project_id);
            return projects;
        };

        ltts.projects.getProjectData.implementation = async (project_id) => {
            let projects = null;
            projects = await serverProxy.projects.getProjectData(project_id);
            return projects;
        };

        ltts.projects.getExportDataFormat.implementation = async () => {
            let projects = null;
            projects = await serverProxy.projects.getExportDataFormat();
            return projects;
        };

        ltts.projects.getToken.implementation = async (filter) => {
            let projects = null;
            projects = await serverProxy.projects.mappingToken(filter);
            return projects;
        };

        ltts.projects.exportDataDownload.implementation = async (exportData) => {
            let projects = null;
            projects = await serverProxy.projects.exportData(exportData);
            return projects;
        };

        ltts.projects.get.implementation = async (filter) => {
            checkFilter(filter, {
                id: isInteger,
                page: isInteger,
                search: isString,
                filter: isString,
            });

            checkExclusiveFields(filter, ['id'], ['page']);

            const searchParams = {};
            for (const field of ['filter', 'search', 'status', 'id', 'page']) {
                if (Object.prototype.hasOwnProperty.call(filter, field)) {
                    searchParams[camelToSnake(field)] = filter[field];
                }
            }

            const projectsData = await serverProxy.projects.get(searchParams);
            const projects = projectsData
                .map((project) => {
                    project.task_ids = project.tasks;
                    return project;
                })
                .map((project) => new Project(project));

            projects.count = projectsData.count;

            return projects;
        };

        ltts.projects.searchNames.implementation = async (search, limit) =>
            serverProxy.projects.searchNames(search, limit);

        ltts.cloudStorages.get.implementation = async (filter) => {
            checkFilter(filter, {
                page: isInteger,
                filter: isString,
                id: isInteger,
                search: isString,
            });

            checkExclusiveFields(filter, ['id', 'search'], ['page']);

            const searchParams = new URLSearchParams();
            for (const field of ['filter', 'search', 'id', 'page']) {
                if (Object.prototype.hasOwnProperty.call(filter, field)) {
                    searchParams.set(camelToSnake(field), filter[field]);
                }
            }

            const cloudStoragesData = await serverProxy.cloudStorages.get(searchParams.toString());
            const cloudStorages = cloudStoragesData.map((cloudStorage) => new CloudStorage(cloudStorage));
            cloudStorages.count = cloudStoragesData.count;
            return cloudStorages;
        };

        ltts.organizations.get.implementation = async () => {
            const organizationsData = await serverProxy.organizations.get();
            const organizations = organizationsData.map((organizationData) => new Organization(organizationData));
            return organizations;
        };

        ltts.organizations.activate.implementation = (organization) => {
            checkObjectType('organization', organization, null, Organization);
            config.organizationID = organization.slug;
        };

        ltts.organizations.deactivate.implementation = async () => {
            config.organizationID = null;
        };

        return ltts;
    }

    module.exports = implementAPI;
})();
