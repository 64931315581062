// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT
import React from 'react';
import { Steps, Typography } from 'antd';
import { useSelector } from 'react-redux';
import CreateProject from './create-project-mgnt';
import { CombinedState } from 'reducers/interfaces';
import CreateProjectDetails from './create-project-details';
import AppsNStages from './apps-stages/react-flow-renderer';
import ProjectPreview from './project-preview';
const { Step } = Steps;
const { Text } = Typography;

function ProjectCreationStepper(): JSX.Element {
    const projectStepper = useSelector((state: CombinedState) => state.projects.activities.stepperCount);

    const steps = [
        {
            title: 'Create Project',
            content: <CreateProject />,
        },
        {
            title: 'Project Configuration',
            content: <CreateProjectDetails />,
        },
        {
            title: 'Apps',
            content: <AppsNStages />,
        },
        {
            title: 'Preview',
            content: <ProjectPreview />,
        },
    ];

    return (
        <div className="stepper-layout-container">
                <Text className='step-title'> {steps[projectStepper].title}</Text>
                <div className='ltts-step-align'>
                    <Steps progressDot current={projectStepper} className='ltts-step-header'>
                        {steps.map((item) => (
                            <Step key={item.title} title={item.title} className='step-header-content' />
                        ))}
                    </Steps>
                    <div className='steps-content'>{steps[projectStepper].content}</div>
                </div>
        </div>
    );
}

export default ProjectCreationStepper;
