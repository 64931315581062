//  Copyright (C) 2022 LTTS

//  SPDX-License-Identifier: MIT
import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Button, Select, Collapse, List, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import { CombinedState } from '../../reducers/interfaces';
import { useSelector, useDispatch } from 'react-redux';
import Spin from 'antd/lib/spin';
import {
    UploadImageIcon,
    UploadVideoIcon,
    InformationIcon,
    DownloadIcon,
    ExportIcon,
    InfoBlackIcon,
    ExportLiDarIcon,
    ExportAudioIcon,
} from 'icons';
import {
    getProjectData,
    getProjectExportFormat,
    downloadExportData,
    generateMappingToken,
} from 'actions/projects-mgnt-actions';
import { useHistory } from 'react-router';
import notification from 'antd/lib/notification';

const { Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

interface Props {
    location: any;
}

function ProjectDatasetComponent(props: Props): JSX.Element {
    const exportData = useSelector((state: CombinedState) => state.projects.projectExportData);
    const projectExportDataFormat = useSelector((state: CombinedState) => state.projects.projectExportDataFormat);
    const dispatch = useDispatch();
    const history = useHistory();
    const [format, setFormat] = useState(projectExportDataFormat[0]?.name);

    useEffect(() => {
        const project_id = props.location.state;
        dispatch(getProjectData(project_id));
        dispatch(getProjectExportFormat());
        dispatch(generateMappingToken(project_id));
    }, [dispatch]);

    useEffect(() => {
        setFormat(projectExportDataFormat[0]?.name);
    }, [projectExportDataFormat]);

    const formatValue = (values: any) => {
        setFormat(values);
    };

    const panelStatus = (status: any) => {
        return (
            <div className='collapsable-dropdown-status'>
                <Icon className='status-icon' component={status === 'completed' ? InformationIcon : InfoBlackIcon} />
                {status}
            </div>
        );
    };

    const handleExport = (event: any, taskId: any, format: any, annotation: any) => {
        event.stopPropagation(), dispatch(downloadExportData(taskId, format, annotation));
        notification.info({
            message: 'Dataset export started',
            description:
                'Dataset export was started for Projects. Download will start automatically as soon as the dataset is ready.',
        });
    };

    return (
        <>
            <Row justify='start'>
                <Col span={24} className='project-layout'>
                    <Text className='project-menu-header'>Export Data</Text>
                    <div className='project-menu-content'>
                        <div className='project-header-border'></div>
                        <div className='project-export-files'>
                            <br />
                            <Collapse defaultActiveKey={['0']} className='collaspe-content'>
                                {exportData.map((val: any) => {
                                    return (
                                        <Panel
                                            showArrow={false}
                                            header={
                                                <div className='collapsable-content'>
                                                    <Row onClick={(event: any) => event.stopPropagation()}>
                                                        {val.project_type === 1 ? (
                                                            <Icon
                                                                className='upload-image-icon'
                                                                component={UploadImageIcon}
                                                            />
                                                        ) : val.project_type === 2 ? (
                                                            <Icon
                                                                className='upload-image-icon'
                                                                component={UploadVideoIcon}
                                                            />
                                                        ) : val.project_type === 3 ? (
                                                            <Icon
                                                                className='upload-image-icon'
                                                                component={ExportLiDarIcon}
                                                            />
                                                        ) : val.project_type === 4 ? (
                                                            <Icon
                                                                className='upload-image-icon'
                                                                component={ExportAudioIcon}
                                                            />
                                                        ) : null}
                                                    </Row>

                                                    <div style={{ padding: '0px 20px' }}>{val.data_ref}</div>

                                                    <Select
                                                        onClick={(event: any) => event.stopPropagation()}
                                                        onChange={formatValue}
                                                        size='large'
                                                        style={{ width: '200px' }}
                                                        defaultValue={format}
                                                    >
                                                        {projectExportDataFormat?.map((format: any) => (
                                                            <Option key={format.id} value={format.name}>
                                                                {format.name}
                                                            </Option>
                                                        ))}
                                                    </Select>

                                                    <div className='export-data'>
                                                        <Tooltip title='Info'>
                                                            <Button onClick={(event: any) => event.stopPropagation()}>
                                                                <Icon component={InformationIcon} />
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title='Annotation'>
                                                            <Button
                                                                onClick={(event: any) =>
                                                                    handleExport(event, val.task_id, format, 'dataset')
                                                                }
                                                            >
                                                                <Icon className='export-icon' component={ExportIcon} />
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title='Export'>
                                                            <Button
                                                                onClick={(event: any) =>
                                                                    handleExport(
                                                                        event,
                                                                        val.task_id,
                                                                        format,
                                                                        'annotations',
                                                                    )
                                                                }
                                                            >
                                                                <Icon component={DownloadIcon} />
                                                            </Button>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            }
                                            key='1'
                                        >
                                            <List>
                                                {val.data_info?.map((v: any, index: any) => (
                                                    <List.Item key={v.id} className='list-align'>
                                                        <Col md={6} xl={8} push={3}>
                                                            {index}-{v.job_id}
                                                        </Col>
                                                        <Col
                                                            className='dataInfo-status'
                                                            md={12}
                                                            xl={16}
                                                            push={2}
                                                            offset={1}
                                                        >
                                                            {v.status === 'new' || v.status === 'in_progress' ? (
                                                                <div className='status-new-progress'>
                                                                    {panelStatus(v.status)}{' '}
                                                                </div>
                                                            ) : v.status === 'completed' ? (
                                                                <div className='status-completed'>
                                                                    {panelStatus(v.status)}{' '}
                                                                </div>
                                                            ) : null}
                                                        </Col>
                                                    </List.Item>
                                                ))}
                                            </List>
                                        </Panel>
                                    );
                                })}
                            </Collapse>
                        </div>
                        <div className='project-menu-submit-btn'>
                            <Button
                                type='primary'
                                className='project-upload-button'
                                onClick={() => history.push('/projects')}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default ProjectDatasetComponent;
