// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT

import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Typography, Button, Select, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';

import { fetchTicketReports, resetReportState } from 'actions/apps-stages-actions';

const { Text } = Typography;

export default function BMWReportsComponent(): JSX.Element {
    const dispatch = useDispatch();

    const [reportsId, setReportsId] = useState(null);

    const { ticketReports } = useSelector((state: CombinedState) => state.appsStages);

    const ticketReportsHandler = (ticketNo: any) => {
        setReportsId(ticketNo);
    };

    const dispatchReportsHandler = () => {
        dispatch(fetchTicketReports(reportsId));
    };

    const showSpinnerContent = () => {
        return <div id='previewCommentWrapper11'></div>;
    };

    useEffect(() => {
        dispatch(resetReportState());
    }, [dispatch]);

    const onSearch = (value: any) => {};

    return (
        <>
            {/* {isIframeOpen ? (
                showSpinnerContent()
            ) : ( */}
            <>
                <div className='customer-review-div'>BMW Reports</div>
                <div className='customer-review-body'>
                    <div className='select-customer-review-project'>
                        <label className='customer-review-select-project'>Select Ticket :</label>
                        <Select
                            placeholder='Select a Ticket'
                            style={{ width: '70%', margin: '0px 20px 20px 20px' }}
                            onChange={ticketReportsHandler}
                            onSearch={onSearch}
                            showSearch
                            optionFilterProp='children'
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                            options={[
                                {
                                    value: 'T3',
                                    label: 'T3',
                                },
                                {
                                    value: 'T8',
                                    label: 'T8',
                                },
                                {
                                    value: 'T1&T2',
                                    label: 'T1&T2',
                                },
                            ]}
                        ></Select>
                        <Button
                            className='report-download'
                            disabled={reportsId === null}
                            onClick={() => dispatchReportsHandler()}
                        >
                            <b>View Ticket Reports</b>
                        </Button>
                    </div>
                    <div className='report-stage-card'>
                        <Row gutter={[8, 8]}>
                            {ticketReports?.length > 0 && ticketReports ? (
                                ticketReports?.map(
                                    (reports: any, index: number): JSX.Element => (
                                        <Col xs={24} lg={12} xl={10} xxl={6} className='' key={ticketReports[index]}>
                                            <Card
                                                className='ltts-item-card report-card'
                                                title={
                                                    <div className='Project-taskcount-text-align'>
                                                        <div className='ltts-project-card-layout'>
                                                            <div className='ltts-project-card-content'>
                                                                <p className='report-stage-align'>
                                                                    <Tooltip
                                                                        placement='rightTop'
                                                                        title={'tset'}
                                                                        overlayStyle={{ maxWidth: '500px' }}
                                                                    >
                                                                        <span aria-hidden>
                                                                            <Text strong className='ltts-project-item'>
                                                                                Project Name:
                                                                            </Text>
                                                                            <span className='ticket-report-stage '>
                                                                                {reports?.project_name}
                                                                            </span>
                                                                        </span>
                                                                    </Tooltip>
                                                                </p>
                                                                {/* <p className='project-stage-name'>
                                                                        <Tooltip
                                                                            placement='rightTop'
                                                                            title={"batch"}
                                                                            overlayStyle={{ maxWidth: '500px' }}
                                                                        >
                                                                            <span aria-hidden>
                                                                                <Text
                                                                                    strong
                                                                                    className='ltts-project-item'
                                                                                >
                                                                                    Batch
                                                                                </Text>
                                                                                <span> 2022045</span>
                                                                            </span>
                                                                        </Tooltip>
                                                                    </p> */}
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            padding: '18px 50px 0px 20px',
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            <Tooltip title={'annot'}>
                                                                                <Row
                                                                                    style={{
                                                                                        flexDirection: 'column',
                                                                                    }}
                                                                                >
                                                                                    <>
                                                                                        <Col className='ticket-status'>
                                                                                            <Text className='report-data-status report-total-color'>
                                                                                                Total Annotations
                                                                                                <Text className='stage-data-status-count data-new-status'>
                                                                                                    {
                                                                                                        reports?.total_annotation
                                                                                                    }
                                                                                                </Text>
                                                                                            </Text>
                                                                                        </Col>
                                                                                    </>
                                                                                </Row>
                                                                            </Tooltip>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style={{ background: '#EEEEEE', padding: '10px' }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            ></Card>
                                        </Col>
                                    ),
                                )
                            ) : (
                                <Col className='gutter-row' span={6}>
                                    <p>Select a ticket to view reports!</p>
                                </Col>
                            )}
                        </Row>
                    </div>
                </div>
            </>
            {/* )} */}
        </>
    );
}
