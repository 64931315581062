// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState } from 'react';
import { Bar } from '@ant-design/plots';
import { Card, Row, Col, Typography, Select } from 'antd';
import Icon from '@ant-design/icons';
import { ProjectCountIcon, ProjectProgressIcon, ProjectCompletedIcon } from 'icons';

const { Text, Title } = Typography;

interface Props {
    projectData: [];
    projectsName: [];
    onSearchChange: (value: any) => void;
}

export default function ReportProjectDataChart(props: Props): JSX.Element {
    const { projectData, projectsName } = props;
    const totalProjects = projectData?.results?.length > 0 ? projectData?.results : [];
    const [filteredProjects, setFilteredProjects] = useState(totalProjects);
    const data = filteredProjects?.length > 0 ? filteredProjects : [];

    useEffect(() => {
        setFilteredProjects(totalProjects);
    }, [projectData]);

    const searchProjectBar = (value: any) => {
        if (value.length !== 0) {
            const selectedProjects = totalProjects.filter((item: any) => {
                for (var key in value) {
                    if (item.label === value[key]) return true;
                }
                return false;
            });
            setFilteredProjects(selectedProjects);
        } else {
            setFilteredProjects(totalProjects);
        }
    };

    const onSearch = (value: any) => {};

    const config = {
        data,
        isGroup: true,
        xField: 'value',
        yField: 'label',
        color: ['#FF6464', '#453F94', '#72B22E'],
        seriesField: 'type',
        label: {
            position: 'right',
            offset: '-30%',
            content: function content(_ref: any) {
                return ''.concat(_ref.value, '%');
            },
            layout: [
                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
            ],
        },
    };

    return (
        <>
            <Row>
                <Col className='report-align'>
                    <Card className='project-report-card'>
                        <Icon component={ProjectCountIcon} />
                        <div className='report-card-content'>
                            <Text className='report-text'>Total Project</Text>
                            <Text className='report-projects-count project-text-color'>{projectData?.total || 0}</Text>
                        </div>
                    </Card>
                </Col>
                <Col className='report-align'>
                    <Card className='project-report-card'>
                        <Icon component={ProjectProgressIcon} />
                        <div className='report-card-content'>
                            <Text className='report-text'>In-Progress</Text>
                            <Text className='report-projects-count progress-color'>
                                {projectData?.in_progress || 0}
                            </Text>
                        </div>
                    </Card>
                </Col>
                <Col className='report-align'>
                    <Card className='project-report-card'>
                        <Icon component={ProjectCompletedIcon} />
                        <div className='report-card-content'>
                            <Text className='report-text'>Completed</Text>
                            <Text className='report-projects-count completed-color'>{projectData?.completed || 0}</Text>
                        </div>
                    </Card>
                </Col>
            </Row>
            <div className='reports-data-align'>
                <label>Search Projects:</label>
                <Select
                    mode='multiple'
                    allowClear
                    style={{
                        width: '70%',
                    }}
                    placeholder='Please select'
                    onSearch={onSearch}
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={searchProjectBar}
                >
                    {projectsName.map(
                        (project: any): JSX.Element => (
                            <Select.Option value={project.name} key={project.id}>
                                {project.name}
                            </Select.Option>
                        ),
                    )}
                </Select>
            </div>
            <Title className='report-project-title' level={5}>
                Project Data
            </Title>
            <Bar {...config} />
        </>
    );
}
