// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import _ltts from 'ltts-core/src/api';

const ltts: any = _ltts;

ltts.config.backendAPI = '/api';
ltts.config.origin = window.location.origin;
ltts.config.uploadChunkSize = 100;
(globalThis as any).ltts = ltts;

export default function getCore(): any {
    return ltts;
}
