// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Typography, Tabs, Select, Button, Modal, Tooltip, Spin } from 'antd';
import Icon, { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { ReportDownloadIcon } from 'icons';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchProjectStages,
    fetchStageReport,
    getDownloadProjectReports,
    resetAnnotationInfo,
    fetchProjectStageData,
} from 'actions/reports-action';
import { CombinedState } from 'reducers/interfaces';
import ReportAnalyticsModal from './report-analytics-modal';
import ReactExport from 'react-export-excel';
import ReportProjectDataChart from './report-project-data';

const { Text } = Typography;
const { TabPane } = Tabs;

interface Props {
    projectData: [];
    projectsName: [];
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ReportProjectComponent(props: Props): JSX.Element {
    const { projectData, projectsName } = props;
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState('projectdata');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [exportData, setExportData] = useState([]);
    const [fetchReport, setFetchReport] = useState([]);
    const [projectReportId, setProjectReportId] = useState();

    const { projectAppStages, fetching, projectReportsDownload, stageDataStatus } = useSelector(
        (state: CombinedState) => state.reports,
    );

    const annotatedReports = [];

    const workflowDataStatus = [];

    for (let i = 0; i < projectAppStages.length; i++) {
        for (let j = 0; j < stageDataStatus.length; j++) {
            if (projectAppStages[i]?.project_app_id === stageDataStatus[j]?.project_app_id) {
                let projectStatus = {
                    project_app_id: projectAppStages[i]?.project_app_id,
                    stage: projectAppStages[i]?.stage,
                    app_alias_name: projectAppStages[i]?.app_alias_name,
                    stage_summary: projectAppStages[i]?.stage_summary,
                    data_summary: stageDataStatus[j]?.data_summary,
                };
                workflowDataStatus.push(projectStatus);
            }
        }
    }

    useEffect(() => {
        if (Object.keys(projectReportsDownload)?.length > 0) {
            const projectName = projectReportsDownload?.project_name;
            const aliasName = projectReportsDownload?.project_app_stages?.map((name: any) => name.app_alias_name);
            projectReportsDownload?.project_app_stages?.map((stages: any) => {
                for (let i = 0; i < aliasName?.length; i++) {
                    if (stages.app_alias_name === aliasName[i]) {
                        if (stages.annotation_info?.length > 0) {
                            stages.annotation_info?.map((annot: any) => {
                                const downloadAnnotationInfo = {
                                    projectName: projectName,
                                    appAliasName: aliasName[i],
                                    username: annot.user,
                                    count: annot.annotation,
                                    completed: annot.completed_at,
                                    workflowId: annot.workflow_id,
                                    jobId: annot.job_id,
                                    parentWf: annot.parent_wf,
                                    status: annot.status,
                                };
                                annotatedReports.push(downloadAnnotationInfo);
                            });
                        } else {
                            const downloadProjectsName = {
                                projectName: projectName,
                                appAliasName: aliasName[i],
                            };
                            annotatedReports.push(downloadProjectsName);
                        }
                        break;
                    }
                }
            });
            setExportData(annotatedReports);
        }
    }, [projectReportsDownload]);

    const reportHandler = (projectId: any) => {
        dispatch(fetchProjectStages(projectId));
        dispatch(fetchProjectStageData(projectId));
        setProjectReportId(projectId);
    };

    const projectHandler = (id: any) => {
        setFetchReport(id);
    };

    const viewReports = (projectAppId: any) => {
        dispatch(fetchStageReport(projectAppId));
        setIsModalVisible(true);
    };

    const changeTab = (activeKey: any) => {
        setActiveTab(activeKey);
        setIsModalVisible(false);
    };

    const backToStage = () => {
        setIsModalVisible(false);
        dispatch(resetAnnotationInfo());
    };

    if (fetching) {
        return <Spin size='large' className='ltts-spinner' />;
    }

    const onSearch = (value: any) => {};

    return (
        <>
            <Tabs
                activeKey={activeTab}
                defaultActiveKey='projectdata'
                className='report-project-tab'
                onChange={changeTab}
            >
                <TabPane tab='Project Data' key='projectdata'>
                    <ReportProjectDataChart projectData={projectData} projectsName={projectsName} />
                </TabPane>

                <TabPane tab='Project Wise Report' key='Project Wise Report'>
                    {isModalVisible === false ? (
                        <>
                            <div style={{ padding: '20px 20px 0px' }}>
                                <label>Select Project :</label>
                                <Select
                                    placeholder='Select a Project'
                                    style={{ width: '70%', margin: '0px 20px 20px 20px' }}
                                    onChange={projectHandler}
                                    defaultValue={projectReportId}
                                    onSearch={onSearch}
                                    showSearch
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string)
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                >
                                    {projectsName.map(
                                        (project: any): JSX.Element => (
                                            <Select.Option value={project.id} key={project.id}>
                                                {project.name}
                                            </Select.Option>
                                        ),
                                    )}
                                </Select>

                                <Button
                                    className='report-download'
                                    disabled={fetchReport?.length === 0}
                                    onClick={() => reportHandler(fetchReport)}
                                >
                                    Fetch Report
                                </Button>
                                {/*
                                {exportData?.length > 0 ? (
                                    <ExcelFile
                                        element={
                                            <Button className='report-download'>
                                                <Icon component={ReportDownloadIcon} />
                                                Download
                                            </Button>
                                        }
                                    >
                                        <ExcelSheet data={exportData} name='ProjectsReports'>
                                            <ExcelColumn label='Project Name' value='projectName' />
                                            <ExcelColumn label='Project Stage Name' value='appAliasName' />
                                            <ExcelColumn label='Username' value='username' />
                                            <ExcelColumn label='Annotation Count' value='count' />
                                            <ExcelColumn label='Completed' value='completed' />
                                            <ExcelColumn label='Workflow Id' value='workflowId' />
                                            <ExcelColumn label='Job Id' value='jobId' />
                                            <ExcelColumn label='Parent WF' value='parentWf' />
                                            <ExcelColumn label='Status' value='status' />
                                        </ExcelSheet>
                                    </ExcelFile>
                                ) : null} */}
                            </div>
                            <div className='report-stage-card'>
                                <Row gutter={[10, 10]}>
                                    {workflowDataStatus?.length > 0 && workflowDataStatus ? (
                                        (workflowDataStatus.sort((a, b) => (a.stage > b.stage ? 1 : -1)),
                                        workflowDataStatus?.map(
                                            (app: any, index: number): JSX.Element => (
                                                <Col
                                                    xs={24}
                                                    md={16}
                                                    lg={16}
                                                    xl={12}
                                                    xxl={8}
                                                    key={workflowDataStatus[index]}
                                                >
                                                    <Card
                                                        className='ltts-item-card'
                                                        title={
                                                            <div className='Project-taskcount-text-align'>
                                                                <div className='ltts-project-card-layout'>
                                                                    <div className='ltts-project-card-content'>
                                                                        <p className='report-stage-align'>
                                                                            <Tooltip
                                                                                placement='rightTop'
                                                                                title={app?.app_alias_name}
                                                                                overlayStyle={{ maxWidth: '500px' }}
                                                                            >
                                                                                <span aria-hidden>
                                                                                    <Text
                                                                                        strong
                                                                                        className='ltts-project-item'
                                                                                    >
                                                                                        Stage{' '}
                                                                                    </Text>
                                                                                    <span className='report-stage-order'>
                                                                                        {app?.stage}
                                                                                    </span>
                                                                                </span>
                                                                            </Tooltip>
                                                                        </p>
                                                                        <p className='project-stage-name'>
                                                                            <Tooltip
                                                                                placement='rightTop'
                                                                                title={app?.app_alias_name}
                                                                                overlayStyle={{ maxWidth: '500px' }}
                                                                            >
                                                                                <span aria-hidden>
                                                                                    <Text
                                                                                        strong
                                                                                        className='ltts-project-item'
                                                                                    >
                                                                                        {' '}
                                                                                        Stage Name :{' '}
                                                                                    </Text>
                                                                                    <span>{app?.app_alias_name}</span>
                                                                                </span>
                                                                            </Tooltip>
                                                                        </p>
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                background: '#EEEEEE',
                                                                            }}
                                                                        >
                                                                            <div className='workflow-data-align'>
                                                                                <p className='stage-content'>
                                                                                    Workflow Status:
                                                                                </p>
                                                                                <Tooltip title={app?.app_alias_name}>
                                                                                    <span className='stage-status'>
                                                                                        <Text className='report-status report-new-color'>
                                                                                            New
                                                                                            <RightOutlined className='new-icon-align' />
                                                                                        </Text>
                                                                                        <Text className='stage-status-count'>
                                                                                            {
                                                                                                workflowDataStatus[
                                                                                                    index
                                                                                                ]?.stage_summary?.new
                                                                                            }
                                                                                        </Text>
                                                                                    </span>

                                                                                    <span className='stage-status'>
                                                                                        <Text className='report-status report-progress-color'>
                                                                                            In-Progress
                                                                                            <RightOutlined className='progress-icon-align' />
                                                                                        </Text>
                                                                                        <Text className='stage-status-count'>
                                                                                            {
                                                                                                workflowDataStatus[
                                                                                                    index
                                                                                                ]?.stage_summary
                                                                                                    ?.in_progress
                                                                                            }
                                                                                        </Text>
                                                                                    </span>
                                                                                    <span className='stage-status'>
                                                                                        <Text className='report-status report-completed-color'>
                                                                                            Completed
                                                                                            <RightOutlined className='complete-icon-align' />
                                                                                        </Text>
                                                                                        <Text className='stage-status-count'>
                                                                                            {
                                                                                                workflowDataStatus[
                                                                                                    index
                                                                                                ]?.stage_summary
                                                                                                    ?.completed
                                                                                            }
                                                                                        </Text>
                                                                                    </span>
                                                                                    <span className='stage-status'>
                                                                                        <Text className='report-status report-rejected-color'>
                                                                                            Rejected
                                                                                            <RightOutlined className='reject-icon-align' />
                                                                                        </Text>
                                                                                        <Text className='stage-status-count'>
                                                                                            {
                                                                                                workflowDataStatus[
                                                                                                    index
                                                                                                ]?.stage_summary
                                                                                                    ?.rejected
                                                                                            }
                                                                                        </Text>
                                                                                    </span>
                                                                                    <span className='stage-status'>
                                                                                        <Text className='report-status report-reasssigned-color'>
                                                                                            Re-Assigned
                                                                                            <RightOutlined className='reassign-icon-align' />
                                                                                        </Text>
                                                                                        <Text className='stage-status-count'>
                                                                                            {
                                                                                                workflowDataStatus[
                                                                                                    index
                                                                                                ]?.stage_summary
                                                                                                    ?.reassigned
                                                                                            }
                                                                                        </Text>
                                                                                    </span>
                                                                                </Tooltip>
                                                                            </div>

                                                                            <div className='data-status-align'>
                                                                                <p className='stage-content'>
                                                                                    Data Status:
                                                                                </p>

                                                                                <Tooltip title={app?.app_alias_name}>
                                                                                    <span className='stage-status'>
                                                                                        <Text className='report-data-status report-new-color'>
                                                                                            New
                                                                                            <Text className='stage-data-status-count data-new-status'>
                                                                                                {
                                                                                                    workflowDataStatus[
                                                                                                        index
                                                                                                    ]?.data_summary?.new
                                                                                                }
                                                                                            </Text>
                                                                                        </Text>
                                                                                    </span>
                                                                                    <span className='stage-status'>
                                                                                        <Text className='report-data-status report-progress-color'>
                                                                                            In-Progress
                                                                                            <Text className='stage-data-status-count data-progress-status'>
                                                                                                {
                                                                                                    workflowDataStatus[
                                                                                                        index
                                                                                                    ]?.data_summary
                                                                                                        ?.in_progress
                                                                                                }
                                                                                            </Text>
                                                                                        </Text>
                                                                                    </span>
                                                                                    <span className='stage-status'>
                                                                                        <Text className='report-data-status report-completed-color'>
                                                                                            Completed
                                                                                            <Text className='stage-data-status-count data-complete-status'>
                                                                                                {
                                                                                                    workflowDataStatus[
                                                                                                        index
                                                                                                    ]?.data_summary
                                                                                                        ?.completed
                                                                                                }
                                                                                            </Text>
                                                                                        </Text>
                                                                                    </span>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </div>
                                                                        <p className='view-stage-report'>
                                                                            <Tooltip
                                                                                placement='rightTop'
                                                                                title={app?.app_alias_name}
                                                                                overlayStyle={{ maxWidth: '500px' }}
                                                                            >
                                                                                <div>
                                                                                    <Button
                                                                                        className='report-download'
                                                                                        onClick={() => {
                                                                                            viewReports(
                                                                                                workflowDataStatus[index]
                                                                                                    .project_app_id,
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        View Report
                                                                                    </Button>
                                                                                </div>
                                                                            </Tooltip>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    ></Card>
                                                </Col>
                                            ),
                                        ))
                                    ) : (
                                        <Col className='gutter-row' span={6}>
                                            <p>No Annotation Stages Available!</p>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </>
                    ) : (
                        <>
                            {fetching ? (
                                <Spin size='large' className='ltts-spinner' />
                            ) : (
                                <div style={{ padding: '12px 20px' }}>
                                    <Button className='back-report-btn' onClick={backToStage}>
                                        <LeftOutlined />
                                        Back to reports stages
                                    </Button>
                                    <ReportAnalyticsModal reportId={projectReportId} />
                                </div>
                            )}
                        </>
                    )}
                </TabPane>
            </Tabs>
        </>
    );
}
