import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    useNodesState,
    useEdgesState,
    Controls,
    MarkerType,
    Background,
} from 'react-flow-renderer';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Transfer, Typography } from 'antd';
import { clickNext, clickPrev } from 'actions/projects-mgnt-actions';
import Sidebar from './sidebar';
import ConnectionLine from './connection-line';
import {
    getApps,
    fetchUsers,
    assignAppToUsers,
    updateProjectAppName,
    getProjectAppAssignedUsers,
} from 'actions/apps-stages-actions';
import { Modal, Input } from 'antd';
import { CombinedState } from 'reducers/interfaces';
import Icon from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
    CuboidIcon,
    EllipseShapeIcon,
    PolyLineIcon,
    PointShapeIcon,
    PolygonShapeIcon,
    RectangleShapeIcon,
    TagSmallIcon,
    BrushIcon,
} from 'icons';
import './index.scss';

const { Title } = Typography;
const initialNodes: any = [];

export default function AppsNStages() {
    let id = 1;
    const getId = () => `${id++}`;

    const reactFlowWrapper = useRef(null);
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [nodePayload, setNodePayload] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedNode, setSelectedNode] = useState({});
    const [userData, setUserData] = useState([]);
    const [targetKeys, setTargetKey] = useState([]);
    const [annotShapes, setannotShapes] = useState([]);
    const [platformMode, setPlatformMode] = useState(['submit']);
    const [selectedNodeLevels, setSelectedNodeLevels] = useState({});
    const [axesPosition, setAxesPosition] = useState({});
    const [pixelPolygon, setPixelPolygon] = useState(false);
    const [enablePixel, setEnablePixel] = useState(false);
    const [enableCropping, setCroppingPolygon] = useState(false);

    const { stagesCreated, projectApps, assignedProjectAppsToUsers, users } = useSelector(
        (state: CombinedState) => state.appsStages,
    );
    const { id: project_id, project_type } = useSelector((state: CombinedState) => state.projects.activities.creates);
    const sequenceDataPresent = useSelector((state: CombinedState) => state.projects.activities.creates.project_config.advanced_configuration.sequenceMethod);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getApps({ project_type }));
        dispatch(fetchUsers());
    }, []);

    const updateNodes = () => {
        setNodes((nds) =>
            nds.map((node) => {
                if (selectedNode?.app_seq_id === parseInt(node.id)) {
                    node.name = selectedNode.app_alias_name;
                }
                return {
                    ...node,
                    data: {
                        label: (
                            <>
                                <strong>STAGE-{node.id}</strong>
                                <div>{node.name}</div>
                                <button onClick={() => onButtonClick(node, projectApps, selectedNode)}>
                                    Configure
                                </button>
                            </>
                        ),
                    },
                };
            }),
        );
    };

    useEffect(() => {
        updateNodes();
    }, [stagesCreated, setNodes]);

    const onButtonClick = useCallback(
        (node, projectApps, selectedNode) => {
            setUsers(users);
            let nodeData = parseInt(node.id) === selectedNode.app_seq_id ? selectedNode : null;
            nodeData = nodeData ? nodeData : projectApps.find((data) => parseInt(node.id) === data.app_seq_id);
            setSelectedNode({ ...nodeData, app_alias_name: node.name, app_config: [node.position, node.levels] });
            dispatch(getProjectAppAssignedUsers(nodeData));
            setShowModal(true);
            // setPixelPolygon(false);
            // setEnablePixel(false);
        },
        [users],
    );

    const onConnect = useCallback(
        (params) =>
            setEdges((eds) => {
                return addEdge({ ...params, animated: true, markerEnd: { type: MarkerType.Arrow } }, eds);
            }),
        [],
    );

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();

            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');
            const appData = JSON.parse(event.dataTransfer.getData('application/appData'));

            // check if the dropped element is valid
            if (typeof type === 'undefined' || !type) {
                return;
            }

            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });
            setAxesPosition(position);
            const levels = {
                mode: platformMode,
                shapes: annotShapes,
            };

            const nodeWidth = 120;
            const nodeHeight = 80;

            const style = { width: nodeWidth, height: nodeHeight, fontSize: 10, color: 'black' };
            let id = getId();
            const newNode = {
                id,
                type,
                position,
                style,
                sourcePosition: 'right',
                targetPosition: 'left',
                name: appData.app_name,
                data: {
                    label: (
                        <>
                            <strong>STAGE-{id}</strong>
                            <div>{appData.app_name}</div>
                        </>
                    ),
                },
            };
            const nodeData = {
                app_alias_name: appData.app_name,
                app_id: appData.id,
                app_config: { axesPosition, levels ,sequenceDataPresent},
                project_id,
                status: 'new',
                app_seq_id: parseInt(id),
            };
            setNodePayload((data: any) => data.concat(nodeData));
            setNodes((nds: any) => nds.concat(newNode));
        },
        [reactFlowInstance],
    );

    const handleCancel = () => {
        setShowModal(false);
    };

    const platformLevel = (value: any) => {
        setPlatformMode(value);
        setSelectedNodeLevels({
            ...selectedNode,
            app_config: {
                ...selectedNode.app_config[0],
                sequenceDataPresent:sequenceDataPresent,
                levels: {
                    mode: value,
                    shapes: annotShapes,
                    pixel: pixelPolygon,
                    cropping: enableCropping,
                },
            },
        });
    };

    const annotationLevel = (shapes: any) => {
        setannotShapes(shapes);
        const poly = shapes.filter((item: any) => item === 'polygon');
        poly[0] === 'polygon' ? setEnablePixel(true) : setEnablePixel(false);
        setSelectedNodeLevels({
            ...selectedNode,
            app_config: {
                ...selectedNode.app_config[0],
                sequenceDataPresent:sequenceDataPresent,
                levels: {
                    mode: platformMode,
                    shapes: shapes,
                    pixel: pixelPolygon,
                    cropping: enableCropping,
                },
            },
        });
    };

    const saveAppData = () => {
        updateNodes();
        setShowModal(false);
        let appStagePayload = [];
        let data = {};
        for (let i = 0; i < targetKeys.length; i++) {
            data = {
                user_id: targetKeys[i],
                project_app_id: selectedNode.id,
            };
            appStagePayload.push(data);
        }

        Modal.confirm({
            title: 'Do you want to continue to save the data?',
            content: 'Once the user is assigned cannot be deassigned. Continue?',
            className: 'ltts-modal-confirm-delete-task',
            onOk: () => {
                dispatch(updateProjectAppName(selectedNodeLevels));
                dispatch(assignAppToUsers(appStagePayload));
                // setPlatformMode([]);
                // setannotShapes([]);
                // setPixelPolygon(false);
                // setEnablePixel(false);
                // setCroppingPolygon(false);
            },
            okButtonProps: {
                type: 'primary',
                danger: true,
            },
            okText: 'Yes',
            cancelText: 'No',
        });
    };

    const pixelHandler = (e: CheckboxChangeEvent) => {
        setPixelPolygon(e.target.checked);
        setSelectedNodeLevels({
            ...selectedNode,
            app_config: {
                ...selectedNode.app_config[0],
                sequenceDataPresent:sequenceDataPresent,
                levels: {
                    mode: platformMode,
                    shapes: annotShapes,
                    pixel: e.target.checked,
                    cropping: enableCropping,
                },
            },
        });
    };
    const croppingHandler = (e: CheckboxChangeEvent) => {
        setCroppingPolygon(e.target.checked);
        setSelectedNodeLevels({
            ...selectedNode,
            app_config: {
                ...selectedNode.app_config[0],
                sequenceDataPresent:sequenceDataPresent,
                levels: {
                    mode: platformMode,
                    shapes: annotShapes,
                    pixel: pixelPolygon,
                    cropping: e.target.checked,
                },
            },
        });
    };

    useEffect(() => {
        if (Object.keys(assignedProjectAppsToUsers).length > 0) {
            // Set available users
            const userList = users.map((user) => {
                let filteredUsers = assignedProjectAppsToUsers.filter(
                    (assignedUser) => assignedUser.user_id === user.id,
                );
                if (filteredUsers.length > 0) {
                    return { title: user.username, key: user.id, disabled: true };
                }
                return { title: user.username, key: user.id, disabled: false };
            });
            setUserData(userList);

            // Set assigned users
            let userIdList =
                Object.keys(assignedProjectAppsToUsers).length > 0
                    ? assignedProjectAppsToUsers?.map((assignedUser) => assignedUser.user_id)
                    : [];
            setTargetKey(userIdList);
        }
    }, [assignedProjectAppsToUsers]);

    const setUsers = (users) => {
        if (users.length) {
            let userList = users.map((user) => ({ title: user.username, key: user.id }));
            setUserData(userList);
            setTargetKey([]);
        }
    };

    const filterOption = (inputValue: any, option: any[]) => option.title.indexOf(inputValue) > -1;

    const handleChange = (targetKeys: any) => {
        setTargetKey(targetKeys);
        setSelectedNodeLevels({
            ...selectedNode,
            app_config: {
                ...selectedNode.app_config[0],
                sequenceDataPresent:sequenceDataPresent,
                levels: {
                    mode: platformMode,
                    shapes: annotShapes,
                    pixel: pixelPolygon,
                    cropping: enableCropping,
                },
            },
        });
    };

    const handleSearch = (dir: any, value: any) => {};
    const headerContent = (
        <div>
            <span>App Name :</span>
            <Input
                className='app-alias-name'
                placeholder='App name'
                value={selectedNode.app_alias_name}
                allowClear
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = event.target;
                    setSelectedNode({ ...selectedNode, app_alias_name: value });
                }}
            />
        </div>
    );

    return (
        <>
            <div className='dndflow'>
                <ReactFlowProvider>
                    <div className='reactflow-wrapper' ref={reactFlowWrapper}>
                        <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}
                            onInit={setReactFlowInstance}
                            onDrop={onDrop}
                            connectionLineComponent={ConnectionLine}
                            onDragOver={onDragOver}
                            fitView
                        >
                            <Controls />
                            <Background />
                        </ReactFlow>
                    </div>
                    <Sidebar nodePayload={nodePayload} />
                </ReactFlowProvider>
                <Modal
                    onOk={saveAppData}
                    onCancel={handleCancel}
                    cancelButtonProps={{
                        style: {
                            display: 'none',
                        },
                    }}
                    title={headerContent}
                    visible={showModal}
                    centered
                    width={800}
                >
                    <Transfer
                        dataSource={userData}
                        showSearch
                        titles={['Available Users', 'Assigned Users']}
                        filterOption={filterOption}
                        targetKeys={targetKeys}
                        onChange={handleChange}
                        onSearch={handleSearch}
                        render={(item) => item.title}
                        className='author-project-page'
                    />
                    <div style={{ margin: '20px 0px 0px 20px' }}>
                        <Title level={5}>Platform Level</Title>
                        <Checkbox.Group defaultValue={platformMode} onChange={platformLevel}>
                            <Checkbox value='submit'>Submit</Checkbox>
                            <Checkbox value='reject'>Reject</Checkbox>
                        </Checkbox.Group>
                        <div>
                            <Title level={5}>Annotation Level</Title>
                            <Checkbox.Group value={annotShapes} onChange={annotationLevel}>
                                <Checkbox value='rectangle'>
                                    <Icon component={RectangleShapeIcon} />
                                </Checkbox>
                                <Checkbox value='polygon'>
                                    <Icon component={PolygonShapeIcon} />
                                </Checkbox>
                                <Checkbox value='cuboid'>
                                    <Icon component={CuboidIcon} />
                                </Checkbox>
                                <Checkbox value='polyline'>
                                    <Icon component={PolyLineIcon} />
                                </Checkbox>
                                <Checkbox value='point'>
                                    <Icon component={PointShapeIcon} />
                                </Checkbox>
                                <Checkbox value='ellipse'>
                                    <Icon component={EllipseShapeIcon} />
                                </Checkbox>
                                <Checkbox value='tag'>
                                    <Icon component={TagSmallIcon} />
                                </Checkbox>
                                <Checkbox value='mask'>
                                    <Icon component={BrushIcon} />
                                </Checkbox>
                            </Checkbox.Group>
                            {enablePixel && (
                                <>
                                    <div>
                                        <Title level={5}>Pixel Level</Title>
                                        <Checkbox defaultChecked={pixelPolygon} onChange={pixelHandler}>
                                            Pixel Polygon
                                        </Checkbox>
                                    </div>
                                    <div>
                                        <Title level={5}>Cropping option</Title>
                                        <Checkbox defaultChecked={enableCropping} onChange={croppingHandler}>
                                            Cropping Polygon
                                        </Checkbox>
                                    </div>
                                </>
                            )}

                        </div>
                    </div>
                </Modal>
            </div>
            <div className='next-button-row-proj-config'>
                <div className='project-left-buttons'>
                    <Button type='text' className='project-config-save-draft'>
                        Save as Draft
                    </Button>
                </div>
                <div className='project-right-buttons'>
                    <Button type='primary' className='project-config-prev' onClick={() => dispatch(clickPrev())}>
                        Previous
                    </Button>
                    <Button type='primary' className='project-config-next' onClick={() => dispatch(clickNext())}>
                        <b>Next</b>
                    </Button>
                </div>
            </div>
        </>
    );
}
