import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    useNodesState,
    useEdgesState,
    Controls,
    MarkerType,
    Background,
} from 'react-flow-renderer';
import { useHistory, useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Collapse, notification, Transfer } from 'antd';
import { fetchUsers, assignAppToUsers, updateProjectAppName, getProjectApps, getProjectAppAssignedUsers, resetState } from 'actions/apps-stages-actions';
import { Modal, Input, Typography } from 'antd';
const { Title } = Typography;
import { CombinedState } from 'reducers/interfaces';

import '../create-project/apps-stages/index.scss';

const initialNodes: any = [];

export default function ProjectApps() {
    let id = 1;
    const getId = () => `${id++}`;

    const history = useHistory();

    const reactFlowWrapper = useRef(null);
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedNode, setSelectedNode] = useState({});
    const [userData, setUserData] = useState([]);
    const [targetKeys, setTargetKey] = useState([]);
    const { assignedProjectAppsToProjects, assignedProjectAppsToUsers, users } = useSelector((state: CombinedState) => state.appsStages);
    const params = useParams();
    const { id: projectId }= params;

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProjectApps({projectId}));
        dispatch(fetchUsers());
    }, []);

    const updateNodes = () => {
        setNodes((nds) =>
        nds.map((node) => {
            if(selectedNode?.app_seq_id === parseInt(node.app_seq_id)) {
                node.name = selectedNode.app_alias_name;
                node.app_alias_name = selectedNode.app_alias_name;
            }
          return {
            ...node,
            data: {
                label: (
                            <>
                            <strong>STAGE-{node.app_seq_id}</strong>
                            <div>{node.name}</div>
                            <button onClick={() => onButtonClick(node)}>Configure</button>
                            </>
                )
            },
          };
        })
      );
    };
    useEffect(() => {
        return () => {
            dispatch(resetState());
        };
    }, []);

    useEffect(() => {
        const { data } = assignedProjectAppsToProjects;
        const nodeWidth = 120;
        const nodeHeight = 80;

        const style = { width: nodeWidth, height: nodeHeight, fontSize: 10, color: 'black' };

        data?.forEach((app) => {

            const newNode = {
                id: app.id.toString(),
                // type,
                position: {x: app.app_config.x, y: app.app_config.y},
                style,
                sourcePosition: 'right',
                targetPosition: 'left',
                name: app.app_alias_name,
                app_alias_name: app.app_alias_name,
                app_seq_id: app.app_seq_id,
                data: { label: (
                    <>
                    <strong>STAGE-{app.app_seq_id}</strong>
                    <div>{app.app_alias_name }</div>
                    <button onClick={() => onButtonClick(app)}>Configure</button>
                    </>
                ),
             },
            };
            setNodes((nds: any) => nds.concat(newNode));
        });

    }, [assignedProjectAppsToProjects, setNodes]);

    const onButtonClick = useCallback((node) => {
        setSelectedNode({...node});
        dispatch(getProjectAppAssignedUsers(node));
        setShowModal(true);
    });

    const onConnect = useCallback((params) => setEdges((eds) => {
        return addEdge({...params, animated: true, markerEnd: { type: MarkerType.Arrow }}, eds)
    }), []);

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const handleCancel = () => {
        setShowModal(false);
      };

    const saveAppData = () => {
        updateNodes();
        setShowModal(false);
        let appStagePayload=[];
        let data ={};

        for (let i=0;i<targetKeys.length;i++) {
         data = {
             "user_id": targetKeys[i],
             "project_app_id": selectedNode.id,
        }
        appStagePayload.push(data)
    }
        dispatch(updateProjectAppName(selectedNode));
        dispatch(assignAppToUsers(appStagePayload));
    };

    const closeProjectAppsPage = () => {
        dispatch(resetState());
        history.push('/projects');
    };

    useEffect(() => {
        if(Object.keys(assignedProjectAppsToUsers).length > 0) {

        // Set available users
        const userList = users.map(user => {
            let filteredUsers = assignedProjectAppsToUsers.filter(assignedUser => assignedUser.user_id === user.id);
            if(filteredUsers.length > 0) {
            return {title: user.username, key: user.id, disabled: true}
            }
            return {title: user.username, key: user.id, disabled: false}

        });
        setUserData(userList);

        // Set assigned users
        let userIdList = Object.keys(assignedProjectAppsToUsers).length > 0 ? assignedProjectAppsToUsers?.map(assignedUser => ( assignedUser.user_id)) : [];
        setTargetKey(userIdList);
    }

    }, [assignedProjectAppsToUsers]);

    const filterOption = (inputValue: any, option: any[]) => option.title.indexOf(inputValue) > -1;
    const handleChange = (targetKeys: any) => {
        setTargetKey(targetKeys)
    };
    const handleSearch = (dir: any, value: any) => {
    };
    const headerContent = (
        <div>
            <span>App Name :</span>
            <Input className='app-alias-name' placeholder="App name" value={selectedNode.app_alias_name} allowClear
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const {value} = event.target;
                setSelectedNode({...selectedNode, app_alias_name: value});
            }}
            />
        </div>);

    return (
        <>
        <Title level={5}>Project Apps</Title>
        <div className='dndflow'>
            <ReactFlowProvider>
                <div className='reactflow-wrapper' ref={reactFlowWrapper}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        onInit={setReactFlowInstance}
                        defaultZoom={1.5}
                        onDragOver={onDragOver}
                        fitView
                    >
                        <Controls />
                        <Background />
                    </ReactFlow>
                </div>
            </ReactFlowProvider>
            <Modal
                onOk={saveAppData}
                onCancel={handleCancel}
                cancelButtonProps={{
                    style: {
                    display: "none",
                    },
                }}
                className=' author-project-page'
                title={headerContent}
                visible={showModal}
                centered
                width={800}
                >
                <Transfer
                    dataSource={userData}
                    showSearch
                    titles={['Available Users', 'Assigned Users']}
                    filterOption={filterOption}
                    targetKeys={targetKeys}
                    onChange={handleChange}
                    onSearch={handleSearch}
                    render={item => item.title}
                />
            </Modal>
        </div>
        <div className='next-button-row-proj-config'>
            <div className='project-right-buttons'>
              <Button type='primary' className='project-config-prev' onClick={() => closeProjectAppsPage()}>Close</Button>
            </div>
        </div>
        </>
    );
}