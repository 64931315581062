// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT
import React, { RefObject, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd/lib/grid';
import { Button, Collapse, notification } from 'antd';
import Text from 'antd/lib/typography/Text';
import LabelsEditor from 'components/labels-editor/labels-editor';
import { Label } from 'components/labels-editor/common';
import './project-details-styles.scss';
import AdvancedConfigurationForm, { SortingMethod, SequencialMethod, rotationMethod, transformMethod } from 'components/create-task-page/advanced-configuration-form';
import { createProjectDetails,clickPrev, getProjectDataById} from 'actions/projects-mgnt-actions';
import { CombinedState } from 'reducers/interfaces';
import Spin from 'antd/lib/spin';
import { LeftOutlined } from '@ant-design/icons';
import { Link, useLocation, useParams } from 'react-router-dom';

const { Panel } = Collapse;

export interface AdvancedConfiguration {
    segmentSize?: number;
    imageQuality?: number;
    overlapSize?: number;
    dataChunkSize?:number;
    sortingMethod?:string;
    sequenceMethod?: string;
    startFrame?:number;
    stopFrame?:number;
    useCache?:boolean;
    useZipChunks?:boolean;
    frameFilter?:string;
    userFlagEnabled?:boolean;
    rejectionFlagEnabled?:boolean;
    rotation?: any;
    translation?: any;
}
const initialValues: AdvancedConfiguration = {
    imageQuality: 70,
    useZipChunks: true,
    useCache: true,
    sortingMethod: SortingMethod.LEXICOGRAPHICAL,
    sequenceMethod: SequencialMethod.SEQUENCIAL,
    userFlagEnabled: true,
    rejectionFlagEnabled: true,
    rotation: rotationMethod.rotation,
    translation:transformMethod.translation,
};

export interface CreateTaskData {
    advanced: AdvancedConfiguration;
    labels: any[];
}
export enum AttributeType {
    SELECT = 'SELECT',
    RADIO = 'RADIO',
    CHECKBOX = 'CHECKBOX',
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
}

let defaultState: State = {
    advanced: {
        segmentSize: 1,
    },
    labels: [],
    // purposefully commented for further usage as required
    // projectId: null,
    // basic: {
    //     name: '',
    // },
    // subset: '',
    // advanced: {
    //     segmentSize: 1
    //     // lfs: false,
    //     // useZipChunks: true,
    //     // useCache: true,
    //     // sortingMethod: SortingMethod.LEXICOGRAPHICAL,
    // },
    // labels: Array(),
    // files: {
    //     local: [],
    //     share: [],
    //     remote: [],
    //     cloudStorage: [],
    // },
    // activeFileManagerTab: 'local',
    // cloudStorageId: null,
};

type State = CreateTaskData;

interface Props {
    labelNames?: string[];
    onSubmit: (label: Label | null) => void;
    installedGit: boolean;
    dumpers:[]
    projectId?: number | null;
    formRef: any;
    editInitialValues:any
}
export default function CreateProjectDetails(props: Props): JSX.Element {
    const newProjectState = useSelector((state: CombinedState) => state.projects);
    const newProjectId = useSelector((state: CombinedState) => state.projects.activities.creates.id);
    const location = useLocation();
    const params = useParams();
    const idparams= params.id;
    const id= idparams;
    useEffect(() => {
      if(id) {
          dispatch(getProjectDataById({id}));
        }
    }, [id]);
    const newProjectDetails = useSelector((state: CombinedState) => state.projects.projectDataeditable.project_config);
    let labelsEdit = newProjectDetails?.basic_configuration?.labels;
    let advancedEdit = newProjectDetails?.advanced_configuration;
    const newProjectPrevValue = useSelector((state: CombinedState) => state.projects.activities.creates.project_config);
    let labelsPrev = newProjectPrevValue?.basic_configuration.labels;
    let advancedPrev = newProjectPrevValue?.advanced_configuration;
    let advanced_previous = {
        dataChunkSize: newProjectPrevValue?.advanced_configuration.datachunk_size,
        frameFilter: newProjectPrevValue?.advanced_configuration.frame_filter,
        imageQuality: newProjectPrevValue?.advanced_configuration.image_quality,
        overlapSize: newProjectPrevValue?.advanced_configuration.overlap_size,
        segmentSize: newProjectPrevValue?.advanced_configuration.segment_size,
        sortingMethod: newProjectPrevValue?.advanced_configuration.sorting_method,
        sequenceMethod: newProjectPrevValue?.advanced_configuration.sequenceMethod,
        startFrame: newProjectPrevValue?.advanced_configuration.start_frame,
        stopFrame: newProjectPrevValue?.advanced_configuration.stop_frame,
        useCache: newProjectPrevValue?.advanced_configuration.use_cache,
        useZipChunks: newProjectPrevValue?.advanced_configuration.use_zipchunks,
        userFlagEnabled: newProjectPrevValue?.advanced_configuration.user_flag_enabled,
        rejectionFlagEnabled: newProjectPrevValue?.advanced_configuration.rejection_flag_enabled,
        rotation: newProjectPrevValue?.advanced_configuration?.rotation,
        translation: newProjectPrevValue?.advanced_configuration?.translation,
    };
    let advanced_edit = {
        dataChunkSize: newProjectDetails?.advanced_configuration?.datachunk_size,
        frameFilter: newProjectDetails?.advanced_configuration?.frame_filter,
        imageQuality: newProjectDetails?.advanced_configuration?.image_quality,
        overlapSize: newProjectDetails?.advanced_configuration?.overlap_size,
        segmentSize: newProjectDetails?.advanced_configuration?.segment_size,
        sortingMethod: newProjectDetails?.advanced_configuration?.sorting_method,
        sequenceMethod: newProjectDetails?.advanced_configuration?.sequenceMethod,
        startFrame: newProjectDetails?.advanced_configuration?.start_frame,
        stopFrame: newProjectDetails?.advanced_configuration?.stop_frame,
        useCache: newProjectDetails?.advanced_configuration?.use_cache,
        useZipChunks: newProjectDetails?.advanced_configuration?.use_zipchunks,
        userFlagEnabled: newProjectPrevValue?.advanced_configuration.user_flag_enabled,
        rejectionFlagEnabled: newProjectPrevValue?.advanced_configuration.rejection_flag_enabled,
        rotation: newProjectDetails?.advanced_configuration?.rotation,
        translation: newProjectDetails?.advanced_configuration?.translation,
    };

    const advancedConfigurationComponent: RefObject<AdvancedConfigurationForm> = React.createRef<AdvancedConfigurationForm>();
    const projectFetching = useSelector((state: CombinedState) => state.projects.fetching);
    const dispatch = useDispatch();
    const labels = labelsEdit?labelsEdit:labelsPrev?labelsPrev:[];
    defaultState = {
        ...defaultState,
        // labels: labelsPrev?labelsPrev:[], //commented to add edit scenario
        labels
    }
    let [localState, setLocalState] = useState(defaultState);
    const { installedGit, dumpers } = props;

    const handleSubmitAdvancedConfiguration = (values: AdvancedConfiguration): void => {
        const { labels } = localState;
        let projectData: Record<string, any> = {};
        projectData = {
            id: newProjectId?newProjectId:idparams,
            project_config: {
                basic_configuration: {
                        labels,
                },
                advanced_configuration: {
                    segment_size: values.segmentSize,
                    image_quality: values.imageQuality,
                    overlap_size: values.overlapSize,
                    sorting_method: values.sortingMethod,
                    sequenceMethod: values.sequenceMethod,
                    datachunk_size: values.dataChunkSize,
                    start_frame: values.startFrame,
                    stop_frame: values.stopFrame,
                    use_cache: values.useCache,
                    use_zipchunks: values.useZipChunks,
                    frame_filter: values.frameFilter,
                    user_flag_enabled: values.userFlagEnabled,
                    rejection_flag_enabled: values.rejectionFlagEnabled,
                    rotation: values.rotation,
                    translation: values.translation,
                },
            },
        };
        dispatch(createProjectDetails(projectData));
    };

    if (projectFetching) {
        return <Spin size='large' className='ltts-spinner' />;
    }

    const validateLabels = (): boolean => {
        const { labels } = localState;
        return !!labels.length;
    };

    const handleSubmitClick = (): void => {
        if (!validateLabels()) {
            notification.error({
                message: 'Could not add Project details',
                description: 'A project must contain at least one label',
                className: 'ltts-notification-create-task-fail',
            });
            return;
        }
        if (advancedConfigurationComponent.current) {
            advancedConfigurationComponent.current?.submit()
                .then(() => {});
        } else {
            const { labels } = localState;
            let projectData: Record<string, any> = {};

            projectData = {
                id: newProjectId?newProjectId:idparams,
                project_config: {
                    basic_configuration: {
                        labels,
                    },
                    //added below object to send edit scenario values
                    advanced_configuration: id? {
                        datachunk_size: newProjectDetails?.advanced_configuration?.datachunk_size,
                        frame_filter: newProjectDetails?.advanced_configuration?.frame_filter,
                        image_quality: newProjectDetails?.advanced_configuration?.image_quality,
                        overlap_size: newProjectDetails?.advanced_configuration?.overlap_size,
                        segment_size: newProjectDetails?.advanced_configuration?.segment_size,
                        sorting_method: newProjectDetails?.advanced_configuration?.sorting_method,
                        sequenceMethod: newProjectDetails?.advanced_configuration?.sequenceMethod,
                        start_frame: newProjectDetails?.advanced_configuration?.start_frame,
                        stop_frame: newProjectDetails?.advanced_configuration?.stop_frame,
                        use_cache: newProjectDetails?.advanced_configuration?.use_cache,
                        use_zipchunks: newProjectDetails?.advanced_configuration?.use_zipchunks,
                        user_flag_enabled: newProjectDetails?.advanced_configuration?.user_flag_enabled,
                        rejection_flag_enabled: newProjectDetails?.advanced_configuration?.rejection_flag_enabled,
                        rotation:  newProjectDetails?.advanced_configuration?.rotation,
                        translation:  newProjectDetails?.advanced_configuration?.translation,

                    } :
                    {
                        image_quality: initialValues.imageQuality,
                        sorting_method: initialValues.sortingMethod,
                        sequenceMethod: initialValues.sequenceMethod,
                        use_cache: initialValues.useCache,
                        use_zipchunks: initialValues.useZipChunks,
                        user_flag_enabled: initialValues.userFlagEnabled,
                        rejection_flag_enabled: initialValues.rejectionFlagEnabled,
                        rotation: initialValues.rotation,
                        translation: initialValues.translation,
                    }
                },
            };

            dispatch(createProjectDetails(projectData));
        }
    };

    const handlePrevClick = () => {
        dispatch(clickPrev());
    };

    const renderLabelsBlock = (): JSX.Element => {
        let { labels } = localState;

        return (
            <Col span={24}>
                <LabelsEditor
                    // labels={labelsPrev?labelsPrev:labels}// commented to add edit scenario values
                    labels = {labelsEdit?labelsEdit:labelsPrev?labelsPrev:labels}
                    onSubmit={(newLabels): void => {
                        setLocalState((prevState) => ({
                            ...prevState,
                            labels: [...newLabels],
                        }));
                    }}
                />
            </Col>
        );
    };

    const renderAdvancedBlock = (): JSX.Element => {
        const {editInitialValues} = props;
       // const  prevInitialvalue  = advancedPrev?advanced_previous:editInitialValues; //commented to add edit scenario values
        const  prevInitialvalue  = advancedEdit?advanced_edit:advancedPrev?advanced_previous:editInitialValues;
        return (
            <Col span={24}>
                <Collapse>
                    <Collapse.Panel key='1' header={<Text className='ltts-title'>Advanced configuration</Text>}>
                        <AdvancedConfigurationForm
                            dumpers={dumpers}
                            installedGit={installedGit}
                            activeFileManagerTab='local'
                            ref={advancedConfigurationComponent}
                            onSubmit={handleSubmitAdvancedConfiguration}
                            editInitialValues={prevInitialvalue}
                        />
                    </Collapse.Panel>
                </Collapse>
            </Col>
        )};

    return (
        <>
        <Row className='ltts-create-task-content project-details project-config-page'>
            <Col className='project-detail-inner'>
                <div className='project-nav-link'><Link to="/projects"  > <span><LeftOutlined /></span> Back to projects</Link></div>
                <Collapse className='collapse-container' defaultActiveKey={1}>
                    <Panel header='Project Configuration' key='1' showArrow className='proj-conf-header'>
                        <Row className='inner-section label-inner-section'>
                            <span className='LabelText'><b>Labels :</b></span>
                            {renderLabelsBlock()}
                        </Row>
                        <Row className='inner-section advanced-inner'>
                            {renderAdvancedBlock()}
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>
        <div className='next-button-row-proj-config'>
            <div className='project-left-buttons'>
            <Button type='primary' className='project-config-save-draft' onClick={handlePrevClick}>
                        Save as Draft
                    </Button>
                    </div>
                    <div className='project-right-buttons'>
            <Button type='primary' className='project-config-prev' onClick={handlePrevClick}>
                        Back
                    </Button>
            <Button type='primary' className='project-config-next' onClick={handleSubmitClick}>
                <b>Next</b>
            </Button>
            </div>
        </div>
     </>
    );
}
