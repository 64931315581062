// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT
import React from 'react';
import './dashboard.scss';
import { Layout } from 'antd';
import ProjectsPageComponent from './projects-pages/projects-main';

function Dashboard(): JSX.Element {
    return (
            <Layout>
                <ProjectsPageComponent />
            </Layout>
    );
}

export default Dashboard;
