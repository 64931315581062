// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { CombinedState } from 'reducers/interfaces';
import AppsstageListComponent from './apps-stage-lists';
import { activateIframe, fetchProjectApps, fetchWorkflowMapping, resetState } from 'actions/apps-stages-actions';
import { notification } from 'antd';
import EmptyListComponent from '../empty-list';
import './apps-styles.scss';
import Spin from 'antd/lib/spin';
function AppsPageComponent(): JSX.Element {
    const ANNOTATION_UI_URL = process.env.REACT_APP_ANNOTATION_UI_URL;
    const {
        projectMetaData,
        assignedProjectApps,
        isWorkFlowSubmitted,
        appProjectId,
        isIframeOpen,
        workflowMessage,
        closeMode,
    } = useSelector((state: CombinedState) => state.appsStages);
    const appsFetching = useSelector((state: CombinedState) => state.appsStages.fetching);
    const { search } = useLocation();
    const dispatch = useDispatch();
    // const anySearchQuery = !!Array.from(new URLSearchParams(search).keys()).filter((value) => value !== 'page').length;

    useEffect(() => {
        dispatch(resetState());
        dispatch(fetchProjectApps());
        dispatch(activateIframe(false));
    }, []);

    useEffect(() => {
        let iframe = document.createElement('iframe');
        let wrapper = document.getElementById('previewCommentWrapper');
        if (isIframeOpen && projectMetaData) {
            const {
                project_meta_data: { task_id, job_id },
                token,
                app_config,
                rotation,
                translation,
            } = projectMetaData;
            let annotationDetails = {
                sequenceDataPresent: app_config?.sequenceDataPresent,//giti
                annot: app_config?.levels?.shapes,
                pixel: app_config?.levels?.pixel,
                token: token,
                cropping: app_config?.levels?.cropping,
                rotation:[rotation],
                translation:[translation],
            };
            let appValues = JSON.stringify(annotationDetails);
            iframe.id = 'iframeId';
            iframe.src = `${ANNOTATION_UI_URL}/tasks/${task_id}/jobs/${job_id}?auth=${appValues}`;
            iframe.setAttribute('allowFullScreen', '');
            // iframe.style.position = 'absolute';
            iframe.style.height = '100%';
            iframe.style.width = '100%';
            iframe.style.zIndex = '999';
            iframe.style.top = '0';
            iframe.style.border = '0';
            iframe.style.backgroundColor = 'white';

            wrapper.appendChild(iframe);

            setTimeout(() => {
                const iframe = document.getElementById('iframeId');
                const iWindow = iframe.contentWindow;
                iWindow.postMessage('hideHeader', ANNOTATION_UI_URL);
            }, 6000);
        }
    }, [isIframeOpen]);

    useEffect(() => {
        if (workflowMessage) {
            notification.info({
                message: 'No Jobs/Tasks are available',
            });
        }
    }, [projectMetaData]);

    useEffect(() => {
        if (isWorkFlowSubmitted) {
            const {
                payload: { app_id, project_app_id },
            } = appProjectId;
            if (closeMode) {
                dispatch(activateIframe(false));
            } else {
                dispatch(fetchWorkflowMapping({ app_id, project_app_id }));
            }
            notification.success({
                message: 'Annotation submitted successfully',
            });
        }
    }, [isWorkFlowSubmitted]);

    const showSpinnerContent = () => {
        return <div id='previewCommentWrapper'></div>;
    };
    if (appsFetching) {
        return <Spin size='large' className='ltts-spinner' />;
    }
    return (
        <div className='content-page'>
            {isIframeOpen ? (
                showSpinnerContent()
            ) : assignedProjectApps.length > 0 ? (
                <>
                    <AppsstageListComponent />
                </>
            ) : (
                <EmptyListComponent notFound={false} title='No apps assigned yet ...' component='apps' />
            )}
            {/* </>
            } */}
        </div>
    );
}

export default React.memo(AppsPageComponent);
