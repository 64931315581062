// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT
import React, { RefObject, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd/lib/grid';
import { Button, Collapse, DatePicker, Form, Input, notification, Radio, Typography } from 'antd';
import './project-details-styles.scss';
import { createProjectPreview } from 'actions/projects-mgnt-actions';
import { CombinedState } from 'reducers/interfaces';
import ConstructorViewerItem from '../../labels-editor/constructor-viewer-item';
import  './project-preview.scss';
import { useHistory } from 'react-router';
import moment from 'moment';
import Spin from 'antd/lib/spin';
import TextArea from 'antd/lib/input/TextArea';
import Icon from '@ant-design/icons';
import { AudioIcon, ImageIcon, LidarIcon, VideoIcon } from 'icons';
import AdvancedConfigurationForm from 'components/create-task-page/advanced-configuration-form';
import Text from 'antd/lib/typography/Text';

const { Title } = Typography;

interface Props {
    labelNames?: string[];
    installedGit: boolean;
    dumpers:[]
    formRef: any;
    formvalues:any
}

export default function ProjectPreview(props: Props): JSX.Element {
    const newProjectId = useSelector((state: CombinedState) => state.projects.activities.creates.id);
    const previewState = useSelector((state: CombinedState) => state.projects.activities.creates);
    const previewState_name = useSelector((state: CombinedState) => state.projects.activities.creates.name);
    const previewState_description = useSelector((state: CombinedState) => state.projects.activities.creates.description);
    const previewState_date = useSelector((state: CombinedState) => state.projects.activities.creates.start_date);
    const dateFormatted = moment(previewState_date);

    let previewState_type = useSelector((state: CombinedState) => state.projects.activities.creates.project_type);
    const labels = useSelector((state: CombinedState) => state.projects.activities.creates.project_config.basic_configuration.labels);
    const dispatch = useDispatch();
    const history = useHistory();
    const status = previewState.project_creation_status === 'completed';
    const projectFetching = useSelector((state: CombinedState) => state.projects.fetching);
    const disabled = true;
    const dateFormat = 'YYYY/MM/DD';
    let projectList: any = [];
    projectList = useSelector((state: CombinedState) => state.projects.project_list);
    const { installedGit, dumpers } = props;
    const advancedConfigurationComponent: RefObject<AdvancedConfigurationForm> = React.createRef<AdvancedConfigurationForm>();
    let  advancedFormValues = {
        dataChunkSize :previewState.project_config.advanced_configuration?.datachunk_size,
        frameFilter:previewState.project_config.advanced_configuration?.frame_filter,
        imageQuality:previewState.project_config.advanced_configuration?.image_quality,
        overlapSize:previewState.project_config.advanced_configuration?.overlap_size,
        segmentSize:previewState.project_config.advanced_configuration?.segment_size,
        sortingMethod:previewState.project_config.advanced_configuration?.sorting_method,
        startFrame:previewState.project_config.advanced_configuration?.start_frame,
        stopFrame:previewState.project_config.advanced_configuration?.stop_frame,
        useCache:previewState.project_config.advanced_configuration?.use_cache,
        useZipChunks:previewState.project_config.advanced_configuration?.use_zipchunks,
        userFlagEnabled:previewState.project_config.advanced_configuration?.user_flag_enabled,
        rejectionFlagEnabled:previewState.project_config.advanced_configuration?.rejection_flag_enabled,
    }

    useEffect(() => {
        if(status){
            // notification.success({
            //     message: 'The project has been created sucessfully',
            //     className: 'ltts-notification-create-project-success',
            // });
            history.push(`/projects`);
        }
     },[status])

     if (projectFetching) {
        return <Spin size='large' className='ltts-spinner' />;
    }

    const handleSubmitClick = (): void => {
        let projectData: Record<string, any> = {};
        projectData = {
            id: newProjectId,
            project_creation_status: 'completed'
        };
        dispatch(createProjectPreview(projectData))
    };
    const handlePrevClick = () => {
        return false
    };
    // const handleDelete = () => {
    //    return false;
    // };
    const renderLabelsBlock = (): JSX.Element => {
        const list = [];
        for (const label of labels) {
            list.push(
                <ConstructorViewerItem
                    onUpdate={handlePrevClick}
                    //onDelete={handleDelete}
                    label={label}
                    key={label.id}
                    color={label.color}
                />,
            );
        }
        return (
            <div>{list}</div>
        );
    };

    const renderAdvancedBlock = (): JSX.Element => (
        <Col span={24}>
            <Collapse defaultActiveKey={['1']}>
                <Collapse.Panel key='1' header={<Text className='ltts-title'>Advanced configuration</Text>}>
                    <AdvancedConfigurationForm
                        dumpers={dumpers}
                        installedGit={installedGit}
                        activeFileManagerTab='local'
                        ref={advancedConfigurationComponent}
                        onSubmit={handlePrevClick}
                        editInitialValues={advancedFormValues}
                    />
                </Collapse.Panel>
            </Collapse>
        </Col>
    );
    return (
        <>
            <Row className='preview-comp-section'>
                <Col span={2} />
                <Col span={20} className='project-content'>
                    <Title level={5} className='project-title'>
                        {'Preview '}
                    </Title>

                    <Col span={24}>
                        <Form
                            name='basic'
                            labelCol={{
                                span: 6,
                            }}
                            wrapperCol={{
                                span: 20,
                            }}
                            initialValues={{

                                name: previewState_name,
                                Description:previewState_description,
                                StartDate: dateFormatted,
                                Project_type: previewState_type
                            }}
                            autoComplete='off'
                            className='project-item'
                        >
                            <Form.Item
                                name='name'
                                label='Project Name'
                                labelAlign='left'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please, specify a Project Name',
                                    },
                                ]}
                            >
                                <Input disabled={disabled} placeholder='Project Name' className='project-list' />
                            </Form.Item>

                            <Form.Item
                                name='Description'
                                label='Description'
                                labelAlign='left'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please, specify a Description',
                                    },
                                ]}
                            >
                                <TextArea disabled={disabled} placeholder='Project Description' allowClear className='project-list' />
                            </Form.Item>
                            <Form.Item
                                name='StartDate'
                                label='Start Date'
                                labelAlign='left'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please, specify a Start Date',
                                    },
                                ]}
                            >
                                <DatePicker
                                    size='large'
                                    placeholder='Start Date'
                                    format={dateFormat}
                                    disabled={disabled}
                                    disabledDate={(current) =>
                                        moment().add(-1, 'days') >= current || moment().add(1, 'month') <= current
                                    }
                                    className='project-list'
                                />
                            </Form.Item>
                            <Form.Item
                                name='Project_type'
                                label='Project Type'
                                labelAlign='left'
                                rules={[{ required: true, message: 'Please select Project type!' }]}
                            >
                                <Radio.Group disabled={disabled}>
                                    {projectList?.map((val: any) => {
                                        if (val.id == 1)
                                            return (
                                                <>
                                                    <Radio key={val.id} value={val.id}>
                                                        <Icon component={ImageIcon} className='imageIcon-align' />
                                                        {val.project_type}
                                                    </Radio>
                                                </>
                                            );
                                        else if (val.id == 2)
                                            return (
                                                <Radio key={val.id} value={val.id}>
                                                    <Icon component={VideoIcon} className='imageIcon-align' />{' '}
                                                    {val.project_type}
                                                </Radio>
                                            );
                                        else if (val.id == 3)
                                            return (
                                                <Radio key={val.id} value={val.id}>
                                                    <Icon component={AudioIcon} className='imageIcon-align2' />{' '}
                                                    {val.project_type}
                                                </Radio>
                                            );
                                        else if (val.id == 4)
                                            return (
                                                <Radio key={val.id} value={val.id}>
                                                    <Icon component={LidarIcon} className='imageIcon-align2' />{' '}
                                                    {val.project_type}
                                                </Radio>
                                            );
                                    })}
                                </Radio.Group>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Row>
                        <Col span={20}>
                            <span className='label-label'>Label :</span>
                        </Col>
                    </Row>
                    <Row className='preview-label-section'>
                        <Col span={20}>
                            <span className='preview-part'>{renderLabelsBlock()}</span>
                        </Col>
                    </Row>
                    {previewState.project_config.advanced_configuration &&
                    <Row className='inner-section preview-advanced-section'>
                        {renderAdvancedBlock() }
                    </Row>
                    }
                </Col>
            </Row>
            <div className='next-button-row-proj-config'>
                <Button type='primary' className='project-config-save-draft' >
                    Save as Draft
                </Button>
                <div>
                <Button type='primary' className='project-config-prev' onClick={handlePrevClick}>
                    Previous
                </Button>
                <Button type='primary' className='project-config-next' onClick={handleSubmitClick}>
                    <b>Submit</b>
                </Button>
                </div>
            </div>
        </>

    );
}
