// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { useRef } from 'react';
import { Button, Typography, Space } from 'antd';
import { LeftOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import { createOrganization } from 'actions/organization-mgnt-actions';
import { CombinedState } from 'reducers/interfaces';
import CreateOrganizationFormPage from './create-organizations-form';

const { Text } = Typography;

function CreateOrganizationPageComponent(): JSX.Element {
    const history = useHistory();
    const creating = useSelector((state: CombinedState) => state.organizations.creating);
    const dispatch = useDispatch();
    const nameFormRef = useRef<FormInstance>(null);
    const onSubmit = async (): Promise<void> => {
        let orgData: Record<string, any> = {};
        if (nameFormRef.current) {
            const basicValues = await nameFormRef.current.validateFields();
            orgData = {
                ...orgData,
                slug: basicValues.slug,
                name: basicValues.name,
                description: basicValues.description,
                email: basicValues.email,
                phoneNumber: basicValues.phoneNumber,
                location: basicValues.location,
            };
        }
        if (!orgData.name && !orgData.description) return;
        dispatch(createOrganization(orgData));
    };

    return (
        <>
            <div className='org-layout'>
                <Text className='org-text'>Organization</Text>
                <div className='org-content'>
                    <div className='org-members-layout'>
                        <Button className='org-members-btn'>
                            <PlusOutlined />
                            Invite Members
                        </Button>
                    </div>
                    <div className='org-form'>
                        <Button className='organization-form-back-button' onClick={() => history.push('/projects')}>
                            <LeftOutlined /> Back to Organization
                        </Button>

                        <div className='org-form-layout'>
                            <CreateOrganizationFormPage formRef={nameFormRef} />
                        </div>
                    </div>
                    <Space className='org-form-buttons'>
                        <Button onClick={() => history.push('/projects')} className='org-form-cancel-button'>
                            Cancel
                        </Button>
                        <Button
                            loading={creating}
                            disabled={creating}
                            htmlType='submit'
                            type='primary'
                            className='org-submit-button'
                            onClick={onSubmit}
                        >
                            Submit
                        </Button>
                    </Space>
                </div>
            </div>
        </>
    );
}

export default React.memo(CreateOrganizationPageComponent);
