// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT
import { ProjectsQuery, TasksQuery, CombinedState } from 'reducers/interfaces';
export enum ProjectsSagaActionTypes {
    // Code added by raju
    GET_PROJECTS = 'GET_PROJECTS',
    GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS',
    GET_PROJECTS_FAILED = 'GET_PROJECTS_FAILED',
    UPDATE_PROJECTS_GETTING_QUERY = 'UPDATE_PROJECTS_GETTING_QUERY',
    // code end above by raju
    GET_PROJECTS_TYPE = 'GET_PROJECTS_TYPE',
    GET_PROJECTS_TYPE_SUCCESS = ' GET_PROJECTS_TYPE_SUCCESS',
    GET_PROJECTS_TYPE_FAILED = 'GET_PROJECTS_TYPE_FAILED',
    CREATE_PROJECT = 'CREATE_PROJECT',
    CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS',
    CREATE_PROJECT_FAILED = 'CREATE_PROJECT_FAILED',
    CREATE_PROJECT_DETAILS = 'CREATE_PROJECT_DETAILS',
    EDIT_PROJECT_DETAILS = 'EDIT_PROJECT_DETAILS',
    CREATE_PROJECT_PREVIEW = 'CREATE_PROJECT_PREVIEW',
    CREATE_PROJECT_PREVIEW_SUCCESS = 'CREATE_PROJECT_PREVIEW_SUCCESS',
    CREATE_PROJECT_DETAILS_SUCCESS = 'CREATE_PROJECT_DETAILS_SUCCESS',
    CREATE_PROJECT_DETAILS_FAILED = 'CREATE_PROJECT_DETAILS_FAILED',
    EDIT_PROJECT_DETAILS_SUCCESS = 'EDIT_PROJECT_DETAILS_SUCCESS',
    EDIT_PROJECT_DETAILS_FAILED = 'EDIT_PROJECT_DETAILS_FAILED',
    CREATE_PROJECT_PREV = 'CREATE_PROJECT_PREV',
    CREATE_UPLOAD_FILES = 'CREATE_UPLOAD_FILES',
    CREATE_UPLOAD_FILES_SUCCESS = 'CREATE_UPLOAD_FILES_SUCCESS',
    CREATE_UPLOAD_FILES_FAILED = 'CREATE_UPLOAD_FILES_FAILED',
    GRID_LIST_VIEW = 'GRID_LIST_VIEW',
    GENERATE_MAPPING_TOKEN = 'GENERATE_MAPPING_TOKEN ',
    GENERATE_MAPPING_TOKEN_SUCCESS = 'GENERATE_MAPPING_TOKEN_SUCCESS',
    GENERATE_MAPPING_TOKEN_FAILED = 'GENERATE_MAPPING_TOKEN_FAILED',

    DEFAULT_STEPPER_COUNT = 'DEFAULT_STEPPER_COUNT',
    EDIT_MODE = 'EDIT_MODE',
    INCREASE_STEPPER_COUNT = 'INCREASE_STEPPER_COUNT',
    DECREASE_STEPPER_COUNT = 'DECREASE_STEPPER_COUNT',

    GET_PROJECT_EXPORT_DATA = "GET_PROJECT_EXPORT_DATA",
    GET_PROJECT_DATA_BY_ID = "GET_PROJECT_DATA_BY_ID",
    GET_PROJECT_DATA_SUCCESS = "GET_PROJECT_DATA_SUCCESS",
    GET_PROJECT_DATA_FAILED = "GET_PROJECT_DATA_FAILED",
    GET_PROJECT_EXPORT_DATA_SUCCESS = "GET_PROJECT_EXPORT_DATA_SUCCESS",
    GET_PROJECT_EXPORT_DATA_FAILED = "GET_PROJECT_EXPORT_DATA_FAILED",
    GET_PROJECT_EXPORT_DATA_FORMAT = "GET_PROJECT_EXPORT_DATA_FORMAT",
    GET_PROJECT_EXPORT_DATA_FORMAT_SUCCESS = "GET_PROJECT_EXPORT_DATA_FORMAT_SUCCESS",
    GET_PROJECT_EXPORT_DATA_FORMAT_FAILED = "GET_PROJECT_EXPORT_DATA_FORMAT_FAILED",

    DOWNLOAD_EXPORT_DATA = 'DOWNLOAD_EXPORT_DATA',
    DOWNLOAD_EXPORT_DATA_SUCCESS = 'DOWNLOAD_EXPORT_DATA_SUCCESS',
    DOWNLOAD_EXPORT_DATA_FAILED = 'DOWNLOAD_EXPORT_DATA_FAILED',

    GET_INITIALISED_STATE = 'GET_INITIALISED_STATE',

}
export function getProjectType() {
    return {
        type: ProjectsSagaActionTypes.GET_PROJECTS_TYPE,
    };
}

export function createProjects(data: any) {
    return {
        type: ProjectsSagaActionTypes.CREATE_PROJECT,
        payload: data,
    };
}
export function createProjectDetails(data: any) {
    return {
        type: ProjectsSagaActionTypes.CREATE_PROJECT_DETAILS,
        payload: data,
    };
}
export function editProjectDetails(data: any) {
    return {
        type: ProjectsSagaActionTypes.EDIT_PROJECT_DETAILS,
        payload: data,
    };
}
export function createProjectPreview(data: any) {
    return {
        type: ProjectsSagaActionTypes.CREATE_PROJECT_PREVIEW,
        payload: data,
    };
}

export function createProjectPrev() {
    return {
        type: ProjectsSagaActionTypes.CREATE_PROJECT_PREV,
        payload: '',
    };
}
export function createUploadFiles(projectValues: any) {
    return {
        type: ProjectsSagaActionTypes.CREATE_UPLOAD_FILES,
        payload: projectValues
    };
}

export function generateMappingToken(id: any) {
    return {
        type: ProjectsSagaActionTypes.GENERATE_MAPPING_TOKEN,
        payload: id
    };
}

export function getProjectsAsync() {
    return {
        type: ProjectsSagaActionTypes.GET_PROJECTS,
        // payload: query

    }
}
export function gridandlistview(query: any) {
    return {
        type: ProjectsSagaActionTypes.GRID_LIST_VIEW,
        payload: query

    }

}

export function initialiaseStepperCount() {
    return {
        type: ProjectsSagaActionTypes.DEFAULT_STEPPER_COUNT,
    };
}

export function editMode() {
    return {
        type: ProjectsSagaActionTypes.EDIT_MODE,
    };
}

export function clickNext() {
    return {
        type: ProjectsSagaActionTypes.INCREASE_STEPPER_COUNT,
    };
}

export function clickPrev() {
    return {
        type: ProjectsSagaActionTypes.DECREASE_STEPPER_COUNT,
    };
}

export function getInitialState() {
    return {
        type: ProjectsSagaActionTypes.GET_INITIALISED_STATE,
    };
}

export function getProjectData(project_id: any) {
    return {
        type: ProjectsSagaActionTypes.GET_PROJECT_EXPORT_DATA,
        payload: project_id,
    };
}

export function getProjectDataById(project_id: any) {
    return {
        type: ProjectsSagaActionTypes.GET_PROJECT_DATA_BY_ID,
        payload: project_id,
    };
}

export function getProjectExportFormat() {
    return {
        type: ProjectsSagaActionTypes.GET_PROJECT_EXPORT_DATA_FORMAT,
    };
}

export function downloadExportData(taskId: any, format: any, annotation: any) {
    return {
        type: ProjectsSagaActionTypes.DOWNLOAD_EXPORT_DATA,
        payload: { taskId, format, annotation }
    };
}
