// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT
import './styles.scss';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import Layout from 'antd/lib/layout';
import LoginForm, { LoginData } from './login-form';
import FooterComponent from '../dashboard/footer';
import { Col, Row } from 'antd';
import loginImage from '../../assets/login-icon.png';

interface LoginPageComponentProps {
    fetching: boolean;
    renderResetPassword: boolean;
    onLogin: (username: string, password: string) => void;
}
function LoginPageComponent(props: LoginPageComponentProps & RouteComponentProps): JSX.Element {
    const { Content } = Layout;
    const { fetching, onLogin, renderResetPassword } = props;
    const sizes = {
        xs: { span: 14 },
        sm: { span: 14 },
        md: { span: 12 },
        lg: { span: 12 },
        xl: { span: 12 },
    };
    return (
        <Layout>
            <Content>
                <Row align='middle' className='login-page'>
                    <Col {...sizes}>
                        <div className='icon-wrapper'>
                            <img  className='login-image' src={loginImage} />
                        </div>
                    </Col>
                    <Col align='center' {...sizes}>
                        <LoginForm
                            fetching={fetching}
                            renderResetPassword={renderResetPassword}
                            onSubmit={(loginData: LoginData): void => {
                                onLogin(loginData.username, loginData.password);
                            }}
                        />
                        <FooterComponent />
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}

export default withRouter(LoginPageComponent);
