// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MI
import { all, put, takeLatest } from 'redux-saga/effects';
import { ProjectsSagaActionTypes } from '../actions/projects-mgnt-actions';
import getCore from '../ltts-core-wrapper';
import { ActionUnion, createAction } from '../utils/redux';
import { ProjectsQuery, TasksQuery } from 'reducers/interfaces';
import moment from 'moment';
const ltts = getCore();

export const projectsSagaActions = {
    getProjects: () => createAction(ProjectsSagaActionTypes.GET_PROJECTS),
    getProjectsSuccess: (array: any[], previews: string[], count: number) =>
        createAction(ProjectsSagaActionTypes.GET_PROJECTS_SUCCESS, { array, previews, count }),
    getProjectsFailed: (error: any) => createAction(ProjectsSagaActionTypes.GET_PROJECTS_FAILED, { error }),
    updateProjectsGettingQuery: (query: Partial<ProjectsQuery>, tasksQuery: Partial<TasksQuery> = {}) =>
        createAction(ProjectsSagaActionTypes.UPDATE_PROJECTS_GETTING_QUERY, { query, tasksQuery }),
    getProjectType: () => createAction(ProjectsSagaActionTypes.GET_PROJECTS_TYPE),
    getProjectTypeSuccess: (project_list: any[]) =>
        createAction(ProjectsSagaActionTypes.GET_PROJECTS_TYPE_SUCCESS, { project_list }),
    getProjectTypeFailed: (error: any) => createAction(ProjectsSagaActionTypes.GET_PROJECTS_TYPE_FAILED, { error }),
    createProjects: () => createAction(ProjectsSagaActionTypes.CREATE_PROJECT),
    createProjectsSuccess: (projectId: any[], project_type: any[], name: any[], description: any[], start_date: any[]) =>
        createAction(ProjectsSagaActionTypes.CREATE_PROJECT_SUCCESS, { projectId, project_type, name, description, start_date }),
    createProjectsFailed: (error: any) => createAction(ProjectsSagaActionTypes.CREATE_PROJECT_FAILED, { error }),
    createProjectDetails: () => createAction(ProjectsSagaActionTypes.CREATE_PROJECT_DETAILS),
    editProjectDetails: () => createAction(ProjectsSagaActionTypes.EDIT_PROJECT_DETAILS),
    createProjectPreview: () => createAction(ProjectsSagaActionTypes.CREATE_PROJECT_PREVIEW),
    createProjectsPreviewSuccess: (
        projectId: any[],
        project_type: any[],
        project_config: any[],
        name: any[],
        segment_size: any[],
        project_creation_status: any[]

    ) =>
        createAction(ProjectsSagaActionTypes.CREATE_PROJECT_PREVIEW_SUCCESS, {
            projectId,
            project_config,
            name,
            segment_size,
            project_type,
            project_creation_status
        }),
    createProjectsDetailsSuccess: (
        projectId: any[],
        project_type: any[],
        project_config: any[],
        name: any[],
        segment_size: any[],
        description: any[]
    ) =>
        createAction(ProjectsSagaActionTypes.CREATE_PROJECT_DETAILS_SUCCESS, {
            projectId,
            project_config,
            name,
            segment_size,
            project_type,
            description,
        }),
    editProjectsDetailsSuccess: (
        projectId: any[],
        project_type: any[],
        project_config: any[],
        name: any[],
        segment_size: any[],
        description: any[]
    ) =>
        createAction(ProjectsSagaActionTypes.EDIT_PROJECT_DETAILS_SUCCESS, {
            projectId,
            project_config,
            name,
            segment_size,
            project_type,
            description,
        }),
    createProjectsDetailsFailed: (error: any) =>
        createAction(ProjectsSagaActionTypes.CREATE_PROJECT_DETAILS_FAILED, { error }),
    editProjectsDetailsFailed: (error: any) =>
        createAction(ProjectsSagaActionTypes.EDIT_PROJECT_DETAILS_FAILED, { error }),
    createProjectPrev: () => createAction(ProjectsSagaActionTypes.CREATE_PROJECT_PREV),
    createUploadFiles: () => createAction(ProjectsSagaActionTypes.CREATE_UPLOAD_FILES),
    createUploadFilesSuccess: () => createAction(ProjectsSagaActionTypes.CREATE_UPLOAD_FILES_SUCCESS),
    createUploadFilesFailed: (error: any) => createAction(ProjectsSagaActionTypes.CREATE_UPLOAD_FILES_FAILED, { error }),
    generateMappingToken: (p2p_mapping: any[]) =>
        createAction(ProjectsSagaActionTypes.GENERATE_MAPPING_TOKEN, { p2p_mapping }),
    generateMappingTokenSuccess: (projectValues: any[]) =>
        createAction(ProjectsSagaActionTypes.GENERATE_MAPPING_TOKEN_SUCCESS, { projectValues }),
    generateMappingTokenFailed: (error: any) => createAction(ProjectsSagaActionTypes.GENERATE_MAPPING_TOKEN_FAILED, { error }),
};


export type ProjectsSagaActions = ActionUnion<typeof projectsSagaActions>;

function* getProjectType(): any {
    let result = null;
    try {
        result = yield ltts.projects.getType();
        yield put(projectsSagaActions.getProjectTypeSuccess(result));
    } catch (error) {
        yield put(projectsSagaActions.getProjectTypeFailed(error));
    }
}

function* createProjects(data: any): any {
    const projectInstance = new ltts.classes.Project(data.payload);
    yield projectsSagaActions.createProjects();
    let result = null;

    try {
        result = yield projectInstance.save();
        yield put(projectsSagaActions.createProjectsSuccess(result.id, result.project_type, result.name, result.description, result.start_date));
    } catch (error) {
        yield put(projectsSagaActions.createProjectsFailed(error));
    }
}

function* createProjectDetails(data: any): any {
    let result = null;
    const projectInstance = new ltts.classes.Project(data.payload);
    yield projectsSagaActions.createProjectDetails();
    try {
        result = yield projectInstance.update();
        yield put(
            projectsSagaActions.createProjectsDetailsSuccess(
                result.id,
                result.project_type,
                result.project_config,
                result.name,
                result.segment_size,
                result.description,
            ),
        );
    } catch (error) {
        yield put(projectsSagaActions.createProjectsDetailsFailed(error));
    }
}
function* editProjectDetails(data: any): any {
    let result = null;
    const projectInstance = new ltts.classes.Project(data.payload);
    yield projectsSagaActions.editProjectDetails();
    try {
        result = yield projectInstance.projectEdit();
        yield put(
            projectsSagaActions.editProjectsDetailsSuccess(
                result.id,
                result.project_type,
                result.project_config,
                result.name,
                result.segment_size,
                result.description,
            ),
        );
    } catch (error) {
        yield put(projectsSagaActions.editProjectsDetailsFailed(error));
    }
}

function* createProjectPreview(data: any): any {
    let result = null;

    const projectInstance = new ltts.classes.Project(data.payload);
    yield projectsSagaActions.createProjectPreview();
    try {
        result = yield projectInstance.projectPreview();
        yield put(
            projectsSagaActions.createProjectsPreviewSuccess(
                result.id,
                result.project_type,
                result.project_config,
                result.name,
                result.segment_size,
                result.project_creation_status
            ),
        );
    } catch (error) {
        yield put(projectsSagaActions.createProjectsDetailsFailed(error));
    }
}

function* createProjectPrev(): any {
    yield projectsSagaActions.createProjectPrev();
}

function* generateMappingToken(data: any): any {
    const projectInstance = yield ltts.projects.getToken(data.payload);
    yield (projectsSagaActions.generateMappingToken(projectInstance.projectValues));
    try {
        yield put(projectsSagaActions.generateMappingTokenSuccess(projectInstance));
    } catch (error) {
        yield put(projectsSagaActions.generateMappingTokenFailed(error));
    }
}

function* createUploadFiles(data: any): any {

    const description: any = {
        name: `${data.payload.name}_${moment()}`,
        image_quality: 70,
        use_zip_chunks: data.payload.advanced_config?.use_zipchunks,
        use_cache: data.payload.advanced_config?.use_cache,
        sorting_method: data.payload.advanced_config?.sorting_method,
    }
    if (data.payload.projectId) {
        description.projectId = data.payload.projectId;
    }
    if (data.payload.p2p_mapping) {
        description.p2p_mapping = data.payload.p2p_mapping;
    }

    if (data.payload.advanced_config?.segment_size) {
        description.segmentSize = data.payload.advanced_config.segment_size;
    }

    if (data.payload.advanced_config?.overlap_size) {
        description.overlap = data.payload.advanced_config.overlap_size;
    }
    if (data.payload.advanced_config?.start_frame) {
        description.start_frame = data.payload.advanced_config.start_frame;
    }
    if (data.payload.advanced_config?.stop_frame) {
        description.stop_frame = data.payload.advanced_config.stop_frame;
    }
    if (data.payload.advanced_config?.frame_filter) {
        description.frame_filter = data.payload.advanced_config.frame_filter;
    }
    if (data.payload.advanced_config?.image_quality) {
        description.image_quality = data.payload.advanced_config.image_quality;
    }
    if (data.payload.advanced_config?.datachunk_size) {
        description.data_chunk_size = data.payload.advanced_config.datachunk_size;
    }

    const taskInstance = new ltts.classes.Task(description);
    taskInstance.clientFiles = data.payload.fileList;
    taskInstance.serverFiles = data.payload.sharedList;

    try {
        // const savedTask = yield taskInstance.save(function*(status: string, progress: number) {
        //     yield put (createTaskUpdateStatus(status + (progress !== null ? ` ${Math.floor(progress * 100)}%` : '')));
        // });
        // yield put(projectsSagaActions.createUploadFilesSuccess(savedTask.id));
        const savedTask = yield taskInstance.save();
        yield put(projectsSagaActions.createUploadFilesSuccess());
    } catch (error) {
        yield put(projectsSagaActions.createUploadFilesFailed(error));
    }
};

// get projects api added by raju
function* getProjectsAsync(query: Partial<ProjectsQuery>, tasksQuery: Partial<TasksQuery> = {}): any {
    yield projectsSagaActions.getProjects();
    yield projectsSagaActions.updateProjectsGettingQuery(query, tasksQuery);

    // Clear query object from null fields

    const filteredQuery: Partial<ProjectsQuery> = {
        page: 1,
        ...query?.payload,
    };

    for (const key in filteredQuery) {
        if (filteredQuery[key] === null || typeof filteredQuery[key] === 'undefined') {
            delete filteredQuery[key];
        }
    }

    //     // Temporary hack to do not change UI currently for projects
    //     // Will be redesigned in a different PR
    const filter = {
        and: ['owner', 'assignee', 'name', 'status'].reduce<object[]>((acc, filterField) => {
            if (filterField in filteredQuery) {
                acc.push({ '==': [{ var: filterField }, filteredQuery[filterField]] });
                delete filteredQuery[filterField];
            }

            return acc;
        }, []),
    };

    if (filter.and.length) {
        filteredQuery.filter = JSON.stringify(filter);
    }

    let result = null;
    try {
        result = yield ltts.projects.get(filteredQuery);
    } catch (error) {
        yield put(projectsSagaActions.getProjectsFailed(error));
        return;
    }

    const array = Array.from(result);

    const previewPromises = array.map((project): string => (project as any).preview().catch(() => ''));
    yield put(projectsSagaActions.getProjectsSuccess(array, yield Promise.all(previewPromises), result.count));

}

function* getprojectData(data: any): any {
    let exportData = null;
    try {
        exportData = yield ltts.projects.getExportData(data.payload);
        yield put({ type: 'GET_PROJECT_EXPORT_DATA_SUCCESS', exportData });
    } catch (error) {
        yield put({ type: 'GET_PROJECT_EXPORT_DATA_FAILED', error });
    }
}

function* getProjectDataByIdEdit(data: any): any {
    let projectData = null;
     try {
         projectData = yield ltts.projects.getProjectData(data.payload);
         yield put({ type: 'GET_PROJECT_DATA_SUCCESS', projectData });
     } catch (error) {
         yield put({ type: 'GET_PROJECT_DATA_FAILED', error });
     }
 }

function* getProjectExportFormat(): any {
    let exportDataFormat = null;
    try {
        exportDataFormat = yield ltts.projects.getExportDataFormat();
        yield put({ type: 'GET_PROJECT_EXPORT_DATA_FORMAT_SUCCESS', exportDataFormat });
    } catch (error) {
        yield put({ type: 'GET_PROJECT_EXPORT_DATA_FORMAT_FAILED', error });
    }
}

function* downloadExportData(data: any): any {
    let exportData = null;
    try {
        exportData = yield ltts.projects.exportDataDownload(data.payload);
        const downloadAnchor = window.document.getElementById('downloadAnchor') as HTMLAnchorElement;
        downloadAnchor.href = exportData;
        downloadAnchor.click();
        yield put({ type: 'DOWNLOAD_EXPORT_DATA_SUCCESS', exportData });
    } catch (error) {
        yield put({ type: 'DOWNLOAD_EXPORT_DATA_FAILED', error });
    }
}


export function* projectWatcher() {
    yield all([
        takeLatest(ProjectsSagaActionTypes.GET_PROJECTS_TYPE, getProjectType),
        takeLatest(ProjectsSagaActionTypes.CREATE_PROJECT, createProjects),
        takeLatest(ProjectsSagaActionTypes.CREATE_PROJECT_DETAILS, createProjectDetails),
        takeLatest(ProjectsSagaActionTypes.EDIT_PROJECT_DETAILS, editProjectDetails),
        takeLatest(ProjectsSagaActionTypes.CREATE_PROJECT_PREVIEW, createProjectPreview),
        takeLatest(ProjectsSagaActionTypes.CREATE_PROJECT_PREV, createProjectPrev),
        takeLatest(ProjectsSagaActionTypes.CREATE_UPLOAD_FILES, createUploadFiles),
        takeLatest(ProjectsSagaActionTypes.GET_PROJECTS, getProjectsAsync),
        takeLatest(ProjectsSagaActionTypes.GENERATE_MAPPING_TOKEN, generateMappingToken),
        takeLatest(ProjectsSagaActionTypes.GET_PROJECT_EXPORT_DATA, getprojectData),
        takeLatest(ProjectsSagaActionTypes.GET_PROJECT_DATA_BY_ID, getProjectDataByIdEdit),
        takeLatest(ProjectsSagaActionTypes.GET_PROJECT_EXPORT_DATA_FORMAT, getProjectExportFormat),
        takeLatest(ProjectsSagaActionTypes.DOWNLOAD_EXPORT_DATA, downloadExportData),
    ])
}