// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Tabs } from 'antd';
import UserWiseReport from './user-wise-report';
import DayWiseReport from './day-wise-report';

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}
const { TabPane } = Tabs;

export default function ReportProjectComponent(props: Props): JSX.Element {
    return (
        <>
            <Tabs defaultActiveKey='1' className='report-project-tab'>
                <TabPane tab='User wise Report' key='User wise Report'>
                    <UserWiseReport/>
                </TabPane>
                <TabPane tab=' Day wise report' key=' Day wise report'>
                    <DayWiseReport/>
                </TabPane>
            </Tabs>
        </>
    );
}
