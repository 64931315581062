// Copyright (C) 2021-2022 LTTS
//
// SPDX-License-Identifier: MIT

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd/lib/grid';
import { CombinedState } from '../../../reducers/interfaces';
import AppsItemComponent from './apps-stage-item';
import Pagination from 'antd/lib/pagination';
import TopBarComponent from './apps-stages-top-bar';
import Icon from '@ant-design/icons';
import { ProjectCardIcon, ProjectListIcon } from 'icons';
import Text from 'antd/lib/typography/Text';
import { gridandlistview } from 'actions/projects-mgnt-actions';
import AppItemListViewComponent from './appstage-item-list-view';
export default function AppsstageListComponent(): JSX.Element {
    const projectApps = useSelector((state: CombinedState) => state.appsStages.assignedProjectApps);
    const [currentPage, setCurrentPage] = useState(1);
    const [appsPerPage] = useState(12);
    const indexOfLastApps = currentPage * appsPerPage;
    const indexOfFirstApps = indexOfLastApps - appsPerPage;
    const changePage = (pageNumber: any) => setCurrentPage(pageNumber);
    const searchKeys = ['app_alias_name', 'project_name', 'status', 'description'];
    const [filteredResults, setFilteredResults] = useState(projectApps);
    const currentProjectApps = filteredResults.slice(indexOfFirstApps, indexOfLastApps);
    const gridview = useSelector((state: CombinedState) => state.projects.gridView);
    let timer: any;
    const dispatch = useDispatch();

    function ViewChange(value:boolean) {
        dispatch(
          gridandlistview (
            value
         ),
      );
      }

    const searchApps = (e: any) => {
        const searchValue = e.target.value;
        if (searchValue !== '') {
            const filteredData: any = projectApps.filter((item) =>
                searchKeys.some((key) => item[key].toLowerCase().includes(searchValue.toLowerCase())),
            );
            setCurrentPage(1);
            setFilteredResults(filteredData);
        } else {
            setFilteredResults(projectApps);
        }

        if (e.type === 'click') {
            e.preventDefault();
            timer = setTimeout(() => {
                setFilteredResults(projectApps);
            }, 500);
        }
    };
    const dimensions = {
        md: 22,
        lg: 18,
        xxl: 16,
    };
    return (
        <>
            <TopBarComponent onSearchChange={searchApps} />
            <Row justify='start' align='middle' className='ltts-subheader'>
                <Col span={16}></Col>
                <Col>
                    <button
                        type='button'
                        className='grid-view-button'
                        title='Grid View'
                        onClick={() => ViewChange(true)}
                    >
                        <Icon component={ProjectCardIcon} />
                        <Text type='secondary' className='ltts-menu-dropdown-text'>
                            Card
                        </Text>
                    </button>
                    <button
                        type='button'
                        className='list-view-button'
                        title='List View'
                        onClick={() => ViewChange(false)}
                    >
                        <Icon component={ProjectListIcon} />
                        <Text type='secondary' className='ltts-menu-dropdown-text'>
                            List
                        </Text>
                    </button>
                </Col>
            </Row>
            <div className='bgbanner-content'>
            { gridview?
                <Row gutter={[8, 8]} style={{marginRight: '-4px', marginLeft: '-4px'}}>
                    {currentProjectApps.length > 0 && currentProjectApps ? (
                        currentProjectApps?.map(
                            (projectApp: any): JSX.Element => (
                                <Col className='gutter-row' key={projectApp.id} xs={24} lg={12} xl={8} xxl={6}>
                                    <AppsItemComponent projectInstance={projectApp} />
                                </Col>
                            ),
                        )
                    ) : (
                        <Col className='gutter-row' span={6}>
                            <p>No Apps Available!</p>
                        </Col>
                    )}
                </Row>:
                <Row gutter={[8, 8]} style={{marginRight: '-4px', marginLeft: '-4px'}}>
                        {currentProjectApps.length > 0 && currentProjectApps ? (
                            currentProjectApps?.map(
                                (projectApp: any): JSX.Element => (
                                    <Col key={projectApp.id} xs={24}>
                                    <AppItemListViewComponent key={projectApp.id}  projectInstance={projectApp}/>
                                    </Col>
                                ),
                            )
                        ) : (
                            <Col className='gutter-row' span={6}>
                                <p>No Apps Available!</p>
                            </Col>
                        )}
                </Row>
            }
            </div>
            {currentProjectApps.length > 0 && currentProjectApps && (
                <Row className='ltts-projects-list-pagination'>
                    <Col {...dimensions}>
                        <Pagination
                            onChange={changePage}
                            showSizeChanger={false}
                            total={filteredResults?.length}
                            pageSize={appsPerPage}
                            current={currentPage}
                            showQuickJumper
                        />
                    </Col>
                </Row>
            )}
        </>
    );
}
