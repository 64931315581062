// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT
import React, {useReducer} from 'react';
import Button from 'antd/lib/button';
import { useDispatch, useSelector } from 'react-redux';
import { createStages } from 'actions/apps-stages-actions';
import { CombinedState } from 'reducers/interfaces';

interface nodeData {
    app_alias_name:string,
    app_id:number,
    app_config:{},
    project_id:number,
    status:string,
    is_active:boolean,
    app_sequence_id: number
  };
interface Props {
    nodePayload: Array<nodeData>;
};

export default (props: Props): JSX.Element => {
    const dispatch = useDispatch();
    const apps = useSelector((state: CombinedState) => state.appsStages.apps);
    const isStagesCreated = useSelector((state: CombinedState) => state.appsStages.stagesCreated);

    const { nodePayload } = props;


    const onDragStart = (event:any, nodeType:any, appData:any) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.setData('application/appData', JSON.stringify(appData));
        event.dataTransfer.effectAllowed = 'move';
    };

    const createAppsNStages = () => {
        dispatch(
            createStages(nodePayload)
        );
    };
    return (
        <>
        {!isStagesCreated ?
        <>
        {apps.length > 0 ?
        <aside>
            <div className='appContainer'>
            <div className='description'>You can drag these APPS to the pane on the right</div>
            {apps.map(
                (app: any): JSX.Element => (
                    <div
                        className='dndnode'
                        onDragStart={(event) => onDragStart(event, 'default', app)}
                        key={app.id}
                        draggable
                    >
                        {app.app_name}
                    </div>
                ),
            )}
             <Button  htmlType='button' type='primary' className='project-config-prev' onClick={() => createAppsNStages()}>Create Stages</Button>
             </div>
             </aside> : <div>No APPS to display</div>} </>
             : '' }
        </>
    );
};
