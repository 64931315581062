// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import Spin from 'antd/lib/spin';
import { CombinedState, ProjectsQuery } from 'reducers/interfaces';
import ImportDatasetModal from 'components/import-dataset-modal/import-dataset-modal';
import EmptyListComponent from '../empty-list';
import ProjectListComponent from './project-list';
import { getProjectsAsync } from 'actions/projects-mgnt-actions';
import { activateIframe } from 'actions/apps-stages-actions';
import './styles.scss';

export default function ProjectsPageComponent(): JSX.Element {
    const { search } = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const projectFetching = useSelector((state: CombinedState) => state.projects.fetching);
    const projectsCount = useSelector((state: CombinedState) => state.projects.current.length);
    const gettingQuery = useSelector((state: CombinedState) => state.projects.gettingQuery);
    const isImporting = useSelector((state: CombinedState) => state.projects.restoring);
    const anySearchQuery = !!Array.from(new URLSearchParams(search).keys()).filter((value) => value !== 'page').length;
    // const getSearchParams = (): Partial<ProjectsQuery> => {
    //     const searchParams: Partial<ProjectsQuery> = {};
    //     for (const [param, value] of new URLSearchParams(search)) {
    //         searchParams[param] = ['page', 'id'].includes(param) ? Number.parseInt(value, 10) : value;
    //     }

    //     return searchParams;
    // };

    useEffect(() => {
        const searchParams = new URLSearchParams();
        for (const [name, value] of Object.entries(gettingQuery)) {
            if (value !== null && typeof value !== 'undefined') {
                searchParams.append(name, value.toString());
            }
        }
        history.push({
            pathname: '/projects',
            // search: `?${searchParams.toString()}`,
        });
    }, [gettingQuery]);

    useEffect(() => {
        if (isImporting === false) {
            dispatch(getProjectsAsync());
            dispatch(activateIframe(false));
        }
    }, [isImporting]);

    if (projectFetching) {
        return <Spin size='large' className='ltts-spinner' />;
    }

    return (
        <div className='content-page'>
            {projectsCount ? <ProjectListComponent /> : <EmptyListComponent notFound={anySearchQuery} title='No projects created yet ...' component='projects'/>}
            <ImportDatasetModal />
        </div>
    );
}
