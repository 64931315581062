// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Row, Col } from 'antd/lib/grid';
import Button from 'antd/lib/button';
// import SearchField from 'components/search-field/search-field';
import { CombinedState, ProjectsQuery } from 'reducers/interfaces';
import { getProjectsAsync } from 'actions/projects-actions';
import { initialiaseStepperCount } from 'actions/projects-mgnt-actions';
import SearchBar from '../custom-search';
interface Props {
    onSearchChange: (value: any) => void;
}
export default function TopBarComponent(props: Props): JSX.Element {
    const history = useHistory();
    const dispatch = useDispatch();
    const query = useSelector((state: CombinedState) => state.projects.gettingQuery);
    const createNewProject = () => {
        history.push({
            pathname: '/project-management-create',
        });
        dispatch(initialiaseStepperCount());
    };

    const dimensions = {
        md: 20,
        lg: 20,
        xxl: 20,
    };

    return (
        <Row className='ltts-projects-page-top-bar'>
            <Col className='ltts-project-content'>Projects</Col>
            <Col {...dimensions}>
                <Row justify='end' align='bottom'>
                    <Col style={{ marginRight: '25px' }}>
                    <SearchBar onSearchChange={props.onSearchChange} />
                    </Col>
                    <Col>
                        <Row gutter={8}>
                            <Col>
                                <Button
                                    size='large'
                                    id='ltts-create-project-button'
                                    className='ltts-create-project-button'
                                    type='primary'
                                    onClick={(): void => createNewProject()}
                                >
                                    Create new project
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
