
// Copyright (C) 2021-2022 LTTS
//
// SPDX-License-Identifier: MIT
import React from 'react';
import Search from 'antd/lib/input/Search';
interface Props {
    onSearchChange: (value: any) => void;
}
const SearchBar = (props: Props) => {

    return (
        <div>
        <Search
            className ='ltts-search-field'
            size='large'
            placeholder='Search'
            allowClear
            onChange={(e) => props.onSearchChange(e)}
        />
    </div>
    );
}

export default SearchBar;