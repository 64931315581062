// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/loading_image.gif");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#loader{width:100%;height:100vh;background:#fff url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;z-index:999999;transition-duration:.2s,1s;transition-timing-function:linear,ease-in;transition-delay:0s,1s}", ""]);
// Exports
module.exports = exports;
