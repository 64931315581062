// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd/lib/grid';
import Pagination from 'antd/lib/pagination';
import { CombinedState, Project } from '../../../reducers/interfaces';
import ProjectItem from './project-item';
import Icon from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';
import Menu from 'antd/lib/menu';
import Text from 'antd/lib/typography/Text';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { gridandlistview } from 'actions/projects-mgnt-actions';
import { ProjectSortIcon, ProjectCardIcon, ProjectListIcon, UserFilterLogo, CardSelectedIcon, ListSelectedIcon } from 'icons';
import './styles.scss';
import ProjectItemListViewComponent from './project-item-list-view';
import TopBarComponent from './top-bar';
import { List } from 'immutable';
const { Component, Fragment } = React;
export default function ProjectListComponent(): JSX.Element {
    const ProjectListTypes = [
        { id: 1, ProjectType: 'Image', isChecked: false },
        { id: 2, ProjectType: 'Video', isChecked: false },
        { id: 3, ProjectType: 'LiDAR', isChecked: false },
        { id: 4, ProjectType: 'Audio', isChecked: false },
    ];
    const dispatch = useDispatch();
    const projects = useSelector((state: CombinedState) => state.projects.current);
    const gridview = useSelector((state: CombinedState) => state.projects.gridView);
    const [currentPage, setCurrentPage] = useState(1);
    const [checkedArray, setCheckedArray] = useState([]);
    const [projectPerPage] = useState(12);
    const [projectListType, setProjectListType] = useState(ProjectListTypes);
    const [filteredProjects, setFilteredProjects] = useState(projects);
    const [visibles, setVisibles] = useState(false);
    const indexOfLastApps = currentPage * projectPerPage;
    const indexOfFirstApps = indexOfLastApps - projectPerPage;
    const changePage = (pageNumber: any) => setCurrentPage(pageNumber);

    let timer;
    //for feature use
    // function ResetFilter() {
    //     setFilteredProjects(projects);
    // }
    function ViewChange(value: boolean) {
        dispatch(gridandlistview(value));
    }
    const searchProject = (e: any) => {
        const searchValue = e.target.value;
        if (searchValue !== '') {
            const filteredData: any = projects?.filter(
                (item) =>
                    item.instance['description'].toLowerCase().includes(searchValue.toLowerCase()) ||
                    item.instance['name'].toLowerCase().includes(searchValue.toLowerCase()) ||
                    item.instance['id'].toString().includes(searchValue),
            );
            setCurrentPage(1);
            setFilteredProjects(filteredData);
        } else {
            setFilteredProjects(projects);
        }
        if (e.type === 'click') {
            e.preventDefault();
            timer = setTimeout(() => {
                setFilteredProjects(projects);
            }, 500);
        }
    };
    const handleChange = (e: any) => {
        e.stopPropagation();
        setVisibles(true);
        const CheckedValue = e.target.checked;
        setProjectListType(
            projectListType.map((product) =>
                product.id === e.target.value ? { ...product, isChecked: CheckedValue } : product,
            ),
        );
        if (e.target.checked) {
            setCheckedArray([...checkedArray, e.target.value]);
            setCurrentPage(1);
        } else {
            setCheckedArray(checkedArray.filter((id) => id !== e.target.value));
        }
    };
    //clear or reset
    const projectTypeFilter = () => {
        setProjectListType(projectListType.map((product) => ({ ...product, isChecked: false })));
        setFilteredProjects(projects);
        setCheckedArray([]);
        setVisibles(false);
    };
    useEffect(() => {
        if (checkedArray.length === 0) {
            setFilteredProjects(projects);
        } else {
            let filterProject = projects.filter((test) =>
                checkedArray.some((category) => [test?.instance?.project_type].flat().includes(category)),
            );
            setFilteredProjects(filterProject);
        }
    }, [checkedArray]);
    const currentProjectList = filteredProjects.slice(indexOfFirstApps, indexOfLastApps);
    const dimensions = {
        md: 17,
        lg: 18,
        xxl: 19,
        xs: 20,
    };
    function handleVisibleChange() {
        let flag = visibles;
        setVisibles(!flag);
    }
    const menu = (
        <Menu multiple={true} className='filter-menu'>
            {projectListType.map((item) => (
                <Menu.Item key={item.id}>
                    <Checkbox value={item.id} checked={item.isChecked} onChange={(e) => handleChange(e)}>
                        {item.ProjectType}
                    </Checkbox>
                </Menu.Item>
            ))}
            <Space>
                <Button className='filter-reset-btn' size='small' onClick={() => projectTypeFilter()}>
                    Reset
                </Button>
            </Space>
        </Menu>
    );
    return (
        <>
            <TopBarComponent onSearchChange={searchProject} />
            <Row justify='start' align='middle' className='ltts-subheader projectlist-subheader'>
                <Col {...dimensions}>
                    <Fragment>
                        <Dropdown
                            overlay={menu}
                            className='ltts-header-menu-dropdown-filter'
                            trigger={['click']}
                            onVisibleChange={() => handleVisibleChange()}
                            visible={visibles}
                        >
                            <span className='project-filter-span grid-view-button'>
                                <Icon className='project-filter-icon' component={UserFilterLogo} />
                                <Text type='secondary' className='ltts-menu-dropdown-text'>
                                    Filter
                                </Text>
                            </span>
                        </Dropdown>
                    </Fragment>
                    <Dropdown overlay={menu} className='ltts-header-menu-dropdown-sort' trigger={['click']}>
                        <span className='grid-view-button'>
                            <Icon className='ltts-sort-icon' component={ProjectSortIcon} />
                            <Text type='secondary' className='ltts-menu-dropdown-text'>
                                Sort
                            </Text>
                        </span>
                    </Dropdown>
                    <button type='button' className='grid-view-button'>
                        <Text type='secondary' className='ltts-menu-dropdown-text'>
                            ClearAll
                        </Text>
                    </button>
                </Col>
                <Col>
                    <button
                        type='button'
                        className='grid-view-button'
                        title='Grid View'
                        onClick={() => ViewChange(true)}
                        // onClick={() => setGridView(true)}
                    >
                        <Icon component={gridview ? CardSelectedIcon : ProjectCardIcon} />
                        <Text type='secondary' className={gridview ? 'ltts-menu-dropdown-text selected' : 'ltts-menu-dropdown-text'}>
                            Card
                        </Text>
                    </button>
                    <button
                        type='button'
                        className='list-view-button'
                        title='List View'
                        onClick={() => ViewChange(false)}
                        // onClick={() => setGridView(false)}
                    >
                        <Icon component={gridview ? ProjectListIcon: ListSelectedIcon} />
                        <Text type='secondary' className={gridview ? 'ltts-menu-dropdown-text' : 'ltts-menu-dropdown-text selected'}>
                            List
                        </Text>
                    </button>
                </Col>
            </Row>
            <div className='bgbanner-content'>
                {gridview ? (
                    <Row gutter={[8, 8]} style={{ marginRight: '-4px', marginLeft: '-4px' }}>
                        {currentProjectList.length > 0 && currentProjectList ? (
                            currentProjectList?.map(
                                (project: Project): JSX.Element => (
                                    <Col xs={24} lg={12} xl={8} xxl={6} className='' key={project.instance.id}>
                                        <ProjectItem key={project.instance.id} projectInstance={project} />
                                    </Col>
                                ),
                            )
                        ) : (
                            <Col className='gutter-row' span={6}>
                                <p>No Projects Available!</p>
                            </Col>
                        )}
                    </Row>
                ) : (
                    <Row gutter={[8, 8]} style={{ marginRight: '-4px', marginLeft: '-4px' }}>
                        {currentProjectList.length > 0 && currentProjectList ? (
                            currentProjectList?.map(
                                (project: Project): JSX.Element => (
                                    <Col xs={24} className='' key={project.instance.id}>
                                        <ProjectItemListViewComponent
                                            key={project.instance.id}
                                            projectInstance={project}
                                        />
                                    </Col>
                                ),
                            )
                        ) : (
                            <Col className='gutter-row' span={6}>
                                <p>No Projects Available!</p>
                            </Col>
                        )}
                    </Row>
                )}
            </div>
            {currentProjectList.length > 0 && currentProjectList && (
                <Row className='ltts-projects-list-pagination-projectpage'>
                    <Col>
                        <Pagination
                            onChange={changePage}
                            showSizeChanger={false}
                            total={filteredProjects?.length}
                            pageSize={projectPerPage}
                            current={currentPage}
                            showQuickJumper
                        />
                    </Col>
                </Row>
            )}
        </>
    );
}
