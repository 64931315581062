import React from 'react';
import { connect } from 'react-redux';

import { TreeNodeNormal } from 'antd/lib/tree/Tree';
import { createUploadFiles, generateMappingToken } from 'actions/projects-mgnt-actions';
import { loadShareDataAsync } from 'actions/share-actions';
import { ShareItem, CombinedState } from 'reducers/interfaces';
import FileManagerComponent, { Files } from 'components/project-mgmt-page/files-upload/files-upload-main';
// #LTTS added  readonly and projectType
interface OwnProps {
    ref: any;
    onFileSelect(value: Files): void;
    readonly: boolean;
    location: {state: null};
    projectType: number | null;
    history: null;
}

interface StateToProps {
    project_type: number | null,
    projectId: number | null,
    name: string,
    p2p_mapping: number | null,
    advanced_config: string,
    uploadFetching: boolean,
    treeData: TreeNodeNormal[];
}

interface DispatchToProps {
    onUpload: (projectValues: any) => void;
    mappingToken: (id: any) => void;
    getTreeData(key: string, success: () => void, failure: () => void): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    function convert(items: ShareItem[], path?: string): TreeNodeNormal[] {
        return items.map((item): TreeNodeNormal => {
            const isLeaf = item.type !== 'DIR';
            const key = `${path}${item.name}${isLeaf ? '' : '/'}`;
            return {
                key,
                isLeaf,
                title: item.name || 'root',
                children: convert(item.children, key),
            };
        });
    }

    const { root } = state.share;
    return {
        project_type: state.projects.activities.creates.project_type,
        projectId: state.projects.activities.creates.id,
        name: state.projects.activities.creates.name,
        p2p_mapping: state.projects.activities.creates.p2p_mapping,
        advanced_config: state.projects.activities.creates.project_config?.advanced_configuration,
        uploadFetching: state.projects.fetching,
        treeData: convert([root], ''),
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onUpload: (projectValues: any): void => dispatch(createUploadFiles(projectValues)),
        mappingToken: (id: any): void => dispatch(generateMappingToken(id)),
        getTreeData: (key: string, success: () => void, failure: () => void): void => {
            dispatch(loadShareDataAsync(key, success, failure));
        },
    };
}

type Props = StateToProps & DispatchToProps & OwnProps;

export class FileManagerContainer extends React.PureComponent<Props> {
    private managerComponentRef: any;

    public constructor(props: Props) {
        super(props);

        this.managerComponentRef = React.createRef();
    }

    public componentDidMount() {
        const { mappingToken, location: { state: project_id } } = this.props;
        mappingToken(project_id);
    }

    public getFiles(): Files {
        return this.managerComponentRef.getFiles();
    }

    public getCloudStorageId(): number | null {
        return this.managerComponentRef.getCloudStorageId();
    }

    public reset(): Files {
        return this.managerComponentRef.reset();
    }

    // private onChangeActiveKey = (key: string): void => {
    //     const values = this.state;
    //     this.setState({
    //         ...values,
    //         activeFileManagerTab: key,
    //     });
    // };

    public render(): JSX.Element {
        const { treeData, getTreeData, name, readonly, project_type, projectId, p2p_mapping, history, onFileSelect, onUpload, advanced_config } = this.props;
        // #LTTS added readonly and projectType
        return (
            <FileManagerComponent
                treeData={treeData}
                projectType={project_type}
                readonly={readonly}
                p2p_mapping={p2p_mapping}
                projectId={projectId}
                name={name}
                history={history}
                advanced_config={advanced_config}
                onUpload={onUpload}
                onLoadData={getTreeData}
                onFileSelect={onFileSelect}
                // onChangeActiveKey={onChangeActiveKey}
                ref={(component): void => {
                    this.managerComponentRef = component;
                }}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FileManagerContainer);
