// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useCallback } from 'react';
import { Row, Col } from 'antd/lib/grid';
import SearchBar from '../custom-search';
interface Props {
    onSearchChange: (value: any) => void;
}
export default function TopBarComponent(props: Props): JSX.Element {
    return (
        <>
            <Row className='ltts-projects-page-top-bar' gutter={[8, 8]}>
                <Col
                    className='ltts-project-content'
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}
                >
                    Apps
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 7 }}>
                    <SearchBar onSearchChange={props.onSearchChange} />
                </Col>
            </Row>
            <Row justify='end' align='bottom'>
                <Col>
                    <Row gutter={8}>
                        {/* <Col>
                                <Button
                                    size='large'
                                    className='ltts-create-project-button'
                                    type='primary'
                                    onClick={(): void => closeIFrame()}
                                >
                                    Close
                                </Button>
                            </Col> */}
                        {/* <Col>
                                <Button
                                    size='large'
                                    className='ltts-create-project-button'
                                    type='primary'
                                    onClick={(): void => submitAnnotation()}
                                >
                                    Submit annotation
                                </Button>
                            </Col> */}
                    </Row>
                </Col>
            </Row>
        </>
    );
}
