// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Layout from 'antd/lib/layout';

function FooterComponent(): JSX.Element {
    const { Footer } = Layout;

    return (
        <Footer className='login-footer'>
          <span  className=''> © LTTS  | </span> 
            <a target='_blank' rel='noopener noreferrer' href='https://www.ltts.com/' className='terms-of-use-margin'>
                Terms of Use 
            </a>|
            <a target='_blank' rel='noopener noreferrer' href='https://www.ltts.com/' className='terms-of-use-margin'>
                Privacy
            </a>
        </Footer>
    );
}

export default FooterComponent;
