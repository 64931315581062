// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';

import { Row, Col } from 'antd/lib/grid';

function UserprofileSettingsComponent(): JSX.Element {

    return (
        <div className='ltts-workspace-settings'>
            <Row>
                <Col>

                </Col>
            </Row>

        </div>
    );
}

export default React.memo(UserprofileSettingsComponent);
