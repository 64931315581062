// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState  } from 'react';
import { Col, Row } from 'antd/lib/grid';
import Layout from 'antd/lib/layout';
import { Button, Checkbox, Modal, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { getUserAgreementsAsync } from '../../actions/useragreements-actions';
const { Content } = Layout;
// import { logoutAsync } from 'actions/auth-saga-actions';
import { logoutAsync } from '../../actions/auth-actions';
import Title from 'antd/lib/typography/Title';
import './agreement-style.scss';
import 'antd/dist/antd.css';
/**
 * Component for displaying Terms and condition acceptance message and then redirecting to the login page
 */

function AgreementConfirmationPage(): JSX.Element {

    const [enable, setEnable] = useState(true);
    const dispatch = useDispatch();
    const agree = (event: any) => {
        event.preventDefault();
        dispatch(getUserAgreementsAsync());
    };

    const disagree = (event: any) => {
        event.preventDefault();
        dispatch(logoutAsync());
    };
    const enbledisable = (e: any) => {
        e.target.checked?setEnable(false):setEnable(true)
    };
    return (
        <Layout>
            <Content>
                <Row justify='center' align='middle'>
                    <Col>
                        <div>
                            <Modal
                                className='ltts-modal-user-agreement'
                                title={<Title level={3}>User Agreement</Title>}
                                okType='primary'
                                okText='Submit'
                                footer={null}
                                visible={true}
                                destroyOnClose
                                // onCancel={onClose}
                            >
                                <div className='wrapper'>
                                    <p>scroll down!</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <p>content</p>
                                    <Checkbox className='checkbox-privacy-policy'  onClick={(event) => enbledisable(event)}>
                                        By joining I declare that I have read and accepted the <br />
                                        <a
                                            rel='noopener noreferrer'
                                            target='_blank'
                                            href='https://rainbow.ltts.com/Pages/Home.aspx'
                                            className='terms-service-text'
                                        >
                                            <b>Terms of Service </b>
                                        </a>
                                        and
                                        <a
                                            rel='noopener noreferrer'
                                            target='_blank'
                                            className='Privacy-policy-text'
                                            href='https://rainbow.ltts.com/Pages/Home.aspx'
                                        >
                                           &nbsp;<b>Privacy Policy</b>
                                        </a>
                                    </Checkbox>
                                </div>
                                <section className='user-aggreement-button-align'>
                                    <Space>
                                        <Button  onClick={(event) => disagree(event)} disabled={enable}type='primary' shape='round' size='middle'>
                                            <span>Disagree</span>
                                        </Button>
                                        <Button onClick={(event) => agree(event)} disabled={enable} type='primary' shape='round' size='middle'>
                                            <span>Agree</span>
                                        </Button>
                                    </Space>
                                </section>
                            </Modal>
                        </div>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}

export default AgreementConfirmationPage;
