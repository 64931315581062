// Copyright (C) 2021-2022 LTTS
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Text from 'antd/lib/typography/Text';
import Button from 'antd/lib/button';
import { List } from 'antd';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router';
import moment from 'moment';
import { ProjectImageIcon, ProjectVideoIcon, ProjectAudioIcon, ProjectLidarIcon } from 'icons';
import Icon from '@ant-design/icons';
import { activateIframe, fetchWorkflowMapping } from 'actions/apps-stages-actions';
import { useDispatch } from 'react-redux';
import './apps-styles.scss';

interface Props {
    projectInstance: any;
}
export default function AppItemListViewComponent(props: Props): JSX.Element {
    const {
        projectInstance: { instance },
    } = props;

    const {
        projectInstance: {
            id,
            app_id,
            project_app_id,
            app_alias_name,
            project_name,
            status,
            project_type: projectType,
        },
    } = props;

    const dispatch = useDispatch();

    const openFrame = (event) => {
            event.preventDefault();
            dispatch(fetchWorkflowMapping({ app_id, project_app_id }));
        };

    const history = useHistory();
    const createdDate = instance?.createdDate ? instance.createdDate : null;
    const created = moment(createdDate);
    const onOpenProject = (): void => {
        history.push(`/projects/${instance.id}`);
    };
    const showProjectType = () => {
        let iconObj = {
            icon: '',
            text: '',
            className: '',
        };
        if(projectType === 1) {
            iconObj = {
                icon: ProjectImageIcon,
                className:'Project-icon-image-listview',
            }
        }
        else if(projectType === 2) {
            iconObj = {
                icon: ProjectVideoIcon,
                className:'Project-icon-video-listview'
            }
        }
        else if(projectType === 3) {
            iconObj = {
                icon: ProjectLidarIcon,
                className:'Project-icon-lidar-listview'
            }
        }
        else if(projectType === 4) {
            iconObj = {
                icon: ProjectAudioIcon,
                className:'Project-icon-audio-listview'
            }
        }
        return (
            <div className={iconObj.className}>
            <Row align='middle'>
                <Col span={24}>
                    <Icon component={iconObj.icon} />
                </Col>
            </Row>
        </div>
        )
    };

    return (
                <div className='list-view project-list-view-rows'>
                    <List
                        grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 3,
                            lg: 4,
                            xl: 5,
                            xxl: 6,
                        }}
                    >
                        <List.Item>
                            <Row className='ltts-project-item-list-item-listview' justify='center' align='middle'>
                                <Col span={2} className='Project-icon-image'style={{textAlign:'center' }}>
                                {showProjectType()}
                                </Col>
                                <Col span={19}>
                                    <div className='ltts-project-card-content-listview'>
                                        <div>
                                            <Text>
                                                {projectType == 1 ? (
                                                    <div className='Project-icon-image'>
                                                        <Row align='middle'>
                                                            <Col span={6} style={{ fontWeight: 'bold',color:'#3691D6' }}>
                                                                Image Annotation
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ) : projectType == 2 ? (
                                                    <div className='Project-icon-video'>
                                                        <Row align='middle'>
                                                            <Col span={6} style={{ fontWeight: 'bold',color:'#3691D6'  }}>
                                                                Video Annotation
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ) : projectType == 3 ? (
                                                    <div className='Project-icon-lidar'>
                                                        <Row align='middle'>
                                                            <Col span={6} style={{ fontWeight: 'bold' ,color:'#3691D6' }}>
                                                                LIDAR Annotation
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ) : projectType == 4 ? (
                                                    <div className='Project-icon-audio'>
                                                        <Row align='middle'>
                                                            <Col span={6} style={{ fontWeight: 'bold' ,color:'#3691D6' }}>
                                                                Audio Annotation
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ) : null}
                                            </Text>
                                        </div>

                                        <p>
                                            <span aria-hidden>
                                                <Text strong className='project-listview-description'>App Name : </Text>
                                                <span className='project-listview-description'>  {app_alias_name}</span>
                                            </span>
                                        </p>
                              
                                        <Text strong className='project-listview-description'>Project Status :</Text>
                                        <span className='project-listview-description'>  {status}</span>

                                        <p>
                                            <span aria-hidden>
                                                <Text strong className='project-listview-description'>Project Name : </Text>
                                                <span className='project-listview-description'> {project_name} </span>
                                            </span>
                                        </p>
                                    </div>
                                </Col>

                                <Col span={3} className='ltts-project-item-description'>
                                    <div className='ltts-apps-open-button'>
                                        <Button
                                            type='primary'
                                            size='small'
                                            className='ltts-create-project-button'
                                            onClick={(e: React.MouseEvent): void => openFrame(e)}
                                        >
                                            Open
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </List.Item>
                    </List>
                </div>
            )}
    

