// Copyright (C) 2022 LTTS
//
// SPDX-License-Identifier: MIT
import React from 'react';

import './loader-styles.scss'
export default function Spinner(){
    return(
      <>
        <div id="loader"></div>
      </>
    );
}
