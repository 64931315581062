

import {
    all, put, takeLatest, delay,
} from 'redux-saga/effects';
import { AuthSagaActionTypes } from '../actions/auth-saga-actions';
import getCore from '../ltts-core-wrapper';

import isReachable from '../utils/url-checker';
import { ActionUnion, createAction } from '../utils/redux';

const cvat = getCore();

export const authSagaActions = {

    register: () => createAction(AuthSagaActionTypes.REGISTER),
    registerSuccess: (user: any) => createAction(AuthSagaActionTypes.REGISTER_SUCCESS, { user }),
    registerFailed: (error: any) => createAction(AuthSagaActionTypes.REGISTER_FAILED, { error }),

};

export type AuthSagaActions = ActionUnion<typeof authSagaActions>;

function* registerAsync(action: any): any {
    try {
        const user = yield cvat.server.register(
            action.payload.email,
            action.payload.firstName,
            action.payload.lastName,
            action.payload.email,
            action.payload.password1,
            action.payload.password2,
            action.payload.confirmations,
        );

        yield put(authSagaActions.registerSuccess(user));
    } catch (error) {
        yield put(authSagaActions.registerFailed(error));
    }
}

export function* authWatcher() {
    yield all([
        takeLatest(AuthSagaActionTypes.REGISTER, registerAsync),
    ]);
}